import React from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { Stack } from "@mui/material";
import "../User.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { AddPost } from "../../../components/AdminPost/AddPost";
export const PostData = () => {
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash" style={{overflowY:"scroll"}}>
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack sx={{ width: "90%" }}>  
            {/* <div className="all-user-strip">All Students</div> */}
            <AddPost />
          </Stack>
        </div>
      </div>
    </div>
  );
};
