import {
  Button,
  Stack,
  Grid,
  Divider,
  Typography,
  Paper,
  Box,
  styled,
  Modal,
  TextField,
  Checkbox,
} from "@mui/material";
import publicAxios from "../../axios/publicAxios";
import { useState } from "react";
import CreateTestTopic from "../AdminTest/CreateTestTopic";

import EmptyChapterPlaceholder from "../../assets/png/empty-chapter.png";
import TopicView from "../AdminTest/TopicView";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

function AddTestSeriesForm2({
  testSeriesId,
  formData,
  handleThumbnailImage,
  testSeriesImage,
  onDelete,
}) {
  const auth = useRecoilValue(authAtom);
  const [chapterSelected, setChapterSelected] = useState(null);
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  function onChapterResourceDelete(chapterId, resourceId, event) {
    console.log(resourceId);
    event.stopPropagation();
    const btnType = event.currentTarget.getAttribute("btnType");
    console.log(chapterId, resourceId, chapterSelected);
    if (chapterSelected === chapterId && !resourceId) {
      setChapterSelected("");
    }
    onDelete(chapterId, resourceId, btnType).then((res) => {
      getTopics();
    });
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [topicSet, setTopicSet] = useState([]);
  const createTestModal = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const getTopics = async () => {
    try {
      const response = await publicAxios.get(
        `v2/testseries/topic/${testSeriesId}`,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      );
      const newArray = [...response.data];
      for (let element of newArray) {
        element["topic"] = [];
        for (let ele of element.test_sets) {
          const testSetResponse = await getTestSet(ele);
          console.log(testSetResponse);
          element["topic"] = [...element["topic"], testSetResponse];
        }
      }
      console.log(newArray);
      setTopicSet(newArray);
    } catch (error) {}
  };

  const getTestSet = async (id) => {
    try {
      const response = await publicAxios.get(`v2/testset/${id}`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      return response.data;
    } catch (error) {
      // Handle errors here
      // console.error(error);
      return null;
    }
  };
  useEffect(() => {
    getTopics();
  }, []);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="space-between">
        <Grid item xs={12} md={4}>
          <div className="left-side-profile">
            <Stack
              direction="column"
              sx={{
                background: "#FFF",
                boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "5px",
              }}
            >
              <Grid
                className="profile-card"
                container
                gap={3}
                justifyContent="center"
                marginBottom={2}
              >
                <Grid item xs={12} justifyContent="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <Button
                            role={undefined}
                            variant="outlined"
                            > */}
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      // color="neutral"
                    >
                      {" "}
                      {
                        // console.log(formData.thumbnail.value)
                      }
                      <img
                        src={testSeriesImage}
                        height={200}
                        width={250}
                        style={{ objectFit: "contain" }}
                        alt="Course Thumbnail"
                      />
                      {/* Upload the Thumbnail */}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleThumbnailImage}
                      />
                    </Button>
                  </div>
                </Grid>
                <span style={{ fontWeight: "bold" }}>Title</span>
                <Divider sx={{ marginTop: "5%", marginBottom: "5%" }} />
              </Grid>
            </Stack>
          </div>
        </Grid>
        <Grid
          container
          direction="column"
          height="100%"
          bgcolor="white"
          item
          xs={12}
          md={8}
          style={{ overflow: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              height: "100%",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              paddingY={1.5}
              paddingX={2.5}
            >
              <Typography>Test Series Content</Typography>
              <Button variant="outlined" onClick={createTestModal}>
                Create Topic
              </Button>
            </Stack>
            {topicSet.length === 0 && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "50%", height: "50%" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={EmptyChapterPlaceholder}
                    alt="empty chapter placeholder"
                  />
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              padding={2}
              flexGrow={1}
              gap={2}
            >
              {topicSet.map((item, index) => (
                <TopicView
                  key={item.id}
                  topicIdx={index}
                  topics={item}
                  // selected={chapter.id === chapterSelected}
                  // onSelect={setChapterSelected.bind(this, chapter.id)}
                  onDelete={onChapterResourceDelete}
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CreateTestTopic
        modalOpen={modalOpen}
        getTopics={getTopics}
        handleClose={handleClose}
        testSeriesId={testSeriesId}
      />
    </>
  );
}

export default AddTestSeriesForm2;
