import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import publicAxios from "../../axios/publicAxios";
import { useState } from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const UserTable = () => {
  const auth = useRecoilValue(authAtom);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const fetchInfo = async () => {
    await publicAxios
      .get("account/users", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        setError(true);
        console.info("error", e);
      });
  };
  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchInfo();
  }, []);

  const navigate = useNavigate();
  const columns = [
    {
      field: "profile_image",
      headerName: "",
      width: 90,
      renderCell: (params) => {
        const currentRow = params.row;
        //   console.log("Current Row: ",currentRow);
        return (
          <>
            <Avatar src={currentRow.resource.url} />
          </>
        );
      },
    },
    {
      field: "fullName",
      headerName: "Name",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return <>{currentRow?.first_name + " " + currentRow?.last_name}</>;
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Mobile",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 150,
    },
    {
      field: "joining_date",
      headerName: "Joining Date",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                navigate("/u1NzT9Pq2s5/admin/user/editUser/" + currentRow.id);
              }}
            >
              <img src="/images/admin/edit.png" alt="" />
            </Button>
            <Button
              onClick={() => {
                const confirm = window.confirm(
                  "You sure want to Delete this user?"
                );
                if (confirm) {
                  publicAxios
                    .delete(`v2/account/${currentRow.id}`, {
                      headers: {
                        Authorization: "Bearer " + auth.access,
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        fetchInfo();
                        setLoading(false);
                      }
                    })
                    .catch((e) => {
                      if (e.response && e.response.status === 404) {
                        setLoading(false);
                      }
                      console.info("error", e);
                    });
                }
                // alert(currentRow.Name);
              }}
            >
              <img src="/images/admin/delete.png" alt="" />
            </Button>
          </Stack>
        );
      },
      // renderCell: () => {
      //   return (
      //     <Stack
      //       direction={{ xs: 'column', sm: 'row' }}
      //       spacing={{ xs: 1, sm: 2, md: 2 }}
      //     >
      //       <Button>
      //         <img src="/images/admin/edit.png" alt="" />
      //       </Button>
      //       <Button>
      //         <img src="/images/admin/delete.png" alt="" />
      //       </Button>
      //     </Stack>
      //   );
      // }
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  // Otherwise filter will be applied on fields such as the hidden column id

  return (
    <>
      {loading ? (
        <>Loading</>
      ) : (
        <>
          {data ? (
            <Box sx={{ height: "100%", width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          ) : (
            <>No Data available</>
          )}
        </>
      )}
    </>
  );
};
