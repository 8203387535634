import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import publicAxios from "../../axios/publicAxios";
import "./Faq.scss";


export const Faq = ({ refName = null, faqData, faqName }) => {
  const [data,setData] = useState([])
  console.log(faqData);
  useEffect(() => {
    const getFaqs = (courseId) => {
      publicAxios
        .get(`/v2/${faqName === "testSeries" ? 'testseries':'cource' }/faqs/${courseId}`)
        .then((res) => {
        setData(res.data)
        })
        .catch((e) => {
          console.info("error", e);
        });
    };
    faqData && getFaqs(faqData);
  }, [faqData]);
  return (
    <div className="faq" ref={refName}>
      <div className="wrapper">
        <div className="item-1">
          <h2 className="display_small">Frequently asked questions</h2>
          <span>
            {/* <Link to={`/contactus`} className="buttonLink">
              Contact us for more info
            </Link> */}
          </span>
          <img className="fluid-image" src="/images/courses/faq.svg" alt="faq" />
        </div>
        <div className="item-2">
          <ol>
            {data &&
              data.map((item) => (
                <li key={`faq-${item.id}`}>
                  <div className="itemWrapper">
                    <div className="question headline_small">{item?.question}</div>
                    <div className="expand">
                      <AddIcon />
                    </div>
                    <div className="answer title_medium">{item?.answer}</div>
                  </div>
                </li>
              ))}
          </ol>
        </div>
      </div>
    </div>
  );
};
