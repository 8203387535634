import Button from "@mui/material/Button";

import "./Coupon.scss";
import { useEffect, useState } from "react";
import publicAxios from "../../axios/publicAxios";
import { catchError } from "../../helper/useError";
import { useRecoilState, useRecoilValue } from "recoil";
import { toastAtom } from "../../state/atoms/toastAtom";
import { authAtom } from "../../state/atoms/authAtom";
import selectedCouponAtom from "../../state/couponAtom";

export const Coupon = ({
  couponCode,
  handleOnChangeCoupon,
  handleApplyCoupon,
  disabledApply,
  handleRemoveCoupon,
  setTotalDataWithCoupon,
  couponsData,
}) => {
  const auth = useRecoilValue(authAtom);
  const [selectedCoupon, setSelectedCoupon] =
    useRecoilState(selectedCouponAtom);

  const [isApplied, setIsApplied] = useState(false);
  const [, setShowToast] = useRecoilState(toastAtom);

  useEffect(() => {
    if (selectedCoupon.id) {
      setShowToast({
        status: true,
        type: "success",
        successMessage: "Coupon Applied",
      });
      setTotalDataWithCoupon(selectedCoupon.value);
    }
  }, [selectedCoupon]);

  const handleChange = async (event) => {
    const selectedCouponId = event.target.value;

    try {
      const response = await publicAxios.post(
        "coupon/validate-coupon",
        {
          coupon_id: selectedCouponId,
        },
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      );

      if (response.status === 200) {
        setSelectedCoupon({
          id: selectedCouponId,
          value: response?.data?.coupon_discount || 0,
          r: response?.data || {},
        });
      } else {
        const message = "This coupon is not valid";
        setShowToast({
          status: true,
          type: "error",
          errorMessage: message,
        });
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      const message = "This coupon is not valid";
      setShowToast({
        status: true,
        type: "error",
        errorMessage: message,
      });
    }
  };

  const applyCoupon = async () => {
    try {
      // coupon/validate-coupon
      if (selectedCoupon.id) {
        // coupon id

        setTotalDataWithCoupon(0);

        setSelectedCoupon({
          id: "",
          value: "",
        });
        setShowToast({
          status: true,
          type: "success",
          successMessage: "Coupon Removed",
        });
      }
    } catch (error) {
      const err = await catchError(error);
      setShowToast({
        status: true,
        type: "error",
        errorMessage: err,
      });
    }
  };

  return (
    <div className="coupon">
      <div>
        <img
          className="fluid-image"
          src="/images/order/coupon.svg"
          alt="coupon"
        />
      </div>
      <div>
        <div>
          <select
            style={{ minWidth: "200px", height: "32px" }}
            value={selectedCoupon.id}
            onChange={handleChange}
          >
            <option value="">Select a Coupon</option>
            {couponsData.map((coupon) => (
              <option key={coupon.id} value={coupon.id}>
                {coupon.code}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        {selectedCoupon.id ? (
          <Button
            variant={"contained"}
            disabled={!selectedCoupon.id}
            onClick={() => applyCoupon()}
          >
            Remove
          </Button>
        ) : null}
      </div>
      <div className="helpText title_small">Check your available coupons.</div>
    </div>
  );
};
