import React from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { Stack } from "@mui/material";
import "../User.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { CouponTable } from "../../../components/AdminCoupon/CouponTable";
import { StudentDetails } from "../../../components/AdminCoupon/StudentDetails";
export const CouponMgmt = () => {
  return (
    <div
      // style={{
      //   display: "flex",
      //   margin: 0,
      //   padding: 0,
      //   background: "#F1F2F5",
      //   fontFamily: "Lato",
      // }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash" style={{overflowY:"scroll"}}>
        {/* <AdminNavbar /> */}
        <div className="main-user-dash" style={{width:"92%", margin:"auto", border:"none"}}>
          <Stack >
            <div className="all-user-strip">Coupons</div>
            <div className="userTables">
              {/* <CouponTable /> */}
              <StudentDetails/>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
