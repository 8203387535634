import { useState } from "react";
import { Typography, Container, Paper } from "@mui/material";
import { Link } from "react-router-dom";

// import Loader from "../../Tools/Loader";

const PrivacyCard = () => {
    const paperStyle = {
        backgroundColor: 'transparent',   // Setting background color
        boxShadow: 'none',
        marginTop: "1%"

    };

    return (
        <Container maxWidth="md">
            <Paper style={paperStyle}>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>

                <Typography variant="body1" paragraph>
                    This site is owned by Studyglows Education Private Limited, hereinafter referred to as "Studyglows". ThThis Privacy Policy is designed to tell you about our practices regarding collection, use, and disclosure of information that you may provide via this site. Please be sure to read this entire Privacy Policy before using, or submitting information, to this site. Use of this site constitutes your consent to our Privacy Policy. By using this site, you agree with the terms of this Privacy Policy. Whenever you submit information via this site, you consent to the collection, use, and disclosure of that information in accordance with this Privacy Policy        </Typography>

                <Typography variant="h5" gutterBottom>
                    Copyright
                </Typography>

                <Typography variant="body1" paragraph>
                    All information displayed, transmitted or carried on www.Studyglows.com is protected by copyright and other intellectual property laws. You may not, under any circumstances, modify, publish, distribute, repost, perform, display or in any way commercially exploit any of the content on this web site.        </Typography>

                <Typography variant="h5" gutterBottom>
                    What we collect
                </Typography>

                <Typography variant="body1" paragraph>
                    Like many Web sites, this site actively collects information from its visitors by permitting you to communicate directly with us via e-mail and feedback forms. Some of the information that you submit may be personally identifiable information (that is, information that can be uniquely identified with you, such as your full name, address, e-mail address, phone number, and so on). Some areas of this site may require you to submit information in order for you to benefit from the specified features (such as newsletter subscriptions, order processing, submitting a resume). As you navigate through the Web site, certain information can be passively collected (that is, gathered without your actively providing the information) using various technologies and means, such as navigational data collection. This site may use Internet Protocol (IP) addresses. An IP Address is a number assigned to your computer by your Internet service provider so you can access the Internet and is generally considered to be non-personally identifiable information, because in most cases an IP address is dynamic (changing each time you connect to the Internet), rather than static (unique to a particular user's computer). We may use your IP address to diagnose problems with our server, report aggregate information, determine the fastest route for your computer to use in connecting to our site, and administer and improve the site.        </Typography>

                {/* Add more sections as needed */}
                <Typography variant="h5" gutterBottom>
                    What we do with the information we gather
                </Typography>

                <Typography variant="body1" paragraph>
                    We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
                    <ul style={{ marginLeft: "25px" }}>
                        <li>Internal record keeping.</li>
                        <li>We may use the information to improve our products and services.</li>
                        <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                        <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Security
                </Typography>

                <Typography variant="body1" paragraph>
                    We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online. However, no data transmissions over the internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, we cannot ensure or warranty the security of any information you transmit to us via the internet, and you do so at your own risk. Once we have received your transmission, we will use our best efforts to ensure its security on our systems.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    How we use cookies
                </Typography>

                <Typography variant="body1" paragraph>
                    <p style={{ marginBottom: "10px" }}>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>

                    <p style={{ marginBottom: "10px" }}>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>

                    <p style={{ marginBottom: "10px" }}>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>

                    <p style={{ marginBottom: "10px" }}>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                </Typography>


                <Typography variant="h5" gutterBottom>
                    Links to other websites
                </Typography>

                <Typography variant="body1" paragraph>
                    Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Controlling your personal information
                </Typography>

                <Typography variant="body1" paragraph>
                    <ul style={{ marginLeft: "25px" }}>
                        <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes.</li>
                        <li>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at unsubscribe@www.studyglows.net</li>
                    </ul>
                    We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Contacting Us
                </Typography>

                <Typography variant="body1" paragraph>
                    If there are any questions regarding this privacy policy you may contact us by Clicking the "Contact Us" in the "About Us" menu tab.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Changes to this Policy
                </Typography>

                <Typography variant="body1" paragraph>
                    Any changes in this Policy will be updated & posted on this site. Please check back periodically, and especially before you provide any personally identifiable information.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Grievance Redressal Mechanism
                </Typography>

                <Typography variant="body1" paragraph>
                    <ul style={{ marginLeft: "25px", marginBottom:"10px" }}>
                        <li>Any complaints or request or concerns with regards to use, processing or disclosure of information provided by you or breach of these terms may be taken up with the designated grievance redressal officer as mentioned below via post or by sending an email to Rashi@studyglows.com</li>
                        <li>Grievance Redressal Officer: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to.</li>
                    </ul>
                    <p>Grievance Redressal Officer</p>
                    <p>Name: Rashi Singhal</p>
                    <p>Email: Rashi@studyglows.com , support@studyglows.com</p>
                    <div style={{ marginLeft: '25px', marginBottom:"10px", marginTop:"10px" }}>
                        We request you to please provide the following information in your complaint:
                        <p><strong>a.</strong> Identification of the information provided by you.</p>
                        <p><strong>b.</strong> Clear statement as to whether the information is personal information or sensitive personal information.</p>
                        <p><strong>c.</strong> Your address, telephone number or e-mail address.</p>
                        <p><strong>d.</strong> A statement that you have a good-faith belief that use of the information has been processed incorrectly or disclosed without authorization, as the case may be.</p>
                        <p><strong>e.</strong> A statement, under penalty of perjury, that the information in the notice is accurate, and that you are authorized to act on behalf of the owner of the right that is allegedly infringed.</p>
                    </div>
                    The Company may reach out to you to confirm or discuss certain details about your complaint and issues raised.
                    The Company shall not be responsible for any communication, if addressed, to any non-designated person in this regard.
                    You may also contact us in case you have any questions / suggestions about the Policy using the contact information mentioned above.
                </Typography>
            </Paper>
        </Container>
    );
};

export default PrivacyCard;
