import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import publicAxios from "../../axios/publicAxios";
import { authAtom } from "../../state/atoms/authAtom";
import { loginPopupAtom } from "../../state/atoms/loginPopupAtom";
import { userAtom } from "../../state/atoms/userAtom";
import { setAuthTokens } from "../../axios/tokens";

import * as constant from "./constants";
import { toastAtom } from "../../state/atoms/toastAtom";
import { catchError } from "../../helper/useError";

export const useLogin = () => {
  const location = useLocation();
  const [, setShowToast] = useRecoilState(toastAtom);
  const navigate = useNavigate();
  const [hero, setHero] = useState(constant.initialHero);
  const [countryCode, setCountryCode] = useState("IN");

  const [toggleParams, setToggleParams] = useState(
    constant.initialToggleParams
  );
  const [values, setValues] = useState(constant.initialValues);
  const [loading, setLoading] = useState({
    init: false,
    resend: false,
    reset: false,
  });
  const [error, setError] = useState(false);
  const [, setAuth] = useRecoilState(authAtom);
  const [, setUser] = useRecoilState(userAtom);
  const [, setLoginPopup] = useRecoilState(loginPopupAtom);

  const showOtpForm = (new_user, reset) => {
    let title;
    if (reset) {
      title = {
        title: "Reset Password",
        description:
          "We've sent an OTP to your Email id, Please enter OTP below and add a new password!",
      };
    } else {
      title = {
        title: constant.title2,
        description: constant.description2,
      };
    }
    setHero(title);

    let updatedInputFields = [];
    if (reset) {
      updatedInputFields = [constant.inputFields[0], constant.inputFields[3]];
    } else {
      updatedInputFields = new_user
        ? constant.inputFields
        : [constant.inputFields[3]];
    }

    setToggleParams({
      phoneDisabled: true,
      login_user: new_user,
      showResend: true,
      showReset: reset,
      inputFields: updatedInputFields,
      loginStep: 2,
    });
  };

  const handleEditEmail = () => {
    let title = {
      title: constant.title1,
      description: constant.description1,
    };
    setHero(title);
    setLoginPopup(true);
    setValues({ email: "" });
    setToggleParams({
      phoneDisabled: false,
      showResend: false,
      showReset: false,
      inputFields: [],
      loginStep: 1,
      login_user: false,
    });
  };

  const handleClose = () => {
    setLoginPopup(false);
    setValues({ email: "" });
    setToggleParams({
      phoneDisabled: false,
      showResend: false,
      showReset: false,
      inputFields: [],
      loginStep: 1,
      login_user: false,
    });
  };

  const sendReset = (type) => {
    setLoading({
      resend: type === "pass",
      init: false,
      reset: type !== "pass",
      submit: false,
    });
    let url = "v2/login/password";
    const request_body = {
      email: values.email,
    };
    publicAxios
      .post(url, request_body)
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          showOtpForm(false, true);
          setShowToast({
            status: true,
            type: "success",
            successMessage: "Otp sent to your email!",
          });
        }
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      })
      .finally(() => {
        setLoading({
          resend: false,
          init: false,
          reset: false,
          submit: false,
        });
      });
  };

  const sendOTP = (resend) => {
    setLoading({
      resend: resend,
      init: !resend,
      reset: false,
      submit: false,
    });

    const request_body = {
      email: values.email,
    };

    publicAxios
      .post("v2/login", request_body)
      .then((res) => {
        const resp = res.data;
        if (resp.message === "SUCCESS") {
          showOtpForm(resp?.new_user, false);
          if (resp?.new_user) {
            setShowToast({
              status: true,
              type: "success",
              successMessage: "Otp sent to your email!",
            });
          }
        }
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      })
      .finally(() => {
        setLoading({
          resend: false,
          init: false,
          reset: false,
          submit: false,
        });
      });
  };

  const resetPassword = async () => {
    try {
      setLoading({ init: true, resend: false, reset: false, submit: true });
      const request_body = {
        ...values,
      };
      const response = await publicAxios.post(
        "v2/login/password/verify",
        request_body
      );
      const responseData = response.data;
      if (responseData.message === "SUCCESS") {
        showOtpForm(false, false, false);
        setShowToast({
          status: true,
          type: "success",
          successMessage: "Your password has been reset!",
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Something went wrong, please try again later!";
      setShowToast({
        status: true,
        type: "error",
        errorMessage: errorMessage,
      });
    } finally {
      setLoading({
        resend: false,
        init: false,
        reset: false,
        submit: false,
      });
    }
  };

  // verify otp
  const verifyOTP = () => {
    console.log("values.reenterpassword ", values.reenterpassword);
    if (values.reenterpassword) {
      if (
        values.password.length < 8 ||
        !/[A-Z]/.test(values.password) ||
        !/[a-z]/.test(values.password) ||
        !/\d/.test(values.password) ||
        !/[!@#$%^&*()]/.test(values.password)
      ) {
        setShowToast({
          status: true,
          type: "error",
          errorMessage:
            "Your password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character (e.g., @, #, $, %)",
        });
        return;
      }
    }

    if (values.reenterpassword && values.password !== values.reenterpassword) {
      setShowToast({
        status: true,
        type: "error",
        errorMessage: "Passwords do not match",
      });
      return;
    }

    setLoading({ init: true, resend: false, reset: false });

    const request_body = {
      ...values,
      country_code: "IN",
      new_user: toggleParams.login_user,
    };

    publicAxios
      .post("v2/login/verify", request_body)
      .then((res) => {
        const resp = res.data;
        if (resp.message === "SUCCESS") {
          const access_data = {
            access: resp.access,
            refresh: resp.refresh,
          };
          setAuthTokens(access_data.access, access_data.refresh);
          setAuth(access_data);
          let user = resp.user;
          let user_data = {
            id: user.id,
            city: user.city,
            email: user.email,
            email_verified: user.email_verified,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            state: user.state,
            address: "",
            country: "IN",
            profile_image: {
              id: null,
              value: null,
              url: "",
            },
          };
          localStorage.setItem("userData", JSON.stringify(user_data));
          const { from } = location.state || { from: location };
          navigate(from, { replace: true });
          handleClose();
          setTimeout(() => {
            window.location.reload();
          }, 10000);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.custom_message ||
          error.response?.data?.message ||
          "Something went wrong, please try again later!";
        setShowToast({
          status: true,
          type: "error",
          errorMessage: errorMessage,
        });
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading({ init: false, resend: false });
      });
  };

  const handlePhoneOnChange = (email) => {
    let Email = email.target.value;
    setValues((pre) => ({
      ...pre,
      email: Email,
    }));
  };

  const handleOnChange = (e) => {
    setValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleParams.loginStep === 1 ? sendOTP() : verifyOTP();
  };

  return {
    hero: hero,
    error: error,
    values: values,
    loading: loading,
    toggleParams: toggleParams,
    sendOTP: sendOTP,
    setValues: setValues,
    verifyOTP: verifyOTP,
    handleClose: handleClose,
    handleSubmit: handleSubmit,
    handleOnChange: handleOnChange,
    handlePhoneOnChange: handlePhoneOnChange,
    resetPassword: resetPassword,
    sendReset: sendReset,
    handleEditEmail: handleEditEmail,
    setCountryCode,
    countryCode,
  };
};
