import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Divider,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { useState } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export default function CreateVideoNameDialog({
  open,
  handleClose,
  handleNameConfirm,
  handleVideoName,
  videoNameId,
  chapters,
}) {
  console.log(handleVideoName);
  const auth = useRecoilValue(authAtom);
  const [name, setName] = useState("");
  const handleSubmit = () => {
    const fileData = new FormData();
    fileData.append("name", name);
    fileData.append("type", "video");
    publicAxios
      .post(`v2/resource/${videoNameId}`, fileData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        chapters.forEach((element) => {
          element.resources.forEach((ele) => {
            console.log(ele);
            if (ele.id == videoNameId) {
              ele.file.name = name;
            }
          });
        });
        handleVideoName();
      })
      .catch((err) => {});
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      top="50%"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        borderRadius={2}
        bgcolor="white"
        width="60%"
        padding={2}
        marginX="auto"
      >
        <Typography fontWeight={700} fontSize="1.25rem">
          Edit Video Name
        </Typography>
        <Divider sx={{ margin: "1rem 0" }} />
        <Typography fontSize="1rem">Video Name</Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <Box
          display="flex"
          gap={2}
          marginLeft="auto"
          marginTop={2}
          width="max-content"
        >
          <Button onClick={handleSubmit} variant="contained">
            Confirm
          </Button>
          <Button
            onClick={handleVideoName}
            variant="contained"
            sx={{ backgroundColor: "#e5e5e5", color: "black" }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
