import {
  TextField,
  Button,
  Avatar,
  MenuItem,
  Select,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { useProfile } from "./useProfile";
import * as constant from "./constant";

import "./User.scss";
import { useRecoilValue } from "recoil";
import { stateReasonAtom } from "../../state/stateReason";
import { textFieldStyle } from "./constant";

const ProfileCard = () => {
  const states = useRecoilValue(stateReasonAtom);
  const { values, profileRef, handleOnChange, handleSubmit } = useProfile();

  return (
    <div className="card">
      <div className="profileImage">
        <div className="wrapper">
          <div
            className="avatar"
            onClick={() => {
              profileRef.current.click();
            }}
          >
            <Avatar
              src={values?.profile_image?.url}
              alt={values?.full_name}
              sx={{ width: 200, height: 200 }}
            />

            {/* <img className="fluid-image" src={values?.profile_image} alt="" /> */}
            <input
              type="file"
              name="profile_image"
              onChange={handleOnChange}
              ref={profileRef}
            />
          </div>
          <div>
            <h3>{values?.full_name}</h3>
          </div>
        </div>
      </div>
      <div className="formGroup">
        <form onSubmit={handleSubmit}>
          {constant.inputFields.map((item) =>
            item.name === "phone" ? (
              <PhoneInput
                key={`profile-${item.id}`}
                value={values[item.name]}
                label={item.label}
                country={"in"}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                disabled={item.disabled}
                containerClass="phoneContainer"
                inputClass="loginPhone"
              />
            ) : item.type === "select" ? (
              <TextField
                select
                key={`profile-${item.id}`}
                variant="outlined"
                name={item.name}
                label={item.label}
                sx={item.sx}
                disabled={item.disabled}
                required={item.required}
                value={values[item.name]}
                onChange={handleOnChange}
              >
                <MenuItem value="" disabled>
                  {item.placeholder}
                </MenuItem>
                {states.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <div>
                <TextField
                  key={`profile-${item.id}`}
                  variant="outlined"
                  name={item.name}
                  disabled={item.disabled}
                  label={item.label}
                  type={item.type}
                  placeholder={item.placeholder}
                  sx={item.sx}
                  required={item.required}
                  value={values[item.name]}
                  InputProps={item.inputProps}
                  onChange={handleOnChange}
                  error={item.error}
                  helperText={item.helperText}
                />
                {/* {item.name === "email" && <Button sx={{display:"inline"}} onClick={handleVarifyEmail}>verify Email</Button>} */}
              </div>
            )
          )}

          <div className="updateButton">
            <Button variant="contained" type="submit">
              Update Profile
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileCard;
