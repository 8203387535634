import { InputAdornment } from "@mui/material";

export const textFieldStyle = {
  color: "#3c4852",
  width: "100%",
  marginTop: "1rem",
};
export const inputFields = [
  {
    id: 1,
    name: "first_name",
    label: "First Name",
    disabled: true,
    type: "text",
    placeholder: "First Name",
    inputProps: {
      maxLength: 50,
    },
    sx: { ...textFieldStyle, gridColumn: "1/3" },
    required: true,
    error: false,
    helperText: "",
  },
  {
    id: 2,
    name: "last_name",
    disabled: true,
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
    inputProps: {
      maxLength: 50,
    },
    sx: { ...textFieldStyle, gridColumn: "1/3" },
    required: true,
    error: false,
    helperText: "",
  },
  // {
  //   id: 1,
  //   name: "full_name",
  //   label: "Full Name",
  //   type: "text",
  //   placeholder: "Full Name",
  //   inputProps: {
  //     maxLength: 50,
  //   },
  //   sx: { ...textFieldStyle, gridColumn: "1/3" },
  //   required: true,
  //   error: false,
  //   helperText: "",
  // },
  {
    id: 3,
    name: "email",
    label: "Email",
    disabled: true,
    type: "email",
    placeholder: "E-mail Address",
    inputProps: {
      maxLength: 50,
    },
    sx: { ...textFieldStyle, gridColumn: "1/3" },
    required: true,
    error: false,
    helperText: "",
  },
  {
    id: 4,
    name: "phone",
    label: "Phone Number",
    disabled: true,
    type: "text",
    placeholder: "Phone Number",
    inputProps: { maxLength: 20 },
    required: true,
    error: false,
    helperText: "",
  },
  // {
  //   id: 5,
  //   name: "address",
  //   disabled: true,
  //   label: "Address",
  //   type: "text",
  //   placeholder: "Address of residence",
  //   inputProps: {
  //     maxLength: 520,
  //   },
  //   sx: { ...textFieldStyle, gridColumn: "1/3" },
  //   required: true,
  //   error: false,
  //   helperText: "",
  // },
  {
    id: 5,
    name: "state",
    disabled: true,
    label: "State",
    type: "select",
    placeholder: "State of residence",
    inputProps: {
      maxLength: 520,
      startAdornment: (
        <InputAdornment position="start">
          <img src="/images/profile/location2.svg" alt="state" />
        </InputAdornment>
      ),
    },
    sx: textFieldStyle,
    required: true,
    error: false,
    helperText: "",
  },
];
