import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const useFetchBlogDetail = (id) => {
  const auth = useRecoilValue(authAtom);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noBlogData, setNoBlogData] = useState(false);
  const [blogData, setBlogData] = useState({});
  useEffect(() => {
    setLoading(true);
    setError(false);
    publicAxios
      .get(`v2/get/post/${id}`, {
        // headers: {
        // {/*v2/get/post/<id>*/}
        //   Authorization: "Bearer " + auth.access,
        // },
      })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setBlogData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setNoBlogData(true);
          setLoading(false);
        }
        setError(true);
        console.info("error", e);
      });
  }, [id]);

  return [blogData, loading, noBlogData, error];
};
