import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useEffect, useState } from "react";

export const CourseCurriculumn = ({ refName = null, curriculumnData }) => {
  const initialMediaData = { video: 0, file: 0, test: 0 ,filterData:[]};
  const [closeCurriculumnSection, setCloseCurriculumnSection] = useState([]);
  const [mediaData, setMediaData] = useState(initialMediaData);
   console.log(curriculumnData);
  const toggleCurriculumnSection = (index) => {
    setCloseCurriculumnSection((pre) => {
      const updated_ = [...pre];
      updated_[index] = !pre[index];
      return updated_;
    });
  };
  let filterData = []
  useEffect(() => {
    if (curriculumnData && curriculumnData.length > 0) {
      // let lectureCount = 0,
      //   resourceCount = 0;
     
      curriculumnData.map((item) => {
        if (filterData.length > 0 && item.type === "video") {
          console.log(filterData);
          const data = filterData.find((ele) => ele.id === item.chapter.id);
      
          if (data) {
            data.lecture = [...(data.lecture || []), { id: item.id, name: item.name, url: item.url,type:item.type }];
            console.log(data);
          } else {
            filterData.push({ ...item.chapter, lecture: [{ id: item.id, name: item.name, url: item.url,type:item.type }] });
          }
        } else if (item.type === "video") {
          filterData.push({ ...item.chapter, lecture: [{ id: item.id, name: item.name, url: item.url,type:item.type }] });
        }
      });
      console.log(filterData);
      // for (var i = 0; i < curriculumnData.length; i++) {
      //   if (curriculumnData[i]?.lecture) {
      //     for (var j = 0; j < curriculumnData[i]?.lecture.length; j++) {
      //       lectureCount += 1;
      //       resourceCount +=
      //         curriculumnData[i]?.lecture[j]?.resourse_count &&
      //         curriculumnData[i]?.lecture[j]?.resourse_count !== ""
      //           ? curriculumnData[i]?.lecture[j]?.resourse_count
      //           : 0;
      //     }
      //   }
      // }

      const videoCount = curriculumnData.filter((item) => item.type === "video")
      console.log(videoCount.length)
      const documentCount = curriculumnData.filter((item) => item.type === "document")
      console.log(documentCount.length)
      const testCount = curriculumnData.filter((item) => item.type === "test")
      console.log(testCount.length)
      setMediaData({ video: videoCount, file: documentCount, test: testCount, filterData:filterData });
      setCloseCurriculumnSection(Array(curriculumnData.length).fill(false));
    }
  }, [curriculumnData]);

  return (
    curriculumnData &&
    curriculumnData.length > 0 && (
      <div className="curriculumnSection" ref={refName}>
        <h2 className="display_small">Curriculumn</h2>
        <div className="content">
          <CurriculumnHeader mediaData={mediaData} />
          <div className="curriculumn">
            {mediaData.filterData.map((item, key) => (
              <div
                key={`curriculumn_section_${item?.id}-${key}`}
                onClick={() => toggleCurriculumnSection(key)}
                className={`curriculumn_wrapper
                ${closeCurriculumnSection[key] ?  undefined : "close" }`}
              >
                <CurriculumnSectionHeader name={item?.name} />
                <ul className="curriculumn_section title_medium">
                  {item?.lecture &&
                    item?.lecture.length > 0 &&
                    item?.lecture.map((item2, key2) => (
                      <CurriculumnSubSection
                        key={`curriculumn_sub_section_${item2?.id}-${key2}`}
                        name={item2.name}
                      />
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

const CurriculumnSectionHeader = ({ name }) => {
  return (
    <div className="curriculumn_section_header headline_small">
      <div>{name}</div>
      <div>
        <IconButton className="arrow up">
          <KeyboardArrowUpIcon fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
};

const CurriculumnSubSection = ({ name }) => {
  return (
    <li className="curriculumn_sub_section">
      <div>
        <PlayCircleFilledWhiteIcon />
        {/* <img className="fluid-image" src="/images/courses/sub_section.svg" alt="file" /> */}
      </div>
      <div>{name}</div>
    </li>
  );
};

const CurriculumnHeader = ({ mediaData }) => {
  console.log(mediaData);
  return (
    <div className="curriculumn_header">
      {mediaData?.video.length > 0 && (
        <MediaCard
          title="Video Lessons"
          count={mediaData?.video.length}
          src="/images/courses/video.svg"
          alt="video"
        />
      )}
      {mediaData?.file.length > 0 && (
        <MediaCard
          title="Files"
          count={mediaData?.file.length}
          src="/images/courses/notes.svg"
          alt="files"
        />
      )}
      {mediaData?.test.length > 0 && (
        <MediaCard
          title="Tests"
          count={mediaData?.test.length}
          src="/images/courses/test.svg"
          alt="tests"
        />
      )}
    </div>
  );
};

const MediaCard = ({ title, count, src, alt }) => {
  console.log(title,count,src,alt);
  return (
    <div className="mediaCard">
      <h5 className="label_medium">{title}</h5>
      <div className="wrapper">
        <div className="headline_large">
          {count}
          <span>+</span>
        </div>
        <div>
          <img className="fluid-image" src={src} alt={alt} />
        </div>
      </div>
    </div>
  );
};
