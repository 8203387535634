/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedQuestionAnswerAtom } from "../../../state/atoms/OnlineTest/selectedQuestionAnswerAtom";
import { selectedSectionAtom } from "../../../state/atoms/OnlineTest/selectedSectionAtom";
import privateAxios from "../../../axios/privateAxios";
import { sectionDataAtom } from "../../../state/atoms/OnlineTest/sectionDataAtom";
import { authAtom } from "../../../state/atoms/authAtom";

const initialQuestionAnswerValue = {
  question_answer_id: "",
  option_id: "",
  answer: "",
};

export const useQuestionAnswer = (onlineTestData) => {
  const [loading, setLoading] = useState(false);
  const [questionData, setQuestionData] = useState();
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [selectedSection, setSelectedSection] = useRecoilState(selectedSectionAtom);
  const [questionAnswerValues, setQuestionAnswerValues] = useState(initialQuestionAnswerValue);
  const [selectedQuestionAnswer, setSeletedQuestionAnswer] = useRecoilState(
    selectedQuestionAnswerAtom
  );
  const sectionData = useRecoilValue(sectionDataAtom);
  const auth = useRecoilValue(authAtom);

  /** when section changes load 1st question in that section */
  useEffect(() => {
    console.log(selectedSection)
    selectedSection?.data &&
      selectedSection?.data.length > 0 &&
      setQuestionAnswerValues({
        question_answer_id: selectedSection?.data[0]?.questions[0]?.question_answer?.id,
        option_id: '',
        answer: '',
      });
    //  setQuestionData(selectedSection?.data[0]?.questions[0]);
      setSeletedQuestionAnswer(selectedSection?.data[0]?.questions[0]);
  }, [selectedSection?.name]);

  /** when selectedQuestionAnswer status is changed load next question */
  useEffect(() => {
   // handleNextQuestion("load");
  }, [selectedSection?.data]);

  /** when selectedQuestionAnswer status is changes */
  useEffect(() => {
    setSelectedSection((pre) => {
      const data = pre?.data;
      for (let i = 0; i < data.length; i++) {
        if (
          data[i]?.id === selectedQuestionAnswer?.id &&
          data[i].status !== selectedQuestionAnswer.status
        ) {
          return {
            ...pre,
            data: [...data.slice(0, i), selectedQuestionAnswer, ...data.slice(i + 1)],
          };
        }
      }
      return pre;
    });
  }, [selectedQuestionAnswer?.status]);

  /** when selectedQuestionAnswer changes fetch question */
  useEffect(() => {
    const loadQuestion = (questionAnswerId, onlineTestId) => {
      setLoading(false);
      privateAxios
        .get(`/onlinetest/${onlineTestId}/question`, {
          params: { question_answer_id: questionAnswerId },
          headers: {
            "Authorization": "Bearer " + auth.access,
          }
        })
        .then((res) => {
          const questionAnswer = res?.data;
          setLoading(false);
          setQuestionData(questionAnswer?.question);
          setQuestionAnswerValues({
            question_answer_id: questionAnswerId,
            option_id: questionAnswer?.selected_option,
            answer: questionAnswer?.answer,
          });

          handleNextQuestion() ? setShowNextBtn(true) : setShowNextBtn(false);
        })
        .catch((e) => console.log(e));
    };

    selectedQuestionAnswer?.id &&
      onlineTestData?.id &&
      loadQuestion(selectedQuestionAnswer?.id, onlineTestData?.id);
  }, [selectedQuestionAnswer?.id]);

  /** function for answer to the selectedQuestionAnswer */
  const handleSaveQuestion = (questionStatus = "") => {

    const submitQuestion = (questionStatus, onlineTestId) => {
      const req_body = {
        ...questionAnswerValues,
        question_status: questionStatus,
      };
      privateAxios
        .post(`/onlinetest/${onlineTestId}/question`, req_body, {headers: {
          "Authorization": "Bearer " + auth.access,
        }})
        .then((res) => {
          setSeletedQuestionAnswer((pre) => ({ ...pre, status: res?.data?.status }));
          if (questionStatus === res?.data?.status) {
            handleNextQuestion("load");
            return;
          }
        })
        .catch((e) => console.log(e));
    };
   console.log(questionStatus,selectedQuestionAnswer?.status);
    if (questionStatus === "") {
      if (questionAnswerValues?.answer !== "" || questionAnswerValues?.option_id !== "") {
        questionStatus = "ANSWERED";
      } else {
        questionStatus = "NOT_ANSWERED";
      }
    }
   // handleNextQuestion("load");
    
    console.log(onlineTestData?.id )
    onlineTestData?.id && submitQuestion(questionStatus, onlineTestData?.id);
  };

  /**function to check and load next question :: action enum: check, load */
  const handleNextQuestion = (action = "check") => {
    /** check next question in the selectedSection */
    console.log(action)
    console.log(selectedSection)
    console.log(selectedQuestionAnswer)
    const data = selectedSection?.data[0].questions;
    for (let i = 0; i < data.length; i++) {
      if (data[i]?.sr_no === selectedQuestionAnswer?.sr_no + 1) {
        console.log(data[i]);
        action === "load" && setSeletedQuestionAnswer(data[i]);
        console.log(selectedQuestionAnswer);
        return true;
      }
    }

    /** check next question in the sectionData(probably next section) */
    const sectionsNameList = Object.keys(sectionData);
    console.log(sectionsNameList)
    if (sectionsNameList.length > 1) {
      for (let i = 0; i < sectionsNameList.length; i++) {
        if (sectionsNameList[i] === selectedSection?.name && i + 1 < sectionsNameList.length) {
          // console.log(sectionsNameList);
          const next_selectedsection = sectionData[sectionsNameList[i + 1]];
          console.log(next_selectedsection);
          action === "load" && setSelectedSection({
            name: next_selectedsection[0].name,
            data: next_selectedsection,
          });;
          return true;
        }
      }
    }
    return false;
  };
console.log(selectedQuestionAnswer)
  /** function to clear responses of selectedQuestionAnswer */
  const handleClearResponse = () => {
    selectedQuestionAnswer?.id &&
      setQuestionAnswerValues({
        ...initialQuestionAnswerValue,
        questionAnswer_id: selectedQuestionAnswer?.id,
      });
  };

  /** function when user update the answer */
  const handleOnchange = (e) => {
    const {name,value} = e.target
    if(name === 'option_id'){
      setQuestionAnswerValues({answer:'',option_id:value,question_answer_id:selectedQuestionAnswer.question_answer.id});
    } else if (name === "answer") {
      setQuestionAnswerValues({option_id:'',answer:value,question_answer_id:selectedQuestionAnswer.question_answer.id });
    }
   // setQuestionAnswerValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleOpenSubmitModal = () => {
    setOpenSubmitModal(true);
  };
  const handleCloseSubmitModal = () => {
    setOpenSubmitModal(false);
  };

  const disabledBtn =
    questionAnswerValues?.option_id !== "" || questionAnswerValues?.answer !== "" ? false : true;

  return {
    loading: loading,
    showNextBtn: showNextBtn,
    disabledBtn: disabledBtn,
    questionData: questionData,
    openSubmitModal: openSubmitModal,
    selectedQuestionAnswer: selectedQuestionAnswer,
    questionAnswerValues: questionAnswerValues,
    handleOnchange: handleOnchange,
    handleSaveQuestion: handleSaveQuestion,
    handleClearResponse: handleClearResponse,
    handleOpenSubmitModal: handleOpenSubmitModal,
    handleCloseSubmitModal: handleCloseSubmitModal,
  };
};
