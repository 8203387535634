import React from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const ConfirmationModal = ({ message, closeModal, data, handleActions }) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign="center"
        >
          {message}
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "center", gap: "10px", mt: 2 }}
        >
          <Button variant="contained" color="primary" onClick={handleActions}>
            {data.loading ? (
              <CircularProgress
                size={15}
                style={{ color: "white", marginRight: "10px" }}
              />
            ) : null}
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={closeModal}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
