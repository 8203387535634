import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";

export const useFetchTestseriesLimit = (sort = "default", limit = 5) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noTestseriesData, setNoTestseriesData] = useState(false);
  const [testseriesData, setTestseriesData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    publicAxios
      .get("v2/client/testseries", {
        params: { sort: getSortParameter(sort), limit: limit },
      })
      .then((res) => {
        if (res.status === 200) {
          let resp = res.data;
          if (resp.length > 0) {
            setTestseriesData(resp);
            setLoading(false);
          } else {
            setNoTestseriesData(true);
          }
          setLoading(false);
        }
      })
      .catch((e) => {
        setError(true);
        console.info("error", e);
      });
  }, [limit, sort]);

  return [testseriesData, loading, noTestseriesData, error];
};

const getSortParameter = (sort) => {
  let testseriesSort = sort;
  return testseriesSort;
};
