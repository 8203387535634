import { useState } from "react";
import { Typography, Container, Paper, TextField, Grid,Box, Stack, Button  } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DoneIcon from '@mui/icons-material/Done';

// import Loader from "../../Tools/Loader";

const EvaluationForm = ({data}) => {
    const paperStyle = {
        backgroundColor: 'transparent',   // Setting background color
        boxShadow: 'none',
        marginTop: "1%"
    };

    return (
        <>
            <Box style={{background:"white", height:"100%", padding:"0 35px"}}>
                <Stack >
                    <Grid container spacing={2} >
                        <Grid item xs={6} style={{display:"flex"}} alignItems="center">
                            <Typography fontWeight="600">Name of the Student</Typography>
                        </Grid>
                        <Grid item xs={6} direction={"row"}  >
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={6}>
                                    <Typography>Total Marks:</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                <TextField placeholder="2" inputProps={{style:{width:"20px",height:"5px"}}}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                <Button variant="contained">Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
                <hr style={{margin:"10px 0"}}></hr>
                {data.length ? (<Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{marginLeft: "-19px"}}>1. Question Title - Usually a Long Answer</Typography>
                            <Typography style={{marginTop: "20px"}}>Students Answer</Typography>
                            <TextField multiline rows={4} placeholder="Students Answer" fullWidth></TextField>
                            <Typography style={{marginTop: "20px"}}>Teacher's Comments</Typography>
                            <TextField fullWidth multiline rows={4} placeholder="Evaludation and Remark"></TextField>
                        </Grid>
                        <Grid container spacing={2} style={{marginTop: "10px", marginLeft:"8px"}}>
                            <Grid item xs = {6}>
                                <Typography>Total Marks</Typography>
                                <TextField placeholder="120" inputProps={{style:{width:"55px"}}}></TextField>
                            </Grid>
                            <Grid item xs = {6}>
                                <Typography>Marks Awarded</Typography>
                                <TextField placeholder="2" inputProps={{style:{width:"80px"}}}></TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{marginTop: "10px", justifyContent:"end"}}>
                            <Grid item xs = {6}>
                                <Button variant="outlined" endIcon={<DoneIcon />} style={{marginRight:"20px"}}>Submitted</Button>
                                <Button variant="contained">Submit</Button>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Stack>):(<>No Record Found</>)}
            </Box>
        </>
    );
};

export default EvaluationForm;
