import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";

export const useFetchBlogs = (
  category,
  page,
  dateRange,
  dateFun,
  state,
  reset
) => {
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noBlogData, setNoBlogData] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const blog_category = {
    current_affair: "1",
    editorial: "2",
    job: "3",
    result: "4",
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await publicAxios.get(
          getBlogURL(category, blog_category),
          {
            params: {
              page: page,
              start_date: dateRange
                ? dateRange?.start_date?.format("YYYY-MM-DD")
                : "",
              end_date: dateRange
                ? dateRange?.end_date?.format("YYYY-MM-DD")
                : "",
            },
          }
        );

        if (response.status === 200) {
          const { data, total_page } = response.data;
          setTotalPages(total_page);

          if (data.length > 0) {
            if (reset) {
              setBlogData(data);
            } else {
              const alreadyBlogIds = new Set(blogData.map((blog) => blog.id));
              const newBlogs = data.filter(
                (post) => !alreadyBlogIds.has(post.id)
              );
              setBlogData((prevBlogData) => [...prevBlogData, ...newBlogs]);
            }
            setNoBlogData(false);
          } else if (data.length === 0 && page === 1) {
            setNoBlogData(true);
          }
        }
      } catch (e) {
        setError(true);
        console.info("error", e);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [page, category, dateFun, state, reset, dateRange]);

  return [blogData, totalPages, loading, noBlogData, error];
};

const getBlogURL = (category, blog_category) => {
  let blogCategory;
  switch (category) {
    case "CurrentAffair":
      blogCategory = blog_category.current_affair;
      break;
    case "Editorial":
      blogCategory = blog_category.editorial;
      break;
    case "Job":
      blogCategory = blog_category.job;
      break;
    case "Result":
      blogCategory = blog_category.result;
      break;
    default:
      blogCategory = blog_category.current_affair;
      break;
  }
  return `/v2/posts/all?category_id=${blogCategory}`;
};
