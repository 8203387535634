import React, { useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
// import  styles from './AdminNavbar.module.css';
import "./AdminSidebar.css";
import { Link, useNavigate } from "react-router-dom";

export const AdminSidebar = () => {
  const [toggled, setToggled] = useState(false);
  const navigate = useNavigate();

  const [broken, setBroken] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  return (
    <Sidebar
      toggled={toggled}
      customBreakPoint="800px"
      onBreakPoint={setBroken}
      width="200px"
      backgroundColor="#0373BA"
      style={{ height: "100vh" }}
    >
      <Menu>
        <MenuItem style={{ background: "#FCB03E", textAlign: "center" }}>
          <div onClick={() => navigate("/u1NzT9Pq2s5/admin/dashboard")}>
            <img src="/images/sidebar-logo.png" alt="" />
          </div>
        </MenuItem>
        <MenuItem
          style={{
            background: "#C42730",
            color: "white",
            margin: "5%",
            fontFamily: "Lato",
            fontWeight: "bold",
          }}
          onClick={() => navigate("/u1NzT9Pq2s5/admin/live-class")}
        >
          Live Class
        </MenuItem>
        <MenuItem
          style={{
            background: "#FCB03E",
            color: "white",
            margin: "5%",
            fontFamily: "Lato",
            fontWeight: "bold",
          }}
          onClick={() => navigate("/u1NzT9Pq2s5/admin/announcement")}
        >
          Announcement
        </MenuItem>
      </Menu>
      <div style={{ color: "white", padding: "5%", fontFamily: "Lato" }}>
        MAIN MENU
      </div>
      <Menu
        style={{
          color: "white",
          fontFamily: "Lato",
          fontWeight: "bold",
          fontSize: "12px",
        }}
      >
        <Link
          to="/u1NzT9Pq2s5/admin/product"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            All Products
          </MenuItem>
        </Link>
        <Link
          to="/u1NzT9Pq2s5/admin/post"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Posts
          </MenuItem>
        </Link>
        <SubMenu
          icon={<img src="/images/admin/icon.svg" alt="Admin" />}
          label="Course Management"
          className="menu-item"
        >
          <Link
            to="/u1NzT9Pq2s5/admin/course"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>All Courses</MenuItem>
          </Link>
          <Link
            to="/u1NzT9Pq2s5/admin/course/addCourse"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>Add Course</MenuItem>
          </Link>
        </SubMenu>
        <Link
          to="/u1NzT9Pq2s5/admin/user"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            User Management
          </MenuItem>
        </Link>
        {/* <Link
          to="/admin/test"
          style={{ color: "white", textDecoration: "none" }}
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Test Series Management
          </MenuItem>
        </Link> */}
        <SubMenu
          icon={<img src="/images/admin/icon.svg" alt="Admin" />}
          label="Test Management"
          className="menu-item"
        >
          <Link
            to="/u1NzT9Pq2s5/admin/test/allTestSeries"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>All Test Series</MenuItem>
          </Link>
          <Link
            to="/u1NzT9Pq2s5/admin/test"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>Test Library</MenuItem>
          </Link>
          <Link
            to="/u1NzT9Pq2s5/admin/test/addTestSeries"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>Add Test Series</MenuItem>
          </Link>
          <Link
            to="/u1NzT9Pq2s5/admin/test/evaluation"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>Evaluation</MenuItem>
          </Link>
        </SubMenu>
        {/* <Link
          to="/admin/payment"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Payment Management
          </MenuItem>
        </Link> */}
        <SubMenu
          icon={<img src="/images/admin/icon.svg" alt="Admin" />}
          label="Payment Management"
          className="menu-item"
        >
          <Link
            to="/u1NzT9Pq2s5/admin/payment"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>All Orders</MenuItem>
          </Link>
          <Link
            to="/u1NzT9Pq2s5/admin/payment/analytics"
            style={{ color: "black", textDecoration: "none" }}
          >
            <MenuItem>Analytics</MenuItem>
          </Link>
          {/*<Link*/}
          {/*  to="/admin/payment/taxes"*/}
          {/*  style={{ color: "black", textDecoration: "none" }}*/}
          {/*>*/}
          {/*  <MenuItem>Taxes</MenuItem>*/}
          {/*</Link>*/}
        </SubMenu>
        <Link
          to="/u1NzT9Pq2s5/admin/student"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Student Management
          </MenuItem>
        </Link>
        <Link
          to="/u1NzT9Pq2s5/admin/faculty"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Faculty Management
          </MenuItem>
        </Link>
        <Link
          to="/u1NzT9Pq2s5/admin/query"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Query Management
          </MenuItem>
        </Link>
        <Link
          to="/u1NzT9Pq2s5/admin/coupon"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Coupon Management
          </MenuItem>
        </Link>
        <Link
          to="/u1NzT9Pq2s5/admin/calendar"
          style={{ color: "white", textDecoration: "none" }}
          className="menu-item"
        >
          <MenuItem icon={<img src="/images/admin/icon.svg" alt="Admin" />}>
            Calendar
          </MenuItem>
        </Link>
      </Menu>
    </Sidebar>
  );
};
