/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState,useRecoilValue } from "recoil";

import privateAxios from "../../axios/privateAxios";
import { selectedSectionAtom } from "../../state/atoms/OnlineTest/selectedSectionAtom";
import { selectedQuestionAnswerAtom } from "../../state/atoms/OnlineTest/selectedQuestionAnswerAtom";
import { sectionDataAtom } from "../../state/atoms/OnlineTest/sectionDataAtom";
import { authAtom } from "../../state/atoms/authAtom";

export const useOnlineTest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [onlineTestData, setOnlineTestData] = useState();
  const [onlineTestLoading, setOnlineTestLoading] = useState(true);
  const [questionAnswerData, setQuestionAnswerData] = useState([]);
  const [sectionData, setSectionData] = useRecoilState(sectionDataAtom);
  const [, setSelectedSection] = useRecoilState(selectedSectionAtom);
  const [selectedQuestionAnswer, setSeletedQuestionAnswer] = useRecoilState(
    selectedQuestionAnswerAtom
  );
  const auth = useRecoilValue(authAtom);

  /** load onlineTest */
  useEffect(() => {
    const loadOnlineTest = (onlineTestId) => {
      privateAxios
        .get(`/onlinetest/${onlineTestId}`,{
          headers: {
            "Authorization": "Bearer " + auth.access,
          }
        })
        .then((res) => {
          setOnlineTestData(res?.data);
          setQuestionAnswerData(res?.data?.questionsAnswer);
          setOnlineTestLoading(false);
        })
        .catch((e) => {
          if(e?.response?.status === 400){
            navigate('/');
          }
          console.log(e);
        });
    };

    params.onlineTestId && loadOnlineTest(params?.onlineTestId);
  }, []);

  useEffect(() => {
    let sections = {};
    onlineTestData && onlineTestData.test_sections &&  onlineTestData.test_sections.map((item) => {
      if (sections[item?.name]) {
        return (sections[item?.name] = [item, ...sections[item?.name]]);
      } else {
        return (sections[item?.name] = [item]);
      }
    });

    const sortByKeys = (obj) => {
      return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    };

    if (Object.keys(sections).length > 0) {
      const updatedSection = sortByKeys(sections);     
      let _counter = 1;
      for (let key in updatedSection) {
          for (let i = 0; i < updatedSection[key][0]?.questions.length; i++) {
            updatedSection[key][0].questions[i].sr_no = _counter++;
        }
      }

      const _selectedsection = Object.keys(updatedSection)[0];
      setSectionData(updatedSection);
      setSelectedSection({
        name: _selectedsection,
        data: updatedSection[_selectedsection],
      });
    }
  }, [questionAnswerData]);
  console.log(sectionData)
  const handleLoadQuestion = (questionAnswer) => {
    if (questionAnswer && questionAnswer !== selectedQuestionAnswer) {
      setSeletedQuestionAnswer(questionAnswer);
    }
  };

  return {
    onlineTestData: onlineTestData,
    onlineTestLoading: onlineTestLoading,
    handleLoadQuestion: handleLoadQuestion,
  };
};
