import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";

export const useFetchCourseLimit = (
  sort = "default",
  limit = 5,
  type = "academic"
) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noCourseData, setNoCourseData] = useState(false);
  const [courseData, setCourseData] = useState([]);

  useEffect(() => {
    // http://13.233.147.30:8002/v2/client/course?page=1&category_type=academic&category_id=1
    setLoading(true);
    setError(false);
    publicAxios
      .get("/v2/client/course?category_type=academic", {
        params: { sort: getSortParameter(sort), limit: limit },
      })
      .then((res) => {
        if (res.status === 200) {
          let resp = res.data;
          if (resp.length > 0) {
            setCourseData((courseData) => [...courseData, ...resp]);
          } else {
            setNoCourseData(true);
          }
          // console.log(resp);
          setLoading(false);
        }
      })
      .catch((e) => {
        setError(true);
        console.info("error", e);
      });
  }, [limit, sort, type]);

  return [courseData, loading, noCourseData, error];
};

const getSortParameter = (sort) => {
  let blogSort = sort;
  return blogSort;
};
