import React, { useRef, useEffect, useState } from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import publicAxios from "../../../axios/publicAxios";
import {
  Avatar,
  Badge,
  Button,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  TextField,
  Tooltip,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

export const EditUser = () => {
  const auth = useRecoilValue(authAtom);
  const fileInputRef = useRef(null);
  const [dept, setDept] = React.useState("");
  const [firstLogin, setFirstLogin] = useState(true);
  const [formData, setFormData] = useState({
    firstName: {
      value: "",
    },
    lastName: {
      value: "",
    },
    userName: {
      value: "",
    },
    password: {
      value: "",
    },
    confirmPassword: {
      value: "",
    },
    number: {
      value: "",
    },
    email: {
      value: "",
    },
    joiningDate: {
      value: "",
    },
    otherDetails: {
      value: "",
    },
    roles: {
      value: "",
    },
    description: {
      value: "",
    },
    designation: {
      value: "",
    },
    thumbnail: {
      value: null,
      id: null,
      url: "/images/admin/Avatar.png",
    },
  });
  const handleChecked = (e) => {
    const { checked } = e.target;
    setFirstLogin(checked);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "number") {
      const inputLength = event.target.value.length;
      const maxLength = 10;
      if (inputLength > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
      }
    }
    setFormData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: value,
      },
    }));
    console.log(formData);
    //setDept(event.target.value);
  };

  function handleThumbnailImage(event) {
    //console.log(event.target.files);
    const resourceId = formData.thumbnail.id;
    uploadResource("", "image", event.target.files[0], resourceId).then(
      (res) => {
        setFormData((formData) => ({
          ...formData,
          thumbnail: {
            ...formData.thumbnail,
            value: event.target.files[0],
            id: res.data.id,
          },
        }));
      }
    );
  }

  const uploadResource = (id, type, file, resourceId) => {
    console.log(file);
    const fileData = new FormData();
    //  type === 'video' && fileData.append('name',file.name)
    fileData.append("file", file);
    fileData.append("type", type);
    return publicAxios
      .post(
        `v2/${resourceId ? "resource/" + resourceId : "resources"}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  const handleSubmit = () => {
    const request_body = {
      username: formData.firstName.value,
      first_name: formData.firstName.value,
      last_name: formData.lastName.value,
      password: formData.password.value,
      email: formData.email.value,
      phone: formData.number.value,
      resource_id: formData.thumbnail.id,
      role: formData.roles.value,
      designation: formData.designation.value,
      additional_details: formData.otherDetails.value,
      address: formData.description.value,
      first_login: firstLogin,
      joining_date: formData.joiningDate.value,
    };
    // {
    //             headers: {
    //               "Authorization": "Bearer " + auth.access,
    //             },
    //           }
    publicAxios
      .post(`/v2/account/${variable}`, request_body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //  setLoading(false);
        //  console.log(courseId);
        // navigate("/admin/course")
      })
      .catch((e) => {
        //  console.log(e);
      });
  };
  const getInfo = (id) => {
    publicAxios
      .get(`/v2/account/${id}`)
      .then((res) => {
        formData.userName.value = res.data.username;
        formData.firstName.value = res.data.first_name;
        formData.lastName.value = res.data.last_name;
        formData.email.value = res.data.email;
        formData.number.value = res.data.phone;
        formData.thumbnail.id = res.data.resource.id;
        formData.thumbnail.url = res.data.resource.url;
        formData.roles.value = res.data.role;
        formData.designation.value = res.data.designation;
        formData.otherDetails.value = res.data.additional_details;
        formData.description.value = res.data.address;
        formData.joiningDate.value = res.data.joining_date;
        setFormData({ ...formData });
        setFirstLogin(res.data.first_login);
        // setLoading(false);
        //  console.log(courseId);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  const path = window.location.pathname;
  const pathSegments = path.split("/"); // Split the path by '/'
  const variable = pathSegments[4]; // Accessing the third segment, change the index as needed
  useEffect(() => {
    if (variable) {
      getInfo(variable);
    }
  }, [variable]);
  console.log(formData);
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
  }));

  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash">
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack sx={{ width: "90%" }}>
            <div className="all-user-strip">Add User</div>
            <div className="FacPaper">
              <Stack
                direction={{ xs: "column", md: "row" }}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingTop: "2%" }}
              >
                <div className="left-side">
                  <Stack direction="column">
                    <span style={{ textAlign: "start", paddingLeft: "1%" }}>
                      <strong>Basic Info</strong>
                    </span>
                    <div className="form">
                      {/* <label htmlFor="fname">First Name</label> */}
                      <TextField
                        id="outlined-basic"
                        name="firstName"
                        value={formData.firstName.value}
                        onChange={handleChange}
                        label="First Name"
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      />
                      <TextField
                        id="outlined-basic"
                        name="userName"
                        value={formData.userName.value}
                        onChange={handleChange}
                        label="User Name"
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      />
                      <TextField
                        id="outlined-basic"
                        name="email"
                        value={formData.email.value}
                        onChange={handleChange}
                        label="Email Here"
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      />
                      <TextField
                        onChange={handleChange}
                        id="outlined-password-input"
                        value={formData.password.value}
                        label="Password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        sx={{ marginTop: "2rem" }}
                      />
                      <TextField
                        onChange={handleChange}
                        label="Phone Number"
                        type="number"
                        name="number"
                        value={formData.number.value}
                        id="outlined-start-adornment"
                        sx={{ m: 1, width: "25ch", marginTop: "2rem" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                          inputProps: {
                            maxLength: 10,
                          },
                        }}
                      />
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Roles
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={formData.roles.value}
                          label="Role"
                          name="roles"
                          onChange={handleChange}
                        >
                          <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem>
                          <MenuItem value="FACULTY">Faculty</MenuItem>
                          <MenuItem value="SALES">Sales</MenuItem>
                          <MenuItem value="EDITOR">Editor</MenuItem>
                          <MenuItem value="TECHNICAL">Technical</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dept}
                        label="Department"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select> */}
                      <TextField
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Designation"
                        value={formData.designation.value}
                        name="designation"
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      />
                      <div className="upload">
                        <Tooltip title="Edit Photo">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <SmallAvatar
                                  alt="Remy Sharp"
                                  src="/images/admin/download.png"
                                  style={{ transform: "rotate(180deg)" }}
                                />
                              }
                            >
                              <Avatar
                                alt="Travis Howard"
                                src={
                                  formData.thumbnail.value
                                    ? URL.createObjectURL(
                                        formData.thumbnail.value
                                      )
                                    : formData.thumbnail?.url
                                }
                                sx={{ width: 100, height: 100 }}
                              />
                            </Badge>
                          </a>
                        </Tooltip>
                        <Button
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                          variant="contained"
                          sx={{
                            background: "#E5E5E5",
                            color: "black",
                            margin: "2%",
                          }}
                        >
                          {" "}
                          Choose File{" "}
                        </Button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleThumbnailImage}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </Stack>
                </div>
                <div className="right-side">
                  <Stack direction="column">
                    <div className="form">
                      {/* <label htmlFor="fname">First Name</label> */}
                      <TextField
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Last Name"
                        value={formData.lastName.value}
                        name="lastName"
                        variant="outlined"
                        sx={{ marginTop: "3.3rem" }}
                      />
                      <TextField
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Joining Date"
                        placeholder="YYYY-MM-DD"
                        name="joiningDate"
                        value={formData.joiningDate.value}
                        variant="outlined"
                        // InputProps={{
                        //     readOnly: true,
                        //   }}
                        InputProps={{
                          inputProps: {
                            maxLength: 10,
                          },
                        }}
                        sx={{ marginTop: "2rem" }}
                      />
                      <TextField
                        onChange={handleChange}
                        id="outlined-password-input"
                        label="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword.value}
                        autoComplete="current-password"
                        sx={{ marginTop: "2rem" }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={firstLogin}
                            onChange={handleChecked}
                          />
                        }
                        label="Reset password on first login"
                      />
                      <TextField
                        onChange={handleChange}
                        id="outlined-multiline-static"
                        label="Description"
                        name="description"
                        value={formData.description.value}
                        multiline
                        rows={4}
                        sx={{ marginTop: "2rem" }}
                      />
                      {/* <TextField
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Date of Birth"
                        variant="outlined"
                        sx={{ marginTop: "2.6rem" }}
                      /> */}
                      <TextField
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Additional Details, if any"
                        name="otherDetails"
                        value={formData.otherDetails.value}
                        variant="outlined"
                        sx={{ marginTop: "3.5rem" }}
                      />
                      <div className="upload">
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={1}
                          justifyContent="end"
                        >
                          <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{
                              background: "#025D97",
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              background: "#E5E5E5",
                              color: "black",
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </div>
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
