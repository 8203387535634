import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import CardImage from "../../Tools/CardImage";

import "./CourseCard.scss";

const CourseCard = ({
  course,
  buttonColor = "primary",
  courseRedirect = "",
  btnContent = "LEARN MORE",
  date,
}) => {
  const defaultThumbnail = "/images/courses/thumbnail.png";
  const thumbnail = course?.resource?.url;

  return (
    <div className="courseCard">
      {/* Conditionally render Link based on btnContent */}
      {btnContent !== "coming soon" ? (
        <Link to={courseRedirect} className="buttonLink">
          <div className="thumbnail">
            <CardImage
              thumbnail={thumbnail}
              defaultThumbnail={defaultThumbnail}
              alt={course.alt}
            />
          </div>
          <div className="title">
            <p className="title_large">{course.title}</p>
          </div>
          <div className="link">
            <Button
              variant="contained"
              disabled={btnContent === "coming soon"}
              color={buttonColor}
            >
              {btnContent}
            </Button>
          </div>
        </Link>
      ) : (
        // Render a disabled button if btnContent is "coming soon"
        <div className="disabledContent">
          <div className="thumbnail">
            <CardImage
              thumbnail={thumbnail}
              defaultThumbnail={defaultThumbnail}
              alt={course.alt}
            />
          </div>
          <div className="title">
            <p className="title_large">{course.title}</p>
          </div>
          <div className="link">
            <Button variant="contained" disabled={true} color={buttonColor}>
              {date}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseCard;
