import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Button, Checkbox, Stack } from "@mui/material";
import publicAxios from "../../axios/publicAxios";
import Loader from "../../components/Tools/Loader";
import { useNavigate } from "react-router-dom";
import { formatDate } from "@fullcalendar/core";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const FacTable = () => {
  const auth = useRecoilValue(authAtom);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getInfo = async () => {
    await publicAxios
      .get("v2/faculty", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  useEffect(() => {
    getInfo();
  }, []);
  const columns = [
    {
      field: "avatar",
      headerName: "",
      width: 90,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <>
            <Avatar
              src={
                currentRow.resource
                  ? currentRow.resource
                  : "/images/admin/Avatar.png"
              }
            />
          </>
        );
      },
    },
    { field: "name", headerName: "Name", width: 180 },
    // {
    //   field: "Role",
    //   headerName: "Role",
    //   width: 150,

    // },
    // {
    //   field: "active",
    //   headerName: "Inactive/Active",
    //   width: 220,
    //   renderCell: (params) => {
    //     const currentRow = params.row;
    //     return (
    //       <Stack direction="row" justifyContent="center" alignItems="center">
    //         <span>{currentRow.active ? "Active" : "Inactive"}</span>
    //       </Stack>
    //     );
    //   },
    // },
    {
      field: "joining_date",
      headerName: "Joining Date",
      width: 150,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                navigate(
                  "/u1NzT9Pq2s5/admin/faculty/editfaculty/" + currentRow.id
                );
              }}
            >
              <img src="/images/admin/edit.png" alt="" />
            </Button>
            <Button
              onClick={() => {
                const confirm = window.confirm(
                  "You sure want to Delete this user?"
                );
                if (confirm) {
                  publicAxios
                    .delete(`v2/faculty/${currentRow.id}`, {
                      headers: {
                        Authorization: "Bearer " + auth.access,
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        getInfo();
                        setLoading(false);
                      }
                    })
                    .catch((e) => {
                      if (e.response && e.response.status === 404) {
                        setLoading(false);
                      }
                      console.info("error", e);
                    });
                }
                // alert(currentRow.Name);
              }}
            >
              <img src="/images/admin/delete.png" alt="" />
            </Button>
          </Stack>
        );
      },
    },
  ];
  // Otherwise filter will be applied on fields such as the hidden column id
  return (
    <>
      {loading && <Loader />}
      {data ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={data}
            autoHeight
            pageSize={5}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      ) : (
        <>No Data available</>
      )}
    </>
  );
};
