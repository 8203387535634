export const CourseArrow = () => {
  return (
    <>
      <div className="curveArrow topLeft">
        <svg
          width="215"
          height="241"
          viewBox="0 0 215 241"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2_155)">
            <path
              d="M28.5332 228.632C24.488 234.952 16.387 234.31 14.0918 226.778C9.72956 212.446 6.66051 197.748 4.92187 182.862C34.402 91.0706 104.356 25.0656 197.281 1.09063C200.09 0.437537 202.071 2.60747 200.52 4.54571C194.827 11.5368 189.61 18.9062 184.905 26.6036C184.475 27.2453 184.193 27.9758 184.082 28.7413C183.97 29.5068 184.03 30.2878 184.258 31.0267C184.487 31.7656 184.877 32.4436 185.401 33.0107C185.924 33.5779 186.568 34.0198 187.284 34.3038C195.03 37.7627 202.531 41.754 209.732 46.2492C211.65 47.4184 210.968 50.1467 208.684 50.6734C133.375 70.5507 77.7995 122.988 53.4441 197.336C44.0121 206.964 36.1207 217.519 28.5332 228.632Z"
              fill="#C42730"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_2_155"
              x="0.921875"
              y="0.972168"
              width="213.928"
              height="239.969"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_155" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2_155"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="curveArrow topRight">
        <svg
          width="232"
          height="208"
          viewBox="0 0 232 208"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.27397 24.3508C-1.01401 20.2847 -0.374719 12.1421 7.11845 9.83522C21.3773 5.45085 36 2.36603 50.8094 0.618116C142.132 30.2919 207.799 100.563 231.651 193.977C232.301 196.8 230.142 198.791 228.214 197.232C221.259 191.51 213.927 186.266 206.269 181.537C205.631 181.104 204.904 180.822 204.142 180.709C203.381 180.597 202.604 180.658 201.869 180.887C201.133 181.117 200.459 181.509 199.895 182.035C199.33 182.561 198.891 183.208 198.608 183.928C195.165 191.7 191.194 199.225 186.724 206.449C185.561 208.377 182.846 207.692 182.322 205.396C162.536 129.71 110.377 73.8493 36.3994 49.3687C26.8312 39.9198 16.3303 31.9878 5.27397 24.3508Z"
            fill="#0373BA"
            fillOpacity="0.5"
          />
        </svg>
      </div>
      <div className="curveArrow bottomRight">
        <svg
          width="207"
          height="233"
          viewBox="0 0 207 233"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M182.521 4.69997C186.566 -1.62034 194.667 -0.977776 196.962 6.55392C201.324 20.8861 204.393 35.584 206.132 50.4695C176.61 142.251 106.698 208.266 13.7622 232.241C10.9535 232.894 8.97284 230.724 10.5239 228.786C16.2167 221.795 21.4336 214.426 26.139 206.728C26.569 206.087 26.8503 205.356 26.9621 204.591C27.074 203.825 27.0135 203.044 26.7853 202.305C26.557 201.566 26.1667 200.888 25.6431 200.321C25.1195 199.754 24.4761 199.312 23.7601 199.028C16.0271 195.561 8.54068 191.562 1.35391 187.062C-0.563922 185.892 0.117275 183.164 2.40191 182.637C77.7004 162.75 133.276 110.323 157.631 35.9644C167.032 26.3575 174.923 15.8026 182.521 4.69997Z"
            fill="#FCB03E"
            fillOpacity="0.5"
          />
        </svg>
      </div>
      <div className="curveArrow bottomLeft">
        <svg
          width="232"
          height="208"
          viewBox="0 0 232 208"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M226.769 183.971C233.057 188.037 232.418 196.179 224.924 198.486C210.666 202.871 196.043 205.955 181.234 207.703C89.9217 178.072 24.2438 107.758 0.391377 14.3549C-0.258381 11.5319 1.90049 9.54099 3.8288 11.1C10.7842 16.8221 18.1158 22.0658 25.7738 26.7954C26.4123 27.2276 27.1391 27.5103 27.9006 27.6227C28.6622 27.7352 29.4392 27.6744 30.1743 27.445C30.9094 27.2155 31.584 26.8232 32.1482 26.2969C32.7125 25.7707 33.1521 25.1239 33.4347 24.4042C36.8827 16.6171 40.8605 9.07809 45.3399 1.84076C46.5032 -0.0869314 49.2175 0.597761 49.7415 2.89415C69.4962 78.5902 121.665 134.451 195.643 158.932C205.222 168.412 215.723 176.344 226.769 183.971Z"
            fill="#156C3C"
            fillOpacity="0.5"
          />
        </svg>
      </div>
    </>
  );
};
