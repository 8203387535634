import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Loader from "../Tools/Loader";
import { useEffect, useState } from "react";
import { catchError } from "../../helper/useError";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { toastAtom } from "../../state/atoms/toastAtom";
import { authAtom } from "../../state/atoms/authAtom";

export const ProductTable = () => {
  const auth = useRecoilValue(authAtom);

  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [testSeries, setTestSeries] = useState([]);
  const [, setShowToast] = useRecoilState(toastAtom);

  const getInfo = async () => {
    await publicAxios
      .get("/allproducts", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCourses(
            res.data.courses.map((course, index) => ({
              ...course,
              rowNumber: index + 1,
            }))
          );
          setTestSeries(
            res.data.test_series.map((series, index) => ({
              ...series,
              rowNumber: index + 1,
            }))
          );
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          const err = catchError(e);
          setShowToast({
            status: true,
            type: "error",
            errorMessage: err,
          });
          setLoading(false);
        }
        console.info("error", e);
        setLoading(false);
      });
  };
  useEffect(() => {
    getInfo();
  }, []);

  const courseColumns = [
    { field: "rowNumber", headerName: "No.", width: 70 }, // Add row number column
    { field: "title", headerName: "Title", width: 180 },
    { field: "price", headerName: "Price", width: 100 },
    { field: "start_date", headerName: "Start Date", width: 150 },
    { field: "productCode", headerName: "Product Code", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "action", headerName: "Action", width: 150 },
  ];

  const testSeriesColumns = [
    { field: "rowNumber", headerName: "#", width: 70 }, // Add row number column
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 180 },
    { field: "duration", headerName: "Duration", width: 100 },
    { field: "startDate", headerName: "Start Date", width: 150 },
  ];

  return (
    <Box sx={{ height: "max-content", width: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <DataGrid
            rows={courses}
            columns={courseColumns.map((column) => ({
              ...column,
              renderCell: (params) =>
                params.field === "rowNumber"
                  ? params.value
                  : params.value
                  ? params.value
                  : "NA",
            }))}
            getRowId={(row) => row.id}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            disableSelectionOnClick
          />
          {testSeries.length > 0 && (
            <DataGrid
              rows={testSeries}
              columns={testSeriesColumns.map((column) => ({
                ...column,
                renderCell: (params) =>
                  params.field === "rowNumber"
                    ? params.value
                    : params.value
                    ? params.value
                    : "NA",
              }))}
              getRowId={(row) => row.id}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              disableSelectionOnClick
            />
          )}
        </div>
      )}
    </Box>
  );
};
