import React from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import {
  Stack,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../User.scss";
import { useState } from "react";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { QueryTable } from "../../../components/AdminQuery/QueryTable";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useRecoilState } from "recoil";
import { toastAtom } from "../../../state/atoms/toastAtom";

export const QueryMgmt = () => {
  const [, setShowToast] = useRecoilState(toastAtom);
  const [formType, setFormType] = useState("contact_us");
  const [formTypeFilter, setFormTypeFilter] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleChange = (e) => {
    const { value } = e.target;
    console.log(value);
    setFormType(value);
  };
  console.log("asd");

  const resetFilter = () => {
    setEndDate("");
    setFormType("contact_us");
    setStartDate("");
    setFormTypeFilter("contact_us");
    setShowToast({
      status: true,
      type: "success",
      errorMessage: "Filter reset!",
    });
  };

  const handleChanges = () => {
    if (!startDate || !endDate || !formType) {
      setShowToast({
        status: true,
        type: "error",
        errorMessage:
          "Select start date, end date and request time is required ",
      });
    } else {
      setFormTypeFilter(
        `${formType}&from_date=${startDate}&end_date=${endDate}`
      );
    }
  };
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash">
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack>
            <div className="all-user-strip">Form Submission</div>
            <Stack gap={2} style={{ background: "white", padding: "11px" }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          style={{ marginLeft: "-90px", marginTop: "5px" }}
                        />
                      )}
                    />
                    <ArrowForwardIcon style={{ marginLeft: "10px" }} />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          style={{ marginLeft: "25px", marginTop: "5px" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Select
                    name="formType"
                    id="select-edu"
                    value={formType}
                    onChange={handleChange}
                    style={{ marginLeft: "13px", marginTop: "5px" }}
                    size="small"
                  >
                    <MenuItem id={1} value="contact_us">
                      Contact Us
                    </MenuItem>
                    <MenuItem id={2} value="request_callback">
                      Request Callback
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid itme xs={2}>
                  <Button
                    variant="contained"
                    style={{ marginTop: "25px" }}
                    onClick={handleChanges}
                  >
                    Apply Changes
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginTop: "25px" }}
                    onClick={resetFilter}
                  >
                    Reset Filter
                  </Button>
                </Grid>
              </Grid>
            </Stack>
            <div className="userTable">
              <QueryTable formType={formType} />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
