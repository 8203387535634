import React from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { Stack } from "@mui/material";
import "../User.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { StudentTable } from "../../../components/AdminStudent/StudentTable";

export const StudentMgmt = () => {
  return (
    <div style={{ padding: "1rem" }}>
      <StudentTable />
    </div>
  );
};
