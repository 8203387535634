import React from "react";
import { useState, useEffect } from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { Stack } from "@mui/material";
import "../User.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { StudentDetails } from "../../../components/AdminStudent/StudentDetails";
import publicAxios from "../../../axios/publicAxios";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

export const StudentData = () => {
  const auth = useRecoilValue(authAtom);
  const [data, setData] = useState();
  const [subsDetails, setSubsDetails] = useState();
  const [loading, setLoading] = useState(true);
  const { studentId } = useParams();
  const getInfo = async () => {
    await publicAxios
      .get("v2/account/" + studentId, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  const getSubsDetails = async () => {
    await publicAxios
      .get("account/subscription/" + studentId, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSubsDetails(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  useEffect(() => {
    getInfo();
    getSubsDetails();
  }, []);
  return (
    <div
      style={{
        width: "90%",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash">
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack>
            <div className="all-user-strip">Student Details</div>
            <div className="UserPaper">
              {data && <StudentDetails data={data} subsDetails={subsDetails} getSubsDetails={getSubsDetails} />}
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
