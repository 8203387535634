import React from "react";
import { Container } from "@mui/material";

import PageHero from "../../components/PageHero/PageHero";
import PageNavbar from "../../components/PageNavbar/PageNavbar";
import VacanciesCard from "../../components/Blog/VacanciesCard/VacanciesCard";

import pageNavbar from "../../helper/DummyData/pageNavbar";
import pageHero from "../../helper/DummyData/pageHero";

import TermsConditionCard from "../../components/Privacy/TermsConditionCard";

// import "./Blog.scss";

export const TermsCondition = () => {
  return (
    <Container>
      <div>
        <TermsConditionCard/>
      </div>
    </Container>
  );
};
