import React, { useEffect, useState } from "react";
import "./Payment.scss";
import {
  Stack,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { AnalyticsTable } from "../../../components/AdminPayment/AnalyticsTable";
import DownloadIcon from "@mui/icons-material/Download";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import privateAxios from "../../../axios/privateAxios";
import { CSVLink } from "react-csv";
import publicAxios from "../../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

export const PaymentAnalyticsMgmt = () => {
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().subtract(3, "months"),
    end_date: dayjs(),
  });

  const [csvData, setCsvData] = useState([]);

  const [product, setProduct] = useState([]);

  const [salesFigure, setSalesFigure] = useState({
    coupon_amount: 0,
    gross_sales: 0,
    net_sales: 0,
    total_tax: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState("PAID");
  const [selectedProduct, setSelectedProduct] = useState(0);

  const [data, setData] = useState([]);

  const auth = useRecoilValue(authAtom);

  const getSalesAmount = () => {
    const { start_date, end_date } = dateRange;

    privateAxios
      .get("get/sales/amount", {
        params: {
          ...(selectedProduct !== 0 ? { course_id: selectedProduct } : {}),
          status: selectedStatus,
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        setData(res.data.orders);
        setSalesFigure({ ...salesFigure, ...res.data });
      });
  };

  const getInfo = async () => {
    await publicAxios
      .get("/allproducts", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setProduct(
            res.data.courses.map((course, index) => ({
              ...course,
              rowNumber: index + 1,
            }))
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getSalesAmount();
  }, [selectedProduct, dateRange, selectedStatus]);

  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
        overflowY: "scroll",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash">
        {/* <AdminNavbar /> */}

        <div className="main-user-dash">
          <Stack sx={{ width: "90%" }}>
            <Stack
              sx={{
                background: "white",
                textAlign: "left",
                padding: "20px",
                marginTop: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "600" }}>
                Payment Analytics
              </Typography>
            </Stack>
            <div className="all-user-strp">
              <Stack direction="row" alignItems="center" sx={{ gap: "1rem" }}>
                <Grid
                  container
                  padding={0}
                  marginTop={3}
                  direction={"column"}
                  sx={{ width: "40%" }}
                >
                  <Typography style={{ textAlign: "left" }}>
                    Date Range :
                  </Typography>
                  <Grid item xs={3} sx={{ width: "fit-content" }} padding={2}>
                    <Stack
                      direction={"row"}
                      sx={{ gap: "1rem", alignItems: "center" }}
                      color={"#0373BA"}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={dateRange.start_date}
                          onChange={(newValue) =>
                            setDateRange({ ...dateRange, start_date: newValue })
                          }
                        />
                        &#45;
                        <DatePicker
                          label="End Date"
                          value={dateRange.end_date}
                          onChange={(newValue) =>
                            setDateRange({ ...dateRange, end_date: newValue })
                          }
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  padding={0}
                  marginTop={3}
                  direction={"column"}
                  sx={{ width: "20%" }}
                >
                  <Typography style={{ textAlign: "left" }}>
                    Order Status :
                  </Typography>
                  <Grid item xs={3} sx={{ width: "100%" }} padding={2}>
                    <Stack
                      direction={"row"}
                      sx={{ gap: "1rem", alignItems: "center" }}
                      color={"#0373BA"}
                    >
                      <Select
                        sx={{ width: "100%" }}
                        value={selectedStatus}
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                        }}
                      >
                        <MenuItem value="PAID">Paid</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="FAILED">Failed</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid
                  container
                  padding={0}
                  marginTop={3}
                  direction={"column"}
                  sx={{ width: "20%" }}
                >
                  <Typography style={{ textAlign: "left" }}>
                    Product :
                  </Typography>
                  <Grid item xs={3} sx={{ width: "100%" }} padding={2}>
                    <Stack
                      direction={"row"}
                      sx={{ gap: "1rem", alignItems: "center" }}
                      color={"#0373BA"}
                    >
                      <Select
                        sx={{ width: "100%" }}
                        value={selectedProduct}
                        onChange={(e) => {
                          setSelectedProduct(e.target.value);
                        }}
                      >
                        <MenuItem value={0}>All Product</MenuItem>
                        {product.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>

              <Grid container padding={0} marginTop={3}>
                <Grid
                  item
                  xs={3}
                  border={"1px solid #DDDDDD"}
                  sx={{ backgroundColor: "#fff" }}
                  padding={2}
                >
                  <Stack direction={"row"}>Gross Sales</Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    paddingY={2}
                  >
                    <Typography>{salesFigure.gross_sales ?? 0}</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  border={"1px solid #DDDDDD"}
                  sx={{ backgroundColor: "#fff" }}
                  padding={2}
                >
                  <Stack direction={"row"}>Coupons</Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    paddingY={2}
                  >
                    <Typography>{salesFigure.coupon_amount}</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  border={"1px solid #DDDDDD"}
                  sx={{ backgroundColor: "#fff" }}
                  padding={2}
                >
                  <Stack direction={"row"}>Net Sales</Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    paddingY={2}
                  >
                    <Typography>{salesFigure.net_sales}</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  border={"1px solid #DDDDDD"}
                  sx={{ backgroundColor: "#fff" }}
                  padding={2}
                >
                  <Stack direction={"row"}>Gst</Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    paddingY={2}
                  >
                    <Typography>{salesFigure.total_tax}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <div>
              {/* <span>Transaction Details</span> */}
              <Grid container spacing={2} padding={"10px"}>
                <Grid item xs={6}>
                  <Typography>Transaction Details</Typography>
                </Grid>
                <Grid item xs={6}>
                  <CSVLink data={csvData}>
                    <Button variant="text" startIcon={<DownloadIcon />}>
                      Download
                    </Button>
                  </CSVLink>
                </Grid>
              </Grid>
              <AnalyticsTable
                setCsvData={setCsvData}
                dateRange={dateRange}
                selectedStatus={selectedStatus}
                selectedProduct={selectedProduct}
                data={data}
              />
              <Grid container spacing={2} padding={"10px"}>
                <Grid
                  item
                  xs={10}
                  direction={"row"}
                  sx={{ display: "flex" }}
                  padding={"7px"}
                  gap={"12px"}
                >
                  <Typography fontSize={"13px"}>124 Days</Typography>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontSize={"13px"}>22 Orders</Typography>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
