import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";
import Loader from "../../components/Tools/Loader";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const QueryTable = ({ formType }) => {
  const auth = useRecoilValue(authAtom);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [indData, setIndData] = useState({});
  const getInfo = async () => {
    await publicAxios
      .get(formType, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          res.data.map((ele, i) => (ele.srNo = i + 1));
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  useEffect(() => {
    getInfo();
  }, [formType]);
  const columns = [
    { field: "srNo", headerName: "No.", width: 50 },
    { field: "name", headerName: "Name", width: 120 },
    {
      field: "phone",
      headerName: "Contact No",
      width: 170,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 150,
    },
    {
      field: "submission_date",
      headerName: "Submission Date",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return new Date(currentRow.submission_date).toLocaleDateString("in");
      },
    },
    {
      field: "message",
      headerName: "Message",
      width: 170,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                const filterData = data.filter(
                  (ele) => ele.id === currentRow.id
                );
                console.log(filterData);
                setIndData(filterData[0]);
                handleModal();
              }}
            >
              Open
            </Button>
          </Stack>
        );
      },
    },
  ];
  // Otherwise filter will be applied on fields such as the hidden column id

  const [openName, setOpenName] = useState(false);
  const handleModal = () => {
    setOpenName(!openName);
  };
  return (
    <>
      {loading && <Loader />}
      {data && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            checkboxSelection
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      )}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openName}
        sx={{
          maxHeight: "1000px",
        }}
      >
        <DialogTitle>
          <Stack direction="column" justifyContent="space-between">
            <Typography variant="h6">Details</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: "15px" }}>
            <Grid item xs={6} direction="column">
              <Typography>Name</Typography>
              <TextField
                placeholder="FirstName Last Name"
                inputProps={{ style: { height: "15px" } }}
                fullWidth
                value={indData?.name}
              ></TextField>
            </Grid>
            <Grid item xs={6} direction="column">
              <Typography>Contact No.</Typography>
              <TextField
                placeholder="+921121212"
                inputProps={{ style: { height: "15px" } }}
                fullWidth
                value={indData?.phone}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: "15px" }}>
            {/* <Grid item xs={6} direction="column">
              <Typography >Alternate Contact No.</Typography>
              <TextField placeholder="+921121212" inputProps={{ style: { height: "15px" } }} fullWidth></TextField>
            </Grid> */}
            <Grid item xs={6} direction="column">
              <Typography>Email</Typography>
              <TextField
                placeholder="user@gmail.com"
                inputProps={{ style: { height: "15px" } }}
                fullWidth
                value={indData?.email}
              ></TextField>
            </Grid>
          </Grid>
          {indData?.message && (
            <Grid container spacing={2}>
              <Grid item xs={12} direction="column" md={12}>
                <Typography>Message</Typography>
                <TextField
                  placeholder="Message..."
                  fullWidth
                  multiline
                  rows={5}
                  value={indData?.message}
                ></TextField>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
