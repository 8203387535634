import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Radio,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useEffect, useState, useRef } from "react";
import Divider from "../Tools/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import publicAxios from "../../axios/publicAxios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { toastAtom } from "../../state/atoms/toastAtom";

let sectionId = "";
export const Tests = (props) => {
  const [, setShowToast] = useRecoilState(toastAtom);

  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [openMCQ, setOpenMCQ] = useState(false);
  const [openIQ, setOpenIQ] = useState(false);
  const [openCO, setOpenCO] = useState(false);
  const [openTF, setOpenTF] = useState(false);
  const [openSection, setOpenSection] = useState(false);
  const [data, setData] = useState();
  const [testset, setTestset] = useState();
  const [fetchQuestion, setFetchQuestion] = useState();
  const [loading, setLoading] = useState();
  const [displayQuestion, setDisplayQuestion] = useState([]);
  const [shouldRunCode, setShouldRunCode] = useState(false);
  const [uploadedQuestion, setUploadedQuestion] = useState([]);
  const [testSeriesName, setTestSeriesName] = useState();
  const [testSetName, setTestSetName] = useState(["Section 1"]);
  const [grade, setGrade] = useState({
    mcqNegativeGrade: "0",
    mcqPositiveGrade: "4",
    booleanNegativeGrade: "0",
    booleanPositiveGrade: "4",
    comprehensionNegativeGrade: "0",
    comprehensionPositiveGrade: "4",
    integerNegativeGrade: "0",
    integerPositiveGrade: "4",
  });
  const [sectionFormData, setSectionFormData] = useState({
    id: "",
    name: "",
    allow_switch: false,
    instructions: "",
  });
  const [integerQuestionFormData, setIntegerQuestionFormData] = useState({
    id: "",
    integer_question: "",
    integer_solution: "",
    integer_answer: "",
    integerPositiveMarks: grade.integerPositiveGrade,
    integerNegativeMarks: grade.integerNegativeGrade,
    integerNegFlag: grade.integerNegativeGrade !== "0" ? true : false,
  });
  const [trueFalseData, setTrueFalseData] = useState({
    id: "",
    question: "",
    booleanAnswer: true,
    solution: "",
    booleanPositiveMarks: grade.booleanPositiveGrade,
    booleanNegativeMarks: grade.booleanNegativeGrade,
    booleanNegFlag: grade.booleanNegativeGrade !== "0" ? true : false,
  });
  const mcqStateData = {
    id: "",
    question: "",
    solution: "",
    mcqPositiveMarks: grade.mcqPositiveGrade,
    mcqNegativeMarks: grade.mcqNegativeGrade,
    mcqNegFlag: grade.mcqNegativeGrade !== "0" ? true : false,
    options: [
      {
        name: "",
        correct_status: false,
      },
      {
        name: "",
        correct_status: false,
      },
      {
        name: "",
        correct_status: false,
      },
      {
        name: "",
        correct_status: false,
      },
    ],
  };
  const [multipleQuestionData, setMultiplQuestionData] = useState(mcqStateData);
  const [comprehensionData, setComprehensionData] = useState({
    id: "",
    questionComprehension: "",
    paragraph: "",
    comprehensionPositiveMarks: grade.comprehensionPositiveGrade,
    comprehensionNegativeMarks: grade.comprehensionNegativeGrade,
    comprehensionNegFlag:
      grade.comprehensionNegativeGrade !== "0" ? true : false,
  });
  const [testSet, setTestSet] = useState({
    id: "",
    name: "",
    description: "",
    duration: "",
    sections: [],
  });
  const [expanded, setExpanded] = useState(null);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    console.log(isExpanded, panel);
    sectionId = isExpanded ? panel.replace("panel", "") : "";
    setExpanded(isExpanded ? panel : null);
  };
  // MCQ Template Options
  const handleDeleteOption = (index) => {
    const updatedQuestionData = { ...multipleQuestionData }; // Create a copy of the state
    updatedQuestionData.options.splice(index, 1);
    setMultiplQuestionData(updatedQuestionData);
  };

  const handleAddedOption = () => {
    const updatedQuestionData = { ...multipleQuestionData }; // Create a copy of the state
    updatedQuestionData.options.push({ name: "", correct_status: false });
    setMultiplQuestionData(updatedQuestionData);
  };

  console.log("ROW DATA: ", props.Rowdata.testset);
  const handleClickOpen = (e) => {
    sectionId = e.target.id;
    setOpen(true);
  };
  const handleOpenMCQ = () => {
    setMultiplQuestionData(mcqStateData);
    setOpen(false);
    setOpenMCQ(true);
  };
  const handleOpenIQ = () => {
    openIQ === false &&
      setIntegerQuestionFormData({
        id: "",
        integer_question: "",
        integer_solution: "",
        integer_answer: "",
        integerPositiveMarks: grade.integerPositiveGrade,
        integerNegativeMarks: grade.integerNegativeGrade,
        integerNegFlag: grade.integerNegativeGrade !== "0" ? true : false,
      });
    setOpenIQ(!openIQ);
  };
  const handleOpenCO = () => {
    setComprehensionData({
      id: "",
      questionComprehension: "",
      paragraph: "",
      comprehensionPositiveMarks: grade.comprehensionPositiveGrade,
      comprehensionNegativeMarks: grade.comprehensionNegativeGrade,
      comprehensionNegFlag:
        grade.comprehensionNegativeGrade !== "0" ? true : false,
    });
    setOpen(false);
    setOpenCO(true);
  };
  const [openPopUp, setOpenPopUp] = useState(false);
  const handleOpenTF = () => {
    setTrueFalseData({
      id: "",
      question: "",
      booleanAnswer: true,
      solution: "",
      booleanPositiveMarks: grade.booleanPositiveGrade,
      booleanNegativeMarks: grade.booleanNegativeGrade,
      booleanNegFlag: grade.booleanNegativeGrade !== "0" ? true : false,
    });
    setOpen(false);
    setOpenTF(true);
  };
  const handleSection = () => {
    if (!openSection) {
      setSectionFormData({
        id: "",
        name: "",
        allow_switch: false,
        instructions: "",
      });
    }
    setOpenSection(!openSection);
  };
  const handleEditSection = (id) => {
    const filterSectionData = testSet.sections.filter((ele) => ele.id === id);
    console.log(filterSectionData);
    setSectionFormData({
      id: id,
      name: filterSectionData[0].name,
      allow_switch: filterSectionData[0].allow_switch,
      instructions: filterSectionData[0].instructions,
    });
    setOpenSection(!openSection);
  };
  const handleDeleteSection = (id) => {
    publicAxios
      .delete(`v2/testsection/${id}`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        getTestDetails();
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  const handleQuestionDelete = (id) => {
    publicAxios
      .delete(`v2/question/${id}`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        getTestDetails();
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  const handleOpenPopUp = () => {
    setOpenPopUp(true);
    setTimeout(() => {
      setOpenPopUp(false);
    }, 1000);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const handleBack = () => {
    navigate(0);
  };
  const handleCloseCQ = () => {
    setOpenMCQ(false);
  };
  // const handleCloseIQ = () => {
  //   setOpenIQ(false);
  // };
  const handleCloseCO = () => {
    setOpenCO(false);
  };
  const handleCloseTF = () => {
    setOpenTF(false);
  };
  // const handleMCQSave = () => {
  //   setLoading(true);

  //   const request_body = {
  //     type: "MCQ",
  //     marks: "4",
  //     max_time: "30",
  //     difficulty: "medium",
  //     question: question,
  //     solution: solution,
  //   };
  //   publicAxios
  //     .post(`http://65.0.164.61/createquestion`, request_body)
  //     .then((res) => {
  //       console.log("POSTED QUESTION: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //     setUploadedQuestion(uploadedQuestion => [...uploadedQuestion, question])
  //   const option1_body = {
  //     option: option1,
  //     correct_status: false,
  //     marks: 4 ,
  //     question: question
  //   }
  //   const option2_body = {
  //     option: option2,
  //     correct_status: false,
  //     marks: 4 ,
  //     question: question
  //   }
  //   const option3_body = {
  //     option: option3,
  //     correct_status: true,
  //     marks: 4 ,
  //     question: question
  //   }
  //   const option4_body = {
  //     option: option4,
  //     correct_status: false,
  //     marks: 4,
  //     question: question
  //   }

  //   publicAxios
  //     .post(`http://65.0.164.61/createoption`, option1_body)
  //     .then((res) => {
  //       console.log("POSTED Option: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //   publicAxios
  //     .post(`http://65.0.164.61/createoption`, option2_body)
  //     .then((res) => {
  //       console.log("POSTED Option: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //   publicAxios
  //     .post(`http://65.0.164.61/createoption`, option3_body)
  //     .then((res) => {
  //       console.log("POSTED Option: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //   publicAxios
  //     .post(`http://65.0.164.61/createoption`, option4_body)
  //     .then((res) => {
  //       console.log("POSTED Option: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //   handleClose();
  //   setLoading(false);
  // };

  // const handleTFSave = () => {

  //   const request_body = {
  //     type: "tf",
  //     marks: "2",
  //     max_time: "15",
  //     difficulty: "easy",
  //     question: question,
  //     solution: solution,
  //   };
  //   publicAxios
  //     .post(`http://65.0.164.61/createquestion`, request_body)
  //     .then((res) => {
  //       console.log("POSTED QUESTION: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //     setUploadedQuestion(uploadedQuestion => [...uploadedQuestion, question])
  //   const option_body1 = {
  //   option: "False",
  //   "correct_status" : true,
  //   "marks": 2,
  //   question: question
  // }
  //   const option_body2 = {
  //   option: "True",
  //   "correct_status" : false,
  //   "marks": 2,
  //   "question": question
  // }
  //   publicAxios
  //     .post(`http://65.0.164.61/createoption`, option_body1)
  //     .then((res) => {
  //       console.log("POSTED Option: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //   publicAxios
  //     .post(`http://65.0.164.61/createoption`, option_body2)
  //     .then((res) => {
  //       console.log("POSTED Option: ", res);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });

  // };

  const getInfo = async () => {
    setLoading(true);
    await publicAxios
      // .get("http://65.0.164.61/testseries")
      .get("http://65.0.164.61/alltest", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log("Pokemon: ", res.data);
        if (res.status === 200) {
          setData(res.data);
          // setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          // setLoading(false);
        }
        console.info("error", e);
      });
    await publicAxios
      .get("http://65.0.164.61/alltestsets", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((set) => {
        console.log("Test Set: ", set.data);
        if (set.status === 200) {
          setTestset(set.data);
          // setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setTestset(true);
          // setLoading(false);
        }
        console.info("error", e);
      });
    await publicAxios
      .get("http://65.0.164.61/allquestions", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((quest) => {
        console.log("Fetch Questions : ", quest.data);
        if (quest.status === 200) {
          setFetchQuestion(quest.data);
          // setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setFetchQuestion(true);
          // setLoading(false);
        }
        console.info("error", e);
      });
    console.log("DISPLAY INFO: ", displayQuestion);
    for (let index = 0; index < testset?.length; index++) {
      const element = testset[index];
      // console.log("TEST SETs: ", element);
      for (let j = 0; j < props.Rowdata.testset?.length; j++) {
        const pokemon = props.Rowdata.testset[j];
        if (element?.id === pokemon) {
          console.log("KEY: ", element);
          for (let k = 0; k < element.questions?.length; k++) {
            const qn = element.questions[k];
            for (let qu = 0; qu < fetchQuestion?.length; qu++) {
              const questionTitle = fetchQuestion[qu];
              if (qn === questionTitle.id) {
                console.log("THE QUESTION: ", questionTitle.question);
                setDisplayQuestion((displayQuestion) => [
                  ...displayQuestion,
                  questionTitle.question,
                ]);
              }
            }
            console.log("QUESTIONS: ", qn);
          }
        }
      }
    }
  };

  const handleSubmit = async () => {
    const testset_body = {
      name: testSetName,
      language: "English",
      description: "Description",
      access_type: "free",
      duration: "60",
      type: "chapter",
      testset_category: ["UPSC"],
      order: 1,
      publish: true,
      questions: uploadedQuestion,
    };

    await publicAxios
      .post("http://65.0.164.61/createtestset", testset_body, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        console.log("CREATED TEST SET: ", response);
        // setTestSetName(testSetName => [...testSetName, testset]);
        // console.log("publishTestSet: ", publishTestSet);
      })
      .catch((error) => {
        console.info(error);
      });

    const req_body = {
      title: testSeriesName,
      about: "About Test Series",
      language: "English",
      price: 200,
      mrp: 2200,
      validity: "2023-12-31",
      publish: true,
      feature: [
        "Current Affairs, Supplementary Material & Reference Material will be provided in Soft Copies.",
        "Expert Support Via Phone and Email Discussion",
      ],
      feature2: ["Expert Feedback & Assessment"],
      category: ["General Awarness"],
      faq: ["Can I change my batch after buying subscription?"],
      testset: testSetName,
    };
    publicAxios
      .post("http://65.0.164.61/createtestseries", req_body, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
    navigate(0);
  };

  const handleSubmitTestSet = () => {
    const req_body = {
      publish: true,
    };
    publicAxios
      .post(`v2/testset/${props.Rowdata.id}`, req_body, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        handleBack();
      })
      .catch(() => {});
  };
  const handleSectionData = (e) => {
    console.log(e.target);
    const { name, checked, value } = e.target;
    console.log(name, checked, value);
    setSectionFormData({
      ...sectionFormData,
      [name]: name === "allow_switch" ? checked : value,
    });
  };
  const handleIntgerQuestion = (e) => {
    const { name, value, checked } = e.target;
    setIntegerQuestionFormData({
      ...integerQuestionFormData,
      [name]: name === "integerNegFlag" ? checked : value,
    });
  };
  const addSection = () => {
    const req_body = {
      test_set_id: props.Rowdata.id,
      name: sectionFormData.name,
      allow_switch: sectionFormData.allow_switch,
      instructions: sectionFormData.instructions,
    };
    publicAxios
      .post(
        `v2/testsection${sectionFormData.id ? "/" + sectionFormData.id : ""}`,
        req_body,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((res) => {
        if (sectionFormData.id) {
          const sectionsData = [...testSet.sections];
          sectionsData.forEach((ele) => {
            if (ele.id === sectionFormData.id) {
              ele.name = res.data.name;
              ele.instructions = res.data.instructions;
              ele.allow_switch = res.data.allow_switch;
            }
            setTestSet((pre) => ({
              ...pre,
              sections: [...sectionsData],
            }));
          });
        } else {
          const newRes = {
            ...res.data,
            questions: [],
          };
          setTestSet((pre) => ({
            ...pre,
            sections: [...pre.sections, newRes],
          }));
        }
        sectionId = res.data.id;
        handleOpenPopUp();
        handleSection();
        console.log("CREATE TEST SERIES: ", res);
        console.log();
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  // setTimeout(()=>{
  //   handleOpenPopUp();
  // }, 500)

  const addIntegerQuestion = () => {
    console.log(integerQuestionFormData);
    const req_body = {
      test_section_id: sectionId,
      type: "integer",
      marks: Number(integerQuestionFormData.integerPositiveMarks),
      max_time: 5,
      difficulty: "hard",
      question: integerQuestionFormData.integer_question,
      solution: integerQuestionFormData.integer_solution,
      order: 1,
      answer: Number(integerQuestionFormData.integer_answer),
      allow_neg: integerQuestionFormData.integerNegFlag,
      neg_marks: integerQuestionFormData.integerNegFlag
        ? Number(integerQuestionFormData.integerNegativeMarks)
        : "0",
    };
    if(!Number(integerQuestionFormData.integer_answer)) return setShowToast({
      status: true,
      type: "error",
      errorMessage: `Please provide an answer.`,
    });
    publicAxios
      .post(
        `v2/question${
          integerQuestionFormData.id ? "/" + integerQuestionFormData.id : ""
        }`,
        req_body,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((response) => {
        if (integerQuestionFormData.id) {
          getTestDetails();
          handleOpenIQ();
        } else {
          setIntegerQuestionFormData((prev) => ({
            ...prev,
            id: "",
            integer_question: "",
            integer_solution: "",
            integer_answer: "",
          }));
          const newQuestion = [
            ...testSet.sections.filter((item) => item.id == sectionId)[0]
              .questions,
            response.data,
          ];
          testSet.sections.forEach((obj) => {
            if (obj.id == sectionId) {
              obj.questions = newQuestion;
            }
          });
          setTestSet((prev) => ({
            ...prev,
            section: testSet.sections,
          }));
        }
        console.log(testSet);
        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  const hanldeComprehension = (e) => {
    const { name, value, checked } = e.target;
    setComprehensionData({
      ...comprehensionData,
      [name]: name === "comprehensionNegFlag" ? checked : value,
    });
  };
  // useEffect(() => {
  //   const checkboxes = document.querySelectorAll('input[name="checkValues"]');
  //   const updatedCheckValues = [];
  //   checkboxes.forEach((checkbox) => {
  //     const attributeValue = checkbox.getAttribute("data-indeterminate");
  //     const optionValue = checkbox.getAttribute("value");
  //     console.log(
  //       `checkBoxValue: ${optionValue}, Attribute value: ${attributeValue}`
  //     );

  //     updatedCheckValues.push({
  //       name: `${optionValue}`,
  //       correct_status:
  //         `${attributeValue}` === "false"
  //           ? false
  //           : Boolean(`${attributeValue}`),
  //     });
  //   });
  //   setMultiplQuestionData((prevData) => ({
  //     ...prevData,
  //     checkValues: updatedCheckValues,
  //   }));
  //   console.log(updatedCheckValues);
  // }, [addedOption]);
  // Handling MCQ Answers
  const handleMcqAnswer = (e) => {
    const updatedQuestionData = { ...multipleQuestionData }; // Create a copy of the state

    const { name, value, checked } = e.target;

    if (
      name === "question" ||
      name === "mcqNegativeMarks" ||
      name === "solution" ||
      name === "mcqPositiveMarks"
    ) {
      // For other fields in multipleQuestionData
      updatedQuestionData[name] = value;
    } else if (name === "mcqNegFlag") {
      updatedQuestionData[name] = checked;
    } else {
      // If the checkbox is in checkValues array
      const index = Number(name.slice(-1)) - 1;
      if (name.slice(0, -1) === "textField") {
        updatedQuestionData.options[index].name = value;
      } else {
        updatedQuestionData.options[index].correct_status = checked;
      }
    }

    setMultiplQuestionData(updatedQuestionData);
  };

  const handleMCQSave = () => {
    const req_body = {
      test_section_id: sectionId,
      type: "mcq",
      marks: Number(multipleQuestionData.mcqPositiveMarks),
      max_time: 5,
      difficulty: "hard",
      question: multipleQuestionData.question,
      solution: multipleQuestionData.solution,
      order: 1,
      options: multipleQuestionData.options,
      allow_neg: multipleQuestionData.mcqNegFlag,
      neg_marks: multipleQuestionData.mcqNegFlag
        ? Number(multipleQuestionData.mcqNegativeMarks)
        : "0",
    };
    if(!multipleQuestionData.solution) return setShowToast({
      status: true,
      type: "error",
      errorMessage: `Please provide an answer.`,
    });
    publicAxios
      .post(
        `v2/question${
          multipleQuestionData.id ? "/" + multipleQuestionData.id : ""
        }`,
        req_body,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((response) => {
        // handleSection()
        console.log("CREATE TEST SERIES:", response);
        console.log(testSet.sections);
        if (multipleQuestionData.id) {
          getTestDetails();
          handleCloseCQ();
        } else {
          setMultiplQuestionData(mcqStateData);
          const newQuestion = [
            ...testSet.sections.filter((item) => item.id == sectionId)[0]
              .questions,
            response.data,
          ];
          testSet.sections.map((obj) => {
            console.log(obj.id, sectionId);
            if (obj.id == sectionId) {
              obj.questions = newQuestion;
            }
          });
          setTestSet((prev) => ({
            ...prev,
            section: testSet.sections,
          }));
        }
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  const nextButton = (e) => {
    console.log(multipleQuestionData);
  };

  const previousButton = (e) => {
    console.log(multipleQuestionData);
  };

  const handleTrueFalseData = (e) => {
    const { name, value, checked } = e.target;
    if (name === "booleanNegFlag") {
      setTrueFalseData({
        ...trueFalseData,
        [name]: checked,
      });
    } else {
      setTrueFalseData({
        ...trueFalseData,
        [name]: name === "booleanAnswer" ? value === "true" : value,
      });
    }
  };
  const handleTFSave = () => {
    const req_body = {
      test_section_id: sectionId,
      type: "boolean",
      marks: Number(trueFalseData.booleanPositiveMarks),
      max_time: 5,
      difficulty: "hard",
      question: trueFalseData.question,
      solution: trueFalseData.solution,
      order: 1,
      answer: trueFalseData.booleanAnswer,
      allow_neg: trueFalseData.booleanNegFlag,
      neg_marks: trueFalseData.booleanNegFlag
        ? Number(trueFalseData.booleanNegativeMarks)
        : "0",
    };

    if(!trueFalseData.booleanAnswer) return setShowToast({
      status: true,
      type: "error",
      errorMessage: `Please provide an answer.`,
    });
    publicAxios
      .post(
        `v2/question${trueFalseData.id ? "/" + trueFalseData.id : ""}`,
        req_body,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((response) => {
        if (trueFalseData.id) {
          getTestDetails();
          handleCloseTF();
        } else {
          setTrueFalseData((prev) => ({
            ...prev,
            id: "",
            question: "",
            booleanAnswer: true,
            solution: "",
          }));
          const newQuestion = [
            ...testSet.sections.filter((item) => item.id == sectionId)[0]
              .questions,
            response.data,
          ];
          testSet.sections.map((obj) => {
            if (obj.id == sectionId) {
              obj.questions = newQuestion;
            }
          });
          setTestSet((prev) => ({
            ...prev,
            section: testSet.sections,
          }));
        }
        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
  };

  const handleGrade = (e) => {
    const { name, value } = e.target;
    setGrade((prev) => ({
      ...prev,
      [name]: [value],
    }));
  };

  const addComprehension = () => {
    const req_body = {
      test_section_id: sectionId,
      type: "comprehension",
      marks: Number(comprehensionData.comprehensionPositiveMarks),
      max_time: 5,
      difficulty: "hard",
      question: comprehensionData.questionComprehension,
      solution: comprehensionData.paragraph,
      order: 1,
      allow_neg: comprehensionData.comprehensionNegFlag,
      neg_marks: comprehensionData.comprehensionNegFlag
        ? Number(comprehensionData.comprehensionNegativeMarks)
        : "0",
    };
    if(!comprehensionData.paragraph) return setShowToast({
      status: true,
      type: "error",
      errorMessage: `Please provide an answer.`,
    });
    publicAxios
      .post(
        `v2/question${comprehensionData.id ? "/" + comprehensionData.id : ""}`,
        req_body,
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((response) => {
        if (comprehensionData.id) {
          getTestDetails();
          handleCloseCO();
        } else {
          setComprehensionData((prev) => ({
            ...prev,
            id: "",
            questionComprehension: "",
            paragraph: "",
          }));
          const newQuestion = [
            ...testSet.sections.filter((item) => item.id == sectionId)[0]
              .questions,
            response.data,
          ];
          testSet.sections.forEach((obj) => {
            if (obj.id == sectionId) {
              obj.questions = newQuestion;
            }
          });
          setTestSet((prev) => ({
            ...prev,
            section: testSet.sections,
          }));
        }

        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
  };

  const handleQuestionEdit = (e) => {
    const questionId = e.currentTarget.getAttribute("questionId");
    publicAxios
      .get(`/v2/question/${questionId}`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        // handleSection()
        if (response.data.type === "mcq") {
          handleOpenMCQ();
          setMultiplQuestionData({
            id: response.data.id,
            question: response.data.question,
            solution: response.data.solution,
            mcqPositiveMarks: response.data.marks,
            mcqNegativeMarks: response.data.neg_marks,
            mcqNegFlag: response.data.allow_neg,
            options: response.data.options,
          });
        } else if (response.data.type === "comprehension") {
          handleOpenCO();
          setComprehensionData({
            id: response.data.id,
            questionComprehension: response.data.question,
            paragraph: response.data.solution,
            comprehensionPositiveMarks: response.data.marks,
            comprehensionNegativeMarks: response.data.neg_marks,
            comprehensionNegFlag: response.data.allow_neg,
          });
        } else if (response.data.type === "integer") {
          handleOpenIQ();
          setIntegerQuestionFormData({
            id: response.data.id,
            integer_question: response.data.question,
            integer_solution: response.data.solution,
            integer_answer: response.data.answer,
            integerPositiveMarks: response.data.marks,
            integerNegativeMarks: response.data.neg_marks,
            integerNegFlag: response.data.allow_neg,
          });
        } else {
          handleOpenTF();
          setTrueFalseData({
            id: response.data.id,
            question: response.data.question,
            booleanAnswer: response.data.answer,
            solution: response.data.solution,
            booleanPositiveMarks: response.data.marks,
            booleanNegativeMarks: response.data.neg_marks,
            booleanNegFlag: response.data.allow_neg,
          });
        }
        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
  };

  const getTestDetails = () => {
    publicAxios
      .get(`/v2/testset/${props.Rowdata.id}/all`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        // handleSection()
        setTestSet(response.data);
        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  useEffect(() => {
    getTestDetails();
  }, []);
  useEffect(() => {
    console.log("UPLOADED QUESTIONS: ", uploadedQuestion);
    if (shouldRunCode) {
      // setLoading(true);

      getInfo();
    }
    // setLoading(false);
  }, [displayQuestion, shouldRunCode, uploadedQuestion]);

  return (
    <div
      style={{
        background: "#F5F5F5",
        width: "100%",
        height: "100%",
        fontFamily: "Lato",
      }}
    >
      <div className="testnav" style={{ background: "white" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              padding: "0.7%",
            }}
          >
            {/* <Link to="/admin/test"> */}
            <IconButton onClick={handleBack}>
              <ArrowBackIcon sx={{ color: "black" }} />
            </IconButton>
            {/* </Link> */}
            {props.Rowdata?.name ? <>{props.Rowdata?.name}</> : <>Default</>}
          </div>
          <div style={{ paddingRight: "0.7%" }}>
            <Button variant="contained" onClick={handleSubmitTestSet}>
              Submit
            </Button>
          </div>
        </Stack>
        <Dialog open={openName} onClose={handleClose}>
          <DialogTitle>Name of the Test Series</DialogTitle>
          <DialogContent>
            <Input
              type="text"
              onChange={(event) => {
                setTestSeriesName(event.target.value);
              }}
              value={testSeriesName}
              fullWidth
              placeholder="Enter Name of the Test Series"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} variant="contained">
              Submit
            </Button>
            <Button onClick={() => setOpenName(false)} variant="contained">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <div style={{ padding: "5%" }}>
            <Accordion sx={{ marginTop: "5%", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon fontSize="10px" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <b>Create Question</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <Typography
                    onClick={handleOpenMCQ}
                    style={{ cursor: "pointer" }}
                  >
                    Multiple Choice Questions
                  </Typography>
                  <Divider />
                </>
                <>
                  <Typography
                    onClick={handleOpenTF}
                    style={{ cursor: "pointer" }}
                  >
                    True/False Questions
                  </Typography>
                  <Divider />
                </>
                <>
                  <Typography
                    onClick={handleOpenCO}
                    style={{ cursor: "pointer" }}
                  >
                    Comprehension Questions
                  </Typography>
                  <Divider />
                </>
                {/* <>
                  Fill In The Blanks Questions
                  <Divider />
                </> */}
                <>
                  <Typography
                    onClick={handleOpenIQ}
                    style={{ cursor: "pointer" }}
                  >
                    Integer Type Questions
                  </Typography>
                  <Divider />
                </>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginTop: "5%", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon fontSize="10px" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <b>Grading</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <Typography
                    sx={{
                      width: "260px",
                      paddingTop: "10px",
                      display: "inline-block",
                    }}
                  >
                    Multiple Choice Questions
                  </Typography>
                  <TextField
                    name="mcqPositiveGrade"
                    value={grade.mcqPositiveGrade}
                    onChange={handleGrade}
                    color="success"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      // border:"1px solid Red",
                      borderRadius: "10px",
                      border: "none",
                      width: "70px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <TextField
                    name="mcqNegativeGrade"
                    value={grade.mcqNegativeGrade}
                    onChange={handleGrade}
                    color="error"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      // border:"1px solid Green",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      borderRadius: "10px",
                    }}
                  />

                  <Divider />
                </>
                <>
                  <Typography
                    sx={{
                      width: "260px",
                      paddingTop: "10px",
                      display: "inline-block",
                    }}
                  >
                    True/False Questions
                  </Typography>
                  <TextField
                    name="booleanPositiveGrade"
                    value={grade.booleanPositiveGrade}
                    onChange={handleGrade}
                    color="success"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      // border:"1px solid Red",
                      borderRadius: "10px",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <TextField
                    name="booleanNegativeGrade"
                    value={grade.booleanNegativeGrade}
                    onChange={handleGrade}
                    color="error"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      // border:"1px solid Green",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      borderRadius: "10px",
                    }}
                  />
                  <Divider />
                </>
                <>
                  <Typography
                    sx={{
                      width: "260px",
                      paddingTop: "10px",
                      display: "inline-block",
                    }}
                  >
                    Comprehension Questions
                  </Typography>
                  <TextField
                    name="comprehensionPositiveGrade"
                    value={grade.comprehensionPositiveGrade}
                    onChange={handleGrade}
                    color="success"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      // border:"1px solid Red",
                      borderRadius: "10px",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <TextField
                    name="comprehensionNegativeGrade"
                    value={grade.comprehensionNegativeGrade}
                    onChange={handleGrade}
                    color="error"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      // border:"1px solid Green",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      borderRadius: "10px",
                    }}
                  />
                  <Divider />
                </>
                {/* <>
                  Fill In The Blanks Questions
                  <Divider />
                </> */}
                <>
                  <Typography
                    sx={{
                      width: "260px",
                      paddingTop: "10px",
                      display: "inline-block",
                    }}
                  >
                    Integer Type Questions
                  </Typography>
                  <TextField
                    name="integerPositiveGrade"
                    value={grade.integerPositiveGrade}
                    onChange={handleGrade}
                    color="success"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      //border:"1px solid Red",
                      borderRadius: "10px",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <TextField
                    name="integerNegativeGrade"
                    value={grade.integerNegativeGrade}
                    onChange={handleGrade}
                    color="error"
                    focused
                    inputProps={{ style: { textAlign: "center" } }}
                    sx={{
                      //  border:"1px solid Green",
                      outline: "none",
                      width: "70px",
                      height: "50px",
                      borderRadius: "10px",
                    }}
                  />
                  <Divider />
                </>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginTop: "5%", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon fontSize="10px" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <b>Test Sections</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button variant="contained" onClick={handleSection}>
                    Add New Section
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginTop: "5%", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon fontSize="10px" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <b>Test Settings</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  <div>
                    <TextField
                      label="Order of Questions"
                      defaultValue="Quantitative Aptitude"
                      fullWidth
                    />
                  </div>
                  <Divider />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="column">
                      <div>
                        <Typography>Enable Solutions</Typography>
                      </div>
                      <div>
                        <Typography variant="caption">
                          Allow Students to view solutions
                        </Typography>
                      </div>
                    </Stack>
                    <div>
                      <Checkbox />
                    </div>
                  </Stack>
                  <Divider />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="column">
                      <div>
                        <Typography>Allow Section Switching</Typography>
                      </div>
                      <div>
                        <Typography variant="caption">
                          Student can switch sections during the course of the
                          test when you create more than one section.
                        </Typography>
                      </div>
                    </Stack>
                    <div>
                      <Checkbox />
                    </div>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion sx={{ marginTop: "5%", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon fontSize="10px" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <b>Import Questions</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <div>
                    <Button variant="outlined">
                      From My Library{" "}
                      <ArrowForwardIosSharpIcon sx={{ fontSize: "small" }} />
                    </Button>
                  </div>
                  OR
                  <div>
                    <Button variant="outlined">Upload Word File</Button>
                  </div>
                </Stack>
              </AccordionDetails>
            </Accordion> */}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          border="1px solid #DDD"
          sx={{ background: "white", height: "100vh" }}
        >
          <Stack>
            <div style={{ padding: "1%" }}>
              <Stack direction="row" alignItems="center">
                <div>
                  <Typography width={100} fontWeight="700">
                    Test Details
                  </Typography>
                </div>
                <div>
                  <EditIcon />
                </div>
              </Stack>
              <Stack direction="row">
                <div>
                  <AccessTimeIcon sx={{ fontSize: "1rem", color: "#666" }} />
                </div>
                <div style={{ width: "100%" }}>
                  <Typography fontSize="0.8rem" color="#666">
                    Test Duration: {props.Rowdata.duration} Minutes
                  </Typography>
                </div>
              </Stack>
              <Stack
                sx={{
                  marginTop: "2%",
                  listStylePosition: "inside",
                  padding: "2.5%",
                  border: "1px solid #DDD",
                  borderRadius: "0.3125rem",
                }}
              >
                <div>
                  {/* <ol>
                    <li>
                      The test consists of 100 questions, each question has four
                      options out of which only one option will be correct.
                    </li>
                    <li>3 marks will be awarded for every right answer.</li>
                    <li>1 mark will be deducted for every wrong answer.</li>
                    <li>
                      No marks will be awarded or dedcuted for un-answered
                      questions.
                    </li>
                  </ol> */}
                  {props.Rowdata.description}
                </div>
              </Stack>
              <Stack
                sx={{
                  marginTop: "2%",
                  listStylePosition: "inside",
                  border: "1px solid #DDD",
                  borderRadius: "0.3125rem",
                }}
              >
                {/* <div style={{ padding: "2.5%" }}> */}
                {testSet.sections &&
                  testSet.sections.map((item, index) => (
                    <Accordion
                      expanded={expanded === "panel" + item.id}
                      onChange={handleAccordianChange("panel" + item.id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={"panel1a-header" + index}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <Stack direction="column">
                            <div>
                              <Typography>
                                Section {index + 1} - {item.questions.length}{" "}
                                Questions
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                fontSize="0.7rem"
                                sx={{ color: "#666" }}
                              >
                                {item.instructions}
                              </Typography>
                            </div>
                          </Stack>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            gap={1}
                          >
                            <div>
                              <EditIcon
                                sx={{ fontSize: "0.9rem", color: "#0373BA" }}
                              />
                            </div>
                            <div>
                              <Typography
                                sx={{ color: "#0373BA" }}
                                onClick={() => {
                                  handleEditSection(item.id);
                                }}
                              >
                                Edit Details
                              </Typography>
                            </div>
                            <div>
                              <DeleteIcon
                                sx={{ fontSize: "0.9rem", color: "#0373BA" }}
                              />
                            </div>
                            <div>
                              <Typography
                                sx={{ color: "#0373BA" }}
                                onClick={() => {
                                  handleDeleteSection(item.id);
                                }}
                              >
                                Delete Section
                              </Typography>
                            </div>
                          </Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item.questions &&
                          item.questions.map((ele, index) => {
                            console.log(ele);
                            if (ele.type === "mcq") {
                              return (
                                <>
                                  <hr />
                                  <DialogContent>
                                    <Typography
                                      fullWidth
                                      sx={{
                                        fontFamily: "Lato",
                                        // border: "1px solid #666",
                                        padding: "1%",
                                        // borderRadius: "0.3125rem",
                                      }}
                                    >
                                      {index + 1}. {ele.question}
                                    </Typography>
                                    {/* </div> */}
                                    <Stack
                                      direction="column"
                                      sx={{
                                        marginBottom: "10px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      {ele.options.map((option, index) => (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          key={index}
                                        >
                                          <div>
                                            <Checkbox
                                              checked={option.correct_status}
                                              name="checkValuesData"
                                              disabled={true}
                                              // inputProps={{ "aria-label": option.label }}
                                            />
                                          </div>
                                          <div style={{ width: "100%" }}>
                                            <Typography fullWidth>
                                              {option.name}
                                            </Typography>
                                          </div>
                                        </Stack>
                                      ))}
                                    </Stack>
                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "#156C3C",
                                        background: "#AAEEC8",
                                        marginLeft: "25px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      +{ele.marks}
                                    </Button>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{ marginTop: "-10px" }}
                                    >
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          onClick={handleQuestionEdit}
                                          startIcon={<EditIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Edit
                                        </Button>
                                      </Grid>
                                      {/* <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          startIcon={<ContentCopyIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Copy
                                        </Button>
                                      </Grid> */}
                                      <Grid item>
                                        <Button
                                          onClick={() =>
                                            handleQuestionDelete(ele.id)
                                          }
                                          variant="text"
                                          startIcon={<DeleteIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Delete
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </DialogContent>
                                </>
                              );
                            } else if (ele.type === "integer") {
                              return (
                                <>
                                  <hr />
                                  <DialogContent>
                                    <Typography
                                      fullWidth
                                      sx={{
                                        fontFamily: "Lato",
                                        padding: "1%",
                                      }}
                                    >
                                      {index + 1}. {ele.question}
                                    </Typography>
                                    <Stack
                                      direction="column"
                                      sx={{
                                        marginBottom: "10px",
                                        marginLeft: "25px",
                                      }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <TextField
                                          onChange={handleMcqAnswer}
                                          disabled
                                          inputProps={{
                                            style: { height: "10px" },
                                          }}
                                          variant="filled"
                                          id="outlined-basic"
                                          value={ele.answer}
                                        />
                                      </div>
                                    </Stack>

                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "#156C3C",
                                        background: "#AAEEC8",
                                        marginLeft: "25px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      +{ele.marks}
                                    </Button>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{ marginTop: "-10px" }}
                                    >
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          onClick={handleQuestionEdit}
                                          startIcon={<EditIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Edit
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          startIcon={<ContentCopyIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Copy
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          onClick={() =>
                                            handleQuestionDelete(ele.id)
                                          }
                                          startIcon={<DeleteIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Delete
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </DialogContent>
                                </>
                              );
                            } else if (ele.type === "comprehension") {
                              console.log("a,o");
                              return (
                                <>
                                  <hr />
                                  <DialogContent>
                                    <Typography
                                      fullWidth
                                      sx={{
                                        fontFamily: "Lato",
                                        // border: "1px solid #666",
                                        padding: "1%",
                                        // borderRadius: "0.3125rem",
                                      }}
                                    >
                                      {index + 1}. {ele.question}
                                    </Typography>
                                    {/* </div> */}
                                    <Stack
                                      direction="column"
                                      sx={{
                                        marginBottom: "10px",
                                        marginLeft: "25px",
                                      }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <TextField
                                          fullWidth
                                          value={ele.answer}
                                          onChange={handleMcqAnswer}
                                          disabled
                                          variant="filled"
                                          inputProps={{
                                            style: { height: "10px" },
                                          }}
                                        />
                                      </div>
                                    </Stack>
                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "#156C3C",
                                        background: "#AAEEC8",
                                        marginLeft: "25px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      +{ele.marks}
                                    </Button>

                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{ marginTop: "-10px" }}
                                    >
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          onClick={handleQuestionEdit}
                                          startIcon={<EditIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Edit
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          startIcon={<ContentCopyIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Copy
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          onClick={() =>
                                            handleQuestionDelete(ele.id)
                                          }
                                          startIcon={<DeleteIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Delete
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </DialogContent>
                                </>
                              );
                            } else if (ele.type === "boolean") {
                              console.log("a,o");
                              return (
                                <>
                                  <hr />
                                  <DialogContent>
                                    <Typography
                                      fullWidth
                                      sx={{
                                        fontFamily: "Lato",
                                        // border: "1px solid #666",
                                        padding: "1%",
                                        // borderRadius: "0.3125rem",
                                      }}
                                    >
                                      {index + 1}. {ele.question}
                                    </Typography>
                                    {/* </div> */}
                                    <Stack
                                      direction="column"
                                      sx={{
                                        marginBottom: "10px",
                                        marginLeft: "25px",
                                      }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <TextField
                                          fullWidth
                                          value={ele.answer}
                                          onChange={handleMcqAnswer}
                                          disabled
                                          variant="filled"
                                          inputProps={{
                                            style: { height: "10px" },
                                          }}
                                        />
                                      </div>
                                    </Stack>
                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "#156C3C",
                                        background: "#AAEEC8",
                                        marginLeft: "25px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      +{ele.marks}
                                    </Button>
                                    <Grid
                                      container
                                      spacing={3}
                                      sx={{ marginTop: "-10px" }}
                                    >
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          questionId={ele.id}
                                          onClick={handleQuestionEdit}
                                          startIcon={<EditIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Edit
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          startIcon={<ContentCopyIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Copy
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          onClick={() =>
                                            handleQuestionDelete(ele.id)
                                          }
                                          startIcon={<DeleteIcon />}
                                          style={{ color: "#666666" }}
                                        >
                                          Delete
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </DialogContent>
                                </>
                              );
                            }
                          })}
                        <Stack
                          direction="column"
                          gap={3}
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: "50vh" }}
                        >
                          <div>
                            <b>Let's add questions</b>
                          </div>
                          <div style={{ width: "50ch", textAlign: "center" }}>
                            You can use the <b>'Create Questions'</b> button or{" "}
                            <b>'Create Questions'</b> panel on left to add
                            questions to a section.
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              id={item.id}
                              onClick={handleClickOpen}
                            >
                              Create Questions
                            </Button>
                          </div>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                {testSet.sections.length === 0 && (
                  <Stack
                    direction="column"
                    gap={3}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "50vh" }}
                  >
                    <div>
                      <b>Let's add section</b>
                    </div>
                    <div style={{ width: "50ch", textAlign: "center" }}>
                      You can use the <b>'Add New Section'</b> button or{" "}
                      <b>'Test Sections'</b> panel on left to add questions to a
                      section.
                    </div>
                    <div>
                      <Button variant="contained" onClick={handleSection}>
                        Add New Section
                      </Button>
                    </div>
                  </Stack>
                )}
              </Stack>
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Create Question</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose the type of question:</DialogContentText>
          <ul style={{ listStyle: "none" }}>
            <li style={{ margin: "2%", width: "250px" }}>
              <Button
                onClick={handleOpenMCQ}
                variant="outlined"
                fullWidth={true}
              >
                Multiple Choice Questions
              </Button>
            </li>
            <li style={{ margin: "2%", width: "250px" }}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={handleOpenTF}
              >
                True/False Questions
              </Button>
            </li>
            <li style={{ margin: "2%", width: "250px" }}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={handleOpenCO}
              >
                Comprehension Questions
              </Button>
            </li>
            {/* <li style={{ margin: "2%",width:'250px' }}>
              <Button variant="outlined" fullWidth={true} >Fill In The Blanks Questions</Button>
            </li> */}
            <li style={{ margin: "2%", width: "250px" }}>
              <Button
                variant="outlined"
                fullWidth={true}
                onClick={handleOpenIQ}
              >
                Integer Type Questions
              </Button>
            </li>
          </ul>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openMCQ}
        onClose={handleCloseCQ}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <b>1. Multiple Choice</b>{" "}
              <span
                style={{
                  fontSize: "1rem",
                  background: "#AAEEC8",
                  padding: "0.2rem",
                  color: "#156C3C",
                }}
              >
                + {multipleQuestionData.mcqPositiveMarks}
              </span>
            </div>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1}>
                <Button variant="contained">Previous</Button>
                <Button variant="contained" onClick={handleMCQSave}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>
                  Next
                </Button>
                <Button onClick={handleCloseCQ} variant="contained">
            Cancel
          </Button>
              </Stack>
             
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Question</DialogContentText>
          {/* <div
              style={{
                fontFamily: "Lato",
                border: "1px solid #666",
                padding: "1%",
                borderRadius: "0.3125rem",
                marginTop: "1%",
              }}
            > */}
          <TextField
            fullWidth
            placeholder="This is MCQ Type Question"
            onChange={handleMcqAnswer}
            name="question"
            value={multipleQuestionData.question}
            sx={{
              fontFamily: "Lato",
              // border: "1px solid #666",
              padding: "1%",
              borderRadius: "0.3125rem",
              marginTop: "1%",
            }}
          />
          {/* </div> */}
          <DialogContentText marginTop="1.5rem" marginBottom="1.5rem">
            Answers
          </DialogContentText>
          <Stack direction="column" gap={2}>
            {multipleQuestionData.options.map((option, index) => (
              <Stack direction="row" alignItems="center" key={index}>
                <div>
                  <Checkbox
                    name={"checkValues" + (index + 1)}
                    checked={option.correct_status}
                    // inputProps={{ "aria-label": option.label }}
                    onChange={handleMcqAnswer}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    value={option.name}
                    name={"textField" + (index + 1)}
                    label={"Option" + (index + 1)}
                    onChange={handleMcqAnswer}
                  />
                </div>
                <div>
                  <Button
                    variant="text"
                    sx={{ color: "black" }}
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => handleDeleteOption(index)}
                  >
                    Delete
                  </Button>
                </div>
              </Stack>
            ))}
          </Stack>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <Button variant="contained" onClick={handleAddedOption}>
              Add a new option
            </Button>
          </div>
          <Stack direction="column">
            <DialogContentText>Solution</DialogContentText>
            <TextField
              fullWidth
              label="Enter detailed solution for your students"
              value={multipleQuestionData.solution}
              onChange={handleMcqAnswer}
              name="solution"
            />
          </Stack>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1%",
            }}
          >
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="mcqNegFlag"
                    onChange={handleMcqAnswer}
                    checked={multipleQuestionData.mcqNegFlag}
                  />
                }
                label="Negative Marks"
                sx={{
                  marginTop: "6%",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText>Negative Marks</DialogContentText>
              <TextField
                autoWidth
                name="mcqNegativeMarks"
                onChange={handleMcqAnswer}
                value={multipleQuestionData.mcqNegativeMarks}
                placeholder="0"
                inputProps={{ style: { height: "10px" } }}
                disabled={!multipleQuestionData.mcqNegFlag}
              />
            </Grid>
            <Grid item xs={5}>
              <DialogContentText>Positive Marks</DialogContentText>
              <TextField
                autoWidth
                name="mcqPositiveMarks"
                onChange={handleMcqAnswer}
                value={multipleQuestionData.mcqPositiveMarks}
                placeholder="1"
                inputProps={{ style: { height: "10px" } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1}>
                <Button variant="contained" onClick={previousButton}>Previous</Button>
                <Button variant="contained" onClick={handleMCQSave}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>
                  Next
                </Button>
                <Button onClick={handleCloseCQ} variant="contained">
            Cancel
          </Button>
              </Stack>
              
            </Stack>
          
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openIQ}
        onClose={handleOpenIQ}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <b>2. Integer Type</b>{" "}
              <span
                style={{
                  fontSize: "1rem",
                  background: "#AAEEC8",
                  padding: "0.2rem",
                  color: "#156C3C",
                }}
              >
                + {integerQuestionFormData.integerPositiveMarks}
              </span>
            </div>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1}>
                <Button variant="contained">Previous</Button>
                <Button variant="contained" onClick={addIntegerQuestion}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>
                  Next
                </Button>
                <Button onClick={handleOpenIQ} variant="contained">
            Cancel
          </Button>
                
            </Stack>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Question</DialogContentText>
          <TextField
            onChange={handleIntgerQuestion}
            fullWidth
            name="integer_question"
            label="Enter detailed question for your students"
            value={integerQuestionFormData.integer_question}
          />
          <DialogContentText marginTop="1.5rem">Answers</DialogContentText>
          <TextField
            onChange={handleIntgerQuestion}
            fullWidth
            value={integerQuestionFormData.integer_answer}
            name="integer_answer"
            label="Enter detailed answer for your students"
          />

          <Stack direction="column" marginTop="2%">
            <DialogContentText>Solution</DialogContentText>
            <TextField
              onChange={handleIntgerQuestion}
              fullWidth
              value={integerQuestionFormData.integer_solution}
              name="integer_solution"
              label="Enter detailed solution for your students"
            />
          </Stack>

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1%",
            }}
          >
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={integerQuestionFormData.integerNegFlag}
                    name="integerNegFlag"
                    onChange={handleIntgerQuestion}
                  />
                }
                label="Negative Marks"
                sx={{
                  marginTop: "6%",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText>Negative Marks</DialogContentText>
              <TextField
                name="integerNegativeMarks"
                onChange={handleIntgerQuestion}
                value={integerQuestionFormData.integerNegativeMarks}
                autoWidth
                placeholder="1"
                inputProps={{ style: { height: "10px" } }}
                disabled={!integerQuestionFormData.integerNegFlag}
              />
            </Grid>
            <Grid item xs={5}>
              <DialogContentText>Positive Marks</DialogContentText>
              <TextField
                name="integerPositiveMarks"
                onChange={handleIntgerQuestion}
                value={integerQuestionFormData.integerPositiveMarks}
                autoWidth
                placeholder="1"
                inputProps={{ style: { height: "10px" } }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
        <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1}>
                <Button variant="contained">Previous</Button>
                <Button variant="contained" onClick={addIntegerQuestion}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>
                  Next
                </Button>
                <Button onClick={handleOpenIQ} variant="contained">
            Cancel
          </Button>
                
            </Stack>
            </Stack>
          
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCO}
        onClose={handleCloseCO}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <b>3. Comprehension</b>{" "}
              <span
                style={{
                  fontSize: "1rem",
                  background: "#AAEEC8",
                  padding: "0.2rem",
                  color: "#156C3C",
                }}
              >
                + {comprehensionData.comprehensionPositiveMarks}
              </span>
            </div>
            <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1}>
                <Button variant="contained">Previous</Button>
                <Button variant="contained" onClick={addComprehension}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>
                  Next
                </Button>
                <Button onClick={handleCloseCO} variant="contained">
            Cancel
          </Button>
                
            </Stack>
            </Stack>
              
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Question</DialogContentText>
          <TextField
            fullWidth
            value={comprehensionData.questionComprehension}
            label="This is a dummy question"
            name="questionComprehension"
            onChange={hanldeComprehension}
          />
          <DialogContentText marginTop="1.5rem">Paragraph</DialogContentText>
          <TextField
            fullWidth
            value={comprehensionData.paragraph}
            label="This is a dummy paragraph. Click options below to start adding questions here."
            name="paragraph"
            onChange={hanldeComprehension}
          />
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1%",
            }}
          >
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={comprehensionData.comprehensionNegFlag}
                    name="comprehensionNegFlag"
                    onChange={hanldeComprehension}
                  />
                }
                label="Negative Marks"
                sx={{
                  marginTop: "6%",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText>Negative Marks</DialogContentText>
              <TextField
                value={comprehensionData.comprehensionNegativeMarks}
                name="comprehensionNegativeMarks"
                onChange={hanldeComprehension}
                autoWidth
                placeholder="1"
                inputProps={{ style: { height: "10px" } }}
                disabled={!comprehensionData.comprehensionNegFlag}
              />
            </Grid>
            <Grid item xs={5}>
              <DialogContentText>Positive Marks</DialogContentText>
              <TextField
                value={comprehensionData.comprehensionPositiveMarks}
                onChange={hanldeComprehension}
                autoWidth
                name="comprehensionPositiveMarks"
                placeholder="1"
                inputProps={{ style: { height: "10px" } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <Stack direction="row" gap={1}>
                <Button variant="contained">Previous</Button>
                <Button variant="contained" onClick={addComprehension}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>
                  Next
                </Button>
                <Button onClick={handleCloseCO} variant="contained">
            Cancel
          </Button>
                
            </Stack>
          
        </DialogActions>
      </Dialog>
      {/* Code Commented for True False Modal */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openTF}
        onClose={handleCloseTF}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <b>4. True False</b>{" "}
              <span
                style={{
                  fontSize: "1rem",
                  background: "#AAEEC8",
                  padding: "0.2rem",
                  color: "#156C3C",
                }}
              >
                + {trueFalseData.booleanPositiveMarks}
              </span>
            </div>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1}>
                <Button variant="contained" onClick={previousButton}>Previous</Button>
                <Button variant="contained" onClick={handleTFSave}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>Next</Button>
                <Button onClick={handleCloseTF} variant="contained">
            Cancel
          </Button>
              </Stack>
              
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Question</DialogContentText>
          <TextField
            fullWidth
            placeholder="This is a true false question"
            onChange={handleTrueFalseData}
            name="question"
            value={trueFalseData.question}
            sx={{
              fontFamily: "Lato",
              padding: "1%",
              borderRadius: "0.3125rem",
              marginTop: "1%",
            }}
          />
          <DialogContentText marginTop="1.5rem" marginBottom="1.5rem">
            Answers
          </DialogContentText>
          <RadioGroup>
            <Stack direction="column" gap={2}>
              <Stack direction="row" alignItems="center">
                <div>
                  <Radio
                    // checked={selectedValue === 'a'}
                    // onChange={handleChange}
                    checked={trueFalseData.booleanAnswer ? true : false}
                    value="true"
                    name="booleanAnswer"
                    inputProps={{ "aria-label": "true" }}
                    onChange={handleTrueFalseData}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TextField fullWidth label="True" disabled />
                </div>
              </Stack>
              <Stack direction="row" alignItems="center">
                <div>
                  <Radio
                    // checked={selectedValue === 'a'}
                    // onChange={handleChange}
                    checked={trueFalseData.booleanAnswer ? false : true}
                    value="false"
                    name="booleanAnswer"
                    inputProps={{ "aria-label": "false" }}
                    onChange={handleTrueFalseData}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <TextField fullWidth label="False" disabled />
                </div>
              </Stack>
            </Stack>
          </RadioGroup>
          <RadioGroup>
            <Stack direction="column" marginTop="2rem">
              <DialogContentText>Solution</DialogContentText>
              <TextField
                fullWidth
                value={trueFalseData.solution}
                label="Enter detailed solution for your students"
                onChange={handleTrueFalseData}
                name="solution"
              />
            </Stack>
          </RadioGroup>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1%",
            }}
          >
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={trueFalseData.booleanNegFlag}
                    name="booleanNegFlag"
                    onChange={handleTrueFalseData}
                  />
                }
                label="Negative Marks"
                sx={{
                  marginTop: "6%",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DialogContentText>Negative Marks</DialogContentText>
              <TextField
                defaultValue={trueFalseData.booleanNegativeMarks}
                autoWidth
                name="booleanNegativeMarks"
                value={trueFalseData.booleanNegativeMarks}
                placeholder="1"
                onChange={handleTrueFalseData}
                disabled={!trueFalseData.booleanNegFlag}
                inputProps={{ style: { height: "10px" } }}
              />
            </Grid>
            <Grid item xs={5}>
              <DialogContentText>Positive Marks</DialogContentText>
              <TextField
                name="booleanPositiveMarks"
                value={trueFalseData.booleanPositiveMarks}
                onChange={handleTrueFalseData}
                autoWidth
                placeholder="1"
                inputProps={{ style: { height: "10px" } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <Stack direction="row" gap={1}>
                <Button variant="contained" onClick={previousButton}>Previous</Button>
                <Button variant="contained" onClick={handleTFSave}>
                  Save
                </Button>
                <Button variant="contained" onClick={nextButton}>Next</Button>
                <Button onClick={handleCloseTF} variant="contained">
            Cancel
          </Button>
              </Stack>
          
        </DialogActions>
      </Dialog>
      {/* Marks Numbers New Modal Dipanshu */}
      {/* <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openTF}
        onClose={handleCloseTF}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <b>Multiple Choice</b>
              <span
                style={{
                  fontSize: "1rem",
                  background: "#AAEEC8",
                  padding: "0.2rem",
                  color: "#156C3C",
                }}
              >

              </span>
            </div>
            <Stack direction="row" alignItems="center">
              <div>
                <IconButton aria-label="Example">
                  <CloseIcon />
                </IconButton>
              </div>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DialogContentText>No. of Question</DialogContentText>
              <TextField
                autoWidth
                placeholder="1"
                sx={{
                  fontFamily: "Lato",
                  padding: "0.5%",
                  borderRadius: "0.3125rem",
                  marginTop: "1%",
                  height:"0.4375em",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>Marks Per Question</DialogContentText>
              <TextField
                autoWidth
                placeholder="1"
                sx={{
                  fontFamily: "Lato",
                  padding: "1%",
                  borderRadius: "0.3125rem",
                  marginTop: "1%",
                  height:"0.4375em",
                }}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={<Checkbox />}
            label="Negative Marks"
            sx={{
              marginTop: "6%",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTF} variant="contained">
            Create Question
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openSection}
        onClose={handleSection}
        sx={{
          maxHeight: "1000px",
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <b>Add New Section</b>
              <span
                style={{
                  fontSize: "1rem",
                  background: "#AAEEC8",
                  padding: "0.2rem",
                  color: "#156C3C",
                }}
              ></span>
            </div>
            <Stack direction="row" alignItems="center">
              <div>
                <IconButton onClick={handleSection} aria-label="Example">
                  <CloseIcon />
                </IconButton>
              </div>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                <Typography variant="body1" fontWeight="bold">
                  Section Name
                </Typography>
              </DialogContentText>
              <TextField
                fullWidth
                placeholder="Enter The Section Name"
                onChange={handleSectionData}
                value={sectionFormData.name}
                sx={{
                  fontFamily: "Lato",
                  padding: "0.5%",
                  borderRadius: "0.3125rem",
                  marginTop: "1%",
                  height: "0.4375em",
                }}
                name="name"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "6%",
            }}
          >
            <Grid item xs={12}>
              <DialogContentText>
                <Typography variant="body1" fontWeight="bold">
                  Allow Section Switching
                </Typography>
              </DialogContentText>
              <FormControlLabel
                control={<Checkbox checked={sectionFormData.allow_switch} />}
                name="allow_switch"
                label="Student can switch sections during the course of the test when you create more than one section."
                labelPlacement="start"
                sx={{
                  marginTop: "1%",
                  marginLeft: "0",
                }}
                onChange={handleSectionData}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                <Typography variant="body1" fontWeight="bold">
                  Section Instruction
                </Typography>
              </DialogContentText>
              <TextField
                fullWidth
                placeholder="Enter The Section Instruction"
                name="instructions"
                value={sectionFormData.instructions}
                onChange={handleSectionData}
                sx={{
                  fontFamily: "Lato",
                  padding: "0.5%",
                  borderRadius: "0.3125rem",
                  marginTop: "1%",
                  height: "2em",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSection} variant="text">
            Close
          </Button>
          <Button
            onClick={addSection}
            sectionId={sectionFormData.id}
            variant="contained"
          >
            Add Section
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        autoWidth
        maxWidth="md"
        open={openPopUp}
        onClose={handleOpenPopUp}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <img
              className="fluid-image check-box-image"
              src="/images/Icon.png"
              alt="analysis"
            />
          </div>
          <DialogContentText>
            <Typography variant="body1" fontWeight="700">
              {sectionFormData.id ? "Section Updated" : "New Section Added!"}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
