import React, { useState } from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { CourseGridView } from "../../../components/AdminCourse/CourseGridView";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import "./Course.scss";
import publicAxios from "../../../axios/publicAxios";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

export const CourseMgmt = () => {
  return (
    <div className="main-user-dash">
      <CourseGridView />
    </div>
  );
};
