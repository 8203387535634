import { useCallback, useState } from "react";

import Loader from "../../Tools/Loader";
import CurrentAffairCard from "./CurrentAffairCard";
import { useFetchBlogs } from "../../../helper/Blog/useFetchBlogs";
import { LoadMore } from "../../Tools/LoadMore";
import { NoBlog } from "../NoBlog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "./CurrentAffair.scss";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import AdsComponent from "../../../helper/Adsense/Adsense";

const CurrentAffairCollections = () => {
  const category = "CurrentAffair";
  const [page, setPage] = useState(1);
  const [state, setState] = useState(false);
  const [reset, setReset] = useState(false);

  const [date, setDate] = useState({
    start_date: dayjs().subtract(15, "days"),
    end_date: dayjs(),
  });

  const [dateRange, setDateRange] = useState({
    start_date: dayjs().subtract(15, "days"),
    end_date: dayjs(),
  });

  const dateFun = useCallback(() => {
    setDate({ start_date: dateRange.start_date, end_date: dateRange.end_date });
    setState(true);
  }, [date]);

  const [currentAffairsData, totalPages, loading, noCurrentAffairsData, error] =
    useFetchBlogs(category, page, dateRange, dateFun, state, reset);

  const handleLoadButton = () => {
    setPage((page) => page + 1);
    setReset(false);
  };

  function resetHandlers() {
    setPage(1);
    setDateRange({
      start_date: dayjs().subtract(15, "days"),
      end_date: dayjs(),
    });
    setReset(true);
    setState(false);
  }

  if (noCurrentAffairsData) {
    return (
      <>
        <Grid container padding={0} marginTop={3} direction={"column"}>
          <Typography style={{ textAlign: "left" }}>Date Range :</Typography>

          <Box sx={{ width: "100%" }} my={4}>
            <AdsComponent />
          </Box>
          <Grid
            item
            xs={12}
            lg={4}
            md={8}
            sx={{ width: "fit-content" }}
            padding={2}
          >
            <Stack
              direction={"row"}
              sx={{ gap: "1rem", alignItems: "center" }}
              color={"#0373BA"}
              flexWrap={"wrap"}
            >
              {(!dayjs(dateRange.start_date).isSame(
                dayjs().subtract(15, "days"),
                "day"
              ) ||
                !dayjs(dateRange.end_date).isSame(dayjs(), "day")) && (
                <div>
                  <Button
                    variant="contained"
                    onClick={!state ? dateFun : resetHandlers}
                    startIcon={!state ? <DoneIcon /> : <RestartAltIcon />}
                  >
                    {!state ? "Apply" : "Reset"}
                  </Button>
                </div>
              )}
            </Stack>
          </Grid>
        </Grid>
        <NoBlog />
      </>
    );
  }

  return (
    <>
      <Grid container padding={0} marginTop={3} direction={"column"}>
        <Typography style={{ textAlign: "left" }}>Date Range :</Typography>
        <Grid
          item
          xs={12}
          lg={4}
          md={8}
          sx={{ width: "fit-content" }}
          padding={2}
        >
          <Stack
            direction={"row"}
            sx={{ gap: "1rem", alignItems: "center" }}
            color={"#0373BA"}
            flexWrap={"wrap"}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={dateRange.start_date}
                onChange={(newValue) => {
                  setDateRange({ ...dateRange, start_date: newValue });
                  setState(false);
                }}
              />
              &#45;
              <DatePicker
                label="End Date"
                value={dateRange.end_date}
                onChange={(newValue) => {
                  setDateRange({ ...dateRange, end_date: newValue });
                  setState(false);
                }}
              />
            </LocalizationProvider>

            {(!dayjs(dateRange.start_date).isSame(
              dayjs().subtract(15, "days"),
              "day"
            ) ||
              !dayjs(dateRange.end_date).isSame(dayjs(), "day")) && (
              <div>
                <Button
                  variant="contained"
                  onClick={!state ? dateFun : resetHandlers}
                  startIcon={!state ? <DoneIcon /> : <RestartAltIcon />}
                >
                  {!state ? "Apply" : "Reset"}
                </Button>
              </div>
            )}
          </Stack>
        </Grid>
      </Grid>

      <div className="currentAffairCollections">
        {currentAffairsData.map((item) => (
          <CurrentAffairCard
            key={`${item?.id}-${category}`}
            currentAffair={item}
            short_content_count={250}
          />
        ))}

        {loading && <Loader />}
        {totalPages !== page && (
          <LoadMore
            text="Load More"
            handleLoadButton={handleLoadButton}
            variant="contained"
          />
        )}
        {error && <div className="error">{error}</div>}
      </div>
    </>
  );
};

export default CurrentAffairCollections;
