import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import privateAxios from "../../axios/privateAxios";
import publicAxios from "../../axios/publicAxios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { authAtom } from "../../state/atoms/authAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import {
  Button,
  Stack,
  Typography,
  TextField,
  Grid,
  Select,
  styled,
  MenuItem,
  DialogContentText,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toastAtom } from "../../state/atoms/toastAtom";

let postText = "";
export const AddPost = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [, setShowToast] = useRecoilState(toastAtom);

  const [formData, setFormData] = useState({
    title: {
      value: "",
    },
    description: {
      value: "",
    },
    category: {
      value: 1,
    },
    thumbnail: {
      value: null,
      error: false,
      message: null,
      id: null,
      url: "/images/admin/addCourseImage.png",
    },
  });
  const { id } = useParams();

  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  const quillRef = React.createRef();
  const handleRteChange = (content, delta, source, editor) => {
    setFormData((prev) => ({
      ...prev,
      description: {
        value: content,
      },
    }));
  };

  const handlePopUp = () => {
    setOpenPopUp(!openPopUp);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: {
        value: value,
      },
    }));
  };

  function handleThumbnailImage(event) {
    const resourceId = formData.thumbnail.id;
    uploadResource("", "image", event.target.files[0], resourceId).then(
      (res) => {
        setFormData((formData) => ({
          ...formData,
          thumbnail: {
            ...formData.thumbnail,
            value: event.target.files[0],
            id: res.data.id,
          },
        }));
      }
    );
  }

  const uploadResource = (id, type, file, resourceId) => {
    const fileData = new FormData();
    type === "video" &&
      fileData.append("duration", parseInt(file.duration / 1000)); // duration uploaded in seconds
    type === "video" && fileData.append("file_name", file.name);
    type !== "video" && fileData.append("file", file);
    fileData.append("type", type);
    id && fileData.append("chapter_id", id);
    return publicAxios
      .post(
        `v2/${resourceId ? "resource/" + resourceId : "resources"}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
          timeout: 600000,
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  const handleSubmit = (type) => {
    const req_body = {
      title: formData.title.value,
      description: formData.description.value,
      category_id: formData.category.value,
      resource_id: formData.thumbnail.id,
    };

    if (!formData.title.value) {
      return setShowToast({
        status: true,
        type: "error",
        errorMessage: `Please provide a value for title`,
      });
    }

    if (!formData.thumbnail.id) {
      return setShowToast({
        status: true,
        type: "error",
        errorMessage: `Please upload image for post`,
      });
    }

    privateAxios
      .post(`/v2/posts${id ? "/" + id : ""}`, req_body, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (type === "submit") {
          publicAxios
            .post(
              `/v2/posts/${res?.data?.id}/submit`,
              {},
              {
                headers: {
                  Authorization: "Bearer " + auth.access,
                },
              }
            )
            .then((res2) => {
              setTimeout(() => {
                id
                  ? (postText = "Post Updated Successfully")
                  : (postText = "Post Added Successfully");
                setFormData({
                  title: {
                    value: "",
                  },
                  description: {
                    value: "",
                  },
                  category: {
                    value: 1,
                  },
                  thumbnail: {
                    value: null,
                    error: false,
                    message: null,
                    id: null,
                    url: "/images/admin/addCourseImage.png",
                  },
                });
                setOpenPopUp(true);
              }, 1000);
            })
            .catch(() => {});
        } else {
          postText = "Post Saved Successfully";
          setOpenPopUp(true);
          setTimeout(() => {
            navigate("/u1NzT9Pq2s5/admin/post");
          }, 2000);
        }
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  const getInfo = (id) => {
    publicAxios
      .get(`/v2/posts/${id}`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        formData.title.value = res.data.title;
        formData.description.value = res.data.description;
        formData.category.value = res.data.category;
        formData.thumbnail.id = res.data.resource.id;
        formData.thumbnail.url = res.data.resource.url;
        setFormData({ ...formData });
        // setLoading(false);
        //  console.log(courseId);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  //const path = window.location.pathname;
  //const pathSegments = path.split('/'); // Split the path by '/'
  const variable = id; // Accessing the third segment, change the index as needed
  useEffect(() => {
    if (variable) {
      getInfo(variable);
    }
  }, [variable]);
  // Otherwise filter will be applied on fields such as the hidden column id
  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap" }} padding={2}>
        <Grid container justifyContent="space-between">
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Add Title</Typography>
                <TextField
                  style={{ background: "white" }}
                  name="title"
                  onChange={handleChange}
                  placeholder="Add Title"
                  value={formData.title.value}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Typography>Post Category</Typography>
                  <Select
                    name="category"
                    id="select-edu"
                    value={formData.category.value}
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value={1}>Current Affairs</MenuItem>
                    <MenuItem value={2}>Editorial</MenuItem>
                    <MenuItem value={3}>Vacancies</MenuItem>
                  </Select>
                </Stack>
              </Grid>
            </Grid>
            <div style={{ marginTop: "1.2rem" }}>
              <ReactQuill
                placeholder="Write Something ......"
                theme="snow"
                value={formData.description.value}
                onChange={handleRteChange}
                modules={modules}
                formats={formats}
                ref={quillRef}
                style={{}}
              />
            </div>
            <Stack
              direction="row"
              spacing={2}
              display="flex"
              justifyContent="end"
              paddingTop={2}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit("draft");
                }}
                size="small"
              >
                Save As Draft
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit("submit");
                }}
                size="small"
              >
                Publish
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                            role={undefined}
                            variant="outlined"
                            > */}
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                // color="neutral"
              >
                <img
                  src={
                    formData?.thumbnail?.value
                      ? URL.createObjectURL(formData?.thumbnail?.value)
                      : formData?.thumbnail?.url
                  }
                  height={200}
                  width={250}
                  style={{ objectFit: "contain" }}
                  alt="Course Thumbnail"
                />
                {/* Upload the Thumbnail */}
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleThumbnailImage}
                />
              </Button>
            </div>
            <Typography>Thumbnail</Typography>
          </Grid>
        </Grid>
      </Box>
      <Dialog autoWidth maxWidth="md" open={openPopUp} onClose={handlePopUp}>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <img
              className="fluid-image check-box-image"
              src="/images/Icon.png"
              alt="analysis"
            />
          </div>
          <DialogContentText>
            <Typography variant="body1" fontWeight="700">
              {postText}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
