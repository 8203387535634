import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import privateAxios from "../../../axios/privateAxios";
import { authAtom } from "../../../state/atoms/authAtom";
import { useRecoilValue } from "recoil";
import { useState, useEffect } from "react";

export const Lecturesection = ({
  lecture,
  openLecture,
  coursesectionId,
  handleOpenLecture,
  count = 1,
  resources,
  first_key,
  sequence_number,
}) => {
  const auth = useRecoilValue(authAtom);
  const [completeStatus, setCompleteStatus] = useState(null);

  const old_even = count % 2 === 0 ? "even" : " odd";
  useEffect(() => {
    if (sequence_number === 1 && first_key === 0)
      handleOpenLecture(coursesectionId, lecture?.id);
  }, [sequence_number, first_key]);

  useEffect(() => {
    const fetchCompleteStatus = async () => {
      try {
        const req_body = {
          course_id: lecture?.chapter.course,
          chapter_id: lecture?.chapter.id,
          resource_id: lecture?.id,
        };
        const response = await privateAxios.post(
          "/study/mycourses/resource",
          req_body,
          {
            headers: {
              Authorization: "Bearer " + auth.access,
            },
          }
        );
        setCompleteStatus(response.data.complete_status);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompleteStatus();
  }, [auth.access, lecture]);

  return (
    <li>
      <div
        className={`coursesection-item-wrapper ${old_even}`}
        onClick={() => {
          handleOpenLecture(coursesectionId, lecture?.id);
        }}
      >
        <div>
          <div className="title_medium">{lecture.name}</div>
          <div className="duration label_large">{/*duration of video */}</div>
        </div>
        {console.log("completeStatus", completeStatus)}
        {console.log("completeStatus", resources.complete_status)}
        <div className="wrapper-2">
          <div>
            {completeStatus || resources.complete_status ? (
              <CheckCircleIcon
                fontSize="sm"
                color="primary"
                variant="outlined"
              />
            ) : null}
          </div>
          <div className="duration label_large">
            {(lecture?.id === openLecture?.resource_id &&
              coursesectionId === openLecture?.chapter_id) ||
            lecture?.id === 1 ? (
              <>Now Playing</>
            ) : null}
          </div>
        </div>
      </div>
    </li>
  );
};
