import React from "react";
import { Stack } from "@mui/material";
import "../User.scss";
import { PostTable } from "../../../components/AdminPost/PostTable";

export const PostMgmt = () => {
  return (
    <div>
      <div className="main-dash" style={{ overflowY: "scroll" }}>
        <div
          className="main-user-dash"
          style={{ width: "100%", margin: "auto", border: "none" }}
        >
          <Stack>
            <div className="all-user-strip">Posts</div>
            <div className="userTables">
              <PostTable />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
