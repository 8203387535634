import { useState } from "react";
import { Typography, Container, Paper } from "@mui/material";
import { Link } from "react-router-dom";

// import Loader from "../../Tools/Loader";

const TermsConditionCard = () => {
    const paperStyle = {
        backgroundColor: 'transparent',   // Setting background color
        boxShadow: 'none',
        marginTop: "1%"

    };

    return (
        <Container maxWidth="md">
            <Paper style={paperStyle}>
                <Typography variant="h4" gutterBottom>
                    Terms & Conditions
                </Typography>

                <Typography variant="body1" paragraph>
                Welcome to <Link>https://studyglows.com </Link> . If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Studyglows's relationship with you in relation to this website.
                <p>The term ‘Studyglows' or 'us' or 'we' refers to the owner of the website whose registered office is at Sector c1 lda colony lucknow place of registration is Lucknow. The term 'you' refers to the user or viewer of our website.</p>
                </Typography>

                <Typography variant="h5" gutterBottom>
                The use of this website is subject to the following terms of use:
                </Typography>

                <Typography variant="body1" paragraph>
                    <ul style={{ marginLeft: "25px" }}>
                        <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                        <li>Successful course purchased user's consent will be received to the Studyglows. Studyglows will provide new updates and exciting offers on user's registered mobile number/email id by Text SMS/Call.</li>
                        <li>The inquiry received on our App or through the incoming calls will be considered as a consent for the commercial offers or for the service calls in the future through the SMS/ WhatsApp or system generated calls through dialers.</li>
                        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                        <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                        <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                        <li>All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                        <li>Reselling the company’s product without its proper consent is prohibited and unauthorized. Any such activity will be considered as violation of copyright act and legal action shall be taken.</li>
                        <li>Unauthorised use of this website by you may give rise to a claim for damages and/or be a criminal offence.</li>
                        <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We take no responsibility for the content of the linked website(s).</li>
                        <li>You may not create a link to this website from another website or document without Studyglows prior consent.</li>
                        <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</li>
                        <li>Other Terms: Credit Card orders will commence on receiving the authorization/confirmation from the Credit Card/respective Payment Gateway companies.</li>
                    </ul>
                </Typography>

                <Typography variant="h5" gutterBottom>
                Content Usage Policy
                </Typography>

                <Typography variant="body1" paragraph>
                    <ol style={{ marginLeft: "25px" }}>
                        <li style={{ marginBottom: "7px" }}>The purchased Content is subject to restrictions on viewing access and on the length of time we make them available to you. These restrictions may change over time as we add new features, devices and content. The following are restrictions for accessing Content on Studyglows Mobile Application & Website:
                            <ol style={{ marginLeft: "30px", marginTop:"7px" }}>
                                <li style={{ marginBottom: "7px" }}>You shall get access to streaming of the Content during the Validity Period through your Web browser/mobile application.</li>
                                <li style={{ marginBottom: "7px" }}>You shall only have option to do in-app download and the downloaded Content can be viewed by you in-app during the Validity Period in your compatible devices. After downloading the Content, you shall have access to the same for the Validity Period only and all access to the downloaded content shall be removed on expiry or termination of your Validity, as the case maybe.</li>
                                <li style={{ marginBottom: "7px" }}>You can watch the Content and re-watch them later during your Validity Period, by in-app downloading or streaming it, as often as you want and as long as you want provided that you have an active validity, and the viewing is within the Validity Period or for a period of time as provided in this Agreement.</li>
                            </ol>
                        </li>
                        <li style={{ marginBottom: "7px" }}>You are granted license only for in-app download of the Content in the mobile application or on such Compatible Device so permitted. Screen-casting or downloading on any other device or in any manner not permitted herein shall amount to immediate termination of Course Validity along with termination of access to all downloaded Content with a right to initiate appropriate legal action, at the sole discretion of Studyglows.</li>
                        <li style={{ marginBottom: "7px" }}>The resolution and quality of the Content you receive will depend on a number of factors, including the type of Compatible Device on which you are accessing the Content and your bandwidth, which may increase or decrease over the course of your viewing. While we strive to provide you a high-quality viewing experience, we make no guarantee as to the resolution or quality of the Content you will receive when streaming.</li>
                        <li style={{ marginBottom: "7px" }}>Except as mentioned below, all information, content, material, trademarks, services marks, trade names, and trade secrets including but not limited to the software, text, images, graphics, video, script and audio, contained in the Application, Website, Services and products are proprietary property of the Company (“Proprietary Information”). No Proprietary Information may be copied, downloaded, reproduced, modified, republished, uploaded, posted, transmitted or distributed in any way without obtaining prior written permission from the Company and nothing on this Application or Website or Services shall be or products deemed to confer a license of or any other right, interest or title to or in any of the intellectual property rights belonging to the Company, to the User. You may own the medium on which the information, content or materials resides, but the Company shall at all times retain full and complete title to the information, content or materials and all intellectual property rights inserted by the Company on such medium. Certain contents on the Website may belong to third parties. Such contents have been reproduced after taking prior consent from said party and all rights relating to such content will remain with such third party. Further, you recognize and acknowledge that the ownership of all trademarks, copyright, logos, service marks and other intellectual property owned by any third party shall continue to vest with such party and You are not permitted to use the same without the consent of the respective third party.</li>
                        <li style={{ marginBottom: "7px" }}>Your use of our products, Website, Application and Services is solely for Your personal and non-commercial use. Any use of the Application, Website, Services or products or their contents other than for personal purposes is prohibited. Your personal and non-commercial use of this Application, Website, products and / or our Services shall be subjected to the following restrictions:
                            <ol style={{ marginLeft: "25px", marginTop:"7px" }}>
                                <li style={{ marginBottom: "7px" }}>You may not decompile, reverse engineer, or disassemble the contents of the Application and / or our Website and/or Services/ products or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Application and / or our Website and/or Services/products, or remove any copyright, trademark registration, or other proprietary notices from the contents of the Application and / or and / or our Website and/or Services/products.</li>
                                <li style={{ marginBottom: "7px" }}>You will not (a) use this Application and / or our Website and/or any of our product/s or Service/s for commercial purposes of any kind, or (b) advertise or sell the Application or any products, Services or domain names or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or (c) use the Application and / or Website/our products and Services in any way that is unlawful, or harms the Company or any other person or entity as determined by the Company.</li>
                                <li style={{ marginBottom: "7px" }}>No User shall be permitted to perform any of the following prohibited activities while availing our Services:
                                    <ol style={{ marginLeft: "30px", marginTop:"7px" }}>
                                        <li style={{ marginBottom: "7px" }}>Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;</li>
                                        <li style={{ marginBottom: "7px" }}>Stalking, intimidating and/or harassing another and/or inciting other to commit violence;</li>
                                        <li style={{ marginBottom: "7px" }}>Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</li>
                                        <li style={{ marginBottom: "7px" }}>Interfering with any other person's use or enjoyment of the Application/Website/Services;</li>
                                        <li style={{ marginBottom: "7px" }}>Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;</li>
                                        <li style={{ marginBottom: "7px" }}>Make available any content or material that You do not have a right to make available under any law or contractual or fiduciary relationship, unless You own or control the rights thereto or have received all necessary consents for such use of the content;</li>
                                        <li style={{ marginBottom: "7px" }}>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
                                        <li style={{ marginBottom: "7px" }}>Post, transmit or make available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files or such other programs that may harm the Application/services, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;</li>
                                        <li style={{ marginBottom: "7px" }}>Access or use the Application/Website/Services/products in any manner that could damage, disable, overburden or impair any of the Application's/Website's servers or the networks connected to any of the servers on which the Application/Website is hosted;</li>
                                        <li style={{ marginBottom: "7px" }}>Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Application/Website/Services/products, violate any requirements, procedures, policies or regulations of networks connected to the Application/Website/Services/products, or engage in any activity prohibited by these Terms;</li>
                                        <li style={{ marginBottom: "7px" }}>Disrupt or interfere with the security of, or otherwise cause harm to, the Application/Website/Services/products, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks connected to or accessible through the Application/Website/Services/products or any affiliated or linked sites;</li>
                                        <li style={{ marginBottom: "7px" }}>Interfere with, or inhibit any user from using and enjoying access to the Application/Website/ Services/products, or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the Application/Website/Services/products;</li>
                                        <li style={{ marginBottom: "7px" }}>Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Application/Website/Services/products, to access, acquire, copy or monitor any portion of the Application /Website/Services/products, or in any way reproduce or circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Application/ Website/Services/products;</li>
                                        <li style={{ marginBottom: "7px" }}>Alter or modify any part of the Services;</li>
                                        <li style={{ marginBottom: "7px" }}>Use the Services for purposes that are not permitted by: (i) these Terms; and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or</li>
                                        <li style={{ marginBottom: "7px" }}>Violate any of the terms specified under the Terms for the use of the Application /Website/Services/products.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>Successful course purchased user's consent will be received to the Studyglows. Studyglows will provide new updates and exciting offers on user's registered mobile number/email id by Text SMS/Call.</li>
                    </ol>
                  </Typography>

                {/* Add more sections as needed */}
            </Paper>
        </Container>
    );
};

export default TermsConditionCard;
