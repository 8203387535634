import { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import "video.js/dist/video-js.css";

export const VideoPlayer = ({
  videoId,
  videoURL = "",
  videoType = "",
  thumbnailURL = "",
  handleFinishVideo,
}) => {
  const videoRef = useRef();
  const [player, setPlayer] = useState(undefined);
  const [callFinishVideoAPI, setCallFinishVideoAPI] = useState(false);
  const [vidDuration, setVidDuration] = useState(50000);
  const [blobURL, setBlobURL] = useState("");

  const videoSrc = getVideoSrc(videoType, blobURL || videoURL);

  const videoJsOptions = {
    autoplay: false,
    preload: "auto",
    controls: true,
    poster: thumbnailURL,
    fluid: false,
    responsive: false,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [videoSrc],
    html5: {
      nativeAudioTracks: true,
      nativeVideoTracks: true,
      nativeTextTracks: true,
    },
  };

  useEffect(() => {
    const v_player = videojs(
      videoRef.current,
      videoJsOptions,
      function onPlayerReady() {
        console.log("Video.js player is ready.");
      }
    );

    v_player.on("error", (error) => {
      console.error("Video.js player error:", error);
    });

    setPlayer(v_player);

    return () => {
      if (player) player.dispose();
    };
  }, []);

  useEffect(() => {
    if (player && videoType === "hls") {
      player.hlsQualitySelector({ displayCurrentQuality: true });
    }
  }, [player, videoType]);

  useEffect(() => {
    if (player) {
      player.src(videoSrc);
      player.poster(thumbnailURL);
      setCallFinishVideoAPI(false);
      setVidDuration(50000);
    }
  }, [videoId, videoURL, thumbnailURL, blobURL]);

  useEffect(() => {
    if (callFinishVideoAPI) {
      handleFinishVideo();
    }
  }, [callFinishVideoAPI]);

  useEffect(() => {
    if (videoURL) {
      fetchVideoAsBlob(videoURL);
    }
  }, [videoURL]);

  const fetchVideoAsBlob = (url) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "arraybuffer";
    xhr.onload = () => {
      const blob = new Blob([xhr.response]);
      const blobUrl = URL.createObjectURL(blob);
      setBlobURL(blobUrl);
    };
    xhr.send();
  };

  const handleOnLoadMetadata = (e) => {
    const hasVideoTrack =
      e.target.videoTracks && e.target.videoTracks.length > 0;

    if (!hasVideoTrack) {
      console.error("No video track found in the loaded metadata.");
    } else {
      setVidDuration(e.target.duration);
    }
  };

  const handleOnTimeUpdate = (e) => {
    if (e.target.currentTime >= vidDuration - 10) {
      setCallFinishVideoAPI(true);
    }
  };

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        onLoadedMetadata={handleOnLoadMetadata}
        onTimeUpdate={handleOnTimeUpdate}
        className="vidPlayer video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
      />
    </div>
  );
};

const getVideoSrc = (type = "hls", url = "") => {
  switch (type) {
    case "hls":
      return {
        src: url,
        type: "application/x-mpegURL",
        withCredentials: false,
      };
    case "mp4":
      return {
        src: url,
        type: "video/mp4",
      };
    case "webm":
      return {
        src: url,
        type: "video/webm",
      };
    default:
      return {};
  }
};
