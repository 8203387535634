import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState } from "recoil";

export const useFetchCourse = (queryParameter) => {
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noCourseData, setNoCourseData] = useState(false);
  const [courseData, setCourseData] = useState([]);

  // eslint-disable-next-line no-undef

  const type = process.env.REACT_APP_MODE;

  console.log(type, "ASdsa");

  useEffect(() => {
    const category = {
      Academic: type === "staging" ? 1 : 4,
      NonAcademic: type === "staging" ? 2 : 5,
      // Academic: 1,
      // NonAcademic: 2,
    };
    const fetchCourse = () => {
      setLoading(true);
      const course_type =
        queryParameter?.category_type === "academic"
          ? "Academic"
          : "NonAcademic";

      setError(false);

      publicAxios
        .get("/v2/client/course", {
          params: {
            ...queryParameter,
            category_id: category[course_type],
          },
        })
        .then((res) => {
          if (res.status === 200) {
            let resp = res.data;
            if (queryParameter.category && queryParameter.category !== "all") {
              resp = resp.data.filter(
                (course) =>
                  course.subcategory.id == Number(queryParameter.category)
              );

              if (resp.length) {
                setCourseData(resp);
                setTotalPages(resp?.total_page ?? 1);
              } else {
                setCourseData(resp);
                setNoCourseData(true);
              }
            }

            if (resp?.data?.length > 0) {
              sessionStorage.setItem(
                "courseDetails",
                JSON.stringify(resp.data)
              );
              setTotalPages(resp.total_page);
              queryParameter?.page === 1
                ? setCourseData(resp.data)
                : setCourseData((courseData) => [...courseData, ...resp.data]);
            } else if (resp.length === 0 && queryParameter?.page === 1) {
              setCourseData(resp);
              setNoCourseData(true);
            }
          }
        })
        .catch((e) => {
          setError(true);
          setCourseData([]);
          setNoCourseData(true);
          console.info("error", e);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchCourse();
  }, [queryParameter]);
  return [courseData, totalPages, loading, noCourseData, error];
};
