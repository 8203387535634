import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function AdminProtected({ auth, children }) {
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  console.log("currentPath", currentPath);

  if (!auth) {
    return (
      <Navigate to={`/u1NzT9Pq2s5/admin/login?next=${currentPath}`} replace />
    );
  }

  return <Outlet />;
}

export default React.memo(AdminProtected);
