import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./errorBoundry";
import { HelmetProvider } from "react-helmet-async";

if (process.env.REACT_APP_ENV === "production") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <RecoilRoot>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </RecoilRoot>
  </HelmetProvider>
);

reportWebVitals();
