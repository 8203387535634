export const BlogArrow = () => {
  return (
    <>
      <div className="curveArrow bottomRight">
        <svg
          width="215"
          height="240"
          viewBox="0 0 215 240"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2_309)">
            <path
              d="M186.51 4.69185C190.556 -1.59276 198.657 -0.953816 200.952 6.53534C205.314 20.7865 208.383 35.4014 210.122 50.2029C180.641 141.476 110.688 207.109 17.7621 230.948C14.9534 231.598 12.9727 229.44 14.5237 227.513C20.2166 220.561 25.4335 213.233 30.1389 205.579C30.5689 204.941 30.8501 204.215 30.962 203.454C31.0738 202.693 31.0134 201.916 30.7851 201.181C30.5568 200.446 30.1665 199.772 29.643 199.208C29.1194 198.644 28.4759 198.205 27.7599 197.923C20.0132 194.483 12.5128 190.515 5.31186 186.045C3.39403 184.882 4.07523 182.169 6.35987 181.645C81.6688 161.88 137.244 109.739 161.599 35.8112C171.031 26.2376 178.923 15.7423 186.51 4.69185Z"
              fill="#C42730"
              fillOpacity="0.5"
              shapeRendering="crispEdges"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_2_309"
              x="0.194336"
              y="0.406494"
              width="213.927"
              height="238.66"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_309" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2_309"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="curveArrow bottomLeft">
        <svg
          width="232"
          height="207"
          viewBox="0 0 232 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M226.769 182.831C233.057 186.874 232.418 194.97 224.925 197.264C210.666 201.624 196.043 204.691 181.234 206.429C89.9112 176.923 24.2437 107.049 0.391312 14.1622C-0.258453 11.355 1.90039 9.37539 3.8287 10.9256C10.7841 16.6154 18.1158 21.8295 25.7737 26.5324C26.4122 26.9622 27.139 27.2433 27.9006 27.355C28.6622 27.4668 29.4391 27.4064 30.1743 27.1783C30.9094 26.9501 31.5839 26.56 32.1482 26.0367C32.7124 25.5135 33.1521 24.8703 33.4346 24.1547C36.8775 16.4264 40.8483 8.94404 45.3189 1.76054C46.4822 -0.156262 49.1965 0.52457 49.7205 2.80798C69.5067 78.0661 121.665 133.612 195.643 157.954C205.212 167.349 215.713 175.237 226.769 182.831Z"
            fill="#0373BA"
            fillOpacity="0.5"
          />
        </svg>
      </div>
      <div className="curveArrow topLeft">
        <svg
          width="207"
          height="232"
          viewBox="0 0 207 232"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.5221 227.245C20.4768 233.53 12.3759 232.891 10.0808 225.402C5.71881 211.151 2.64977 196.536 0.910799 181.734C30.4328 90.4713 100.345 24.8286 193.281 0.988979C196.089 0.339569 198.07 2.49728 196.519 4.42456C190.826 11.3762 185.609 18.704 180.904 26.3578C180.474 26.996 180.193 27.7223 180.081 28.4835C179.969 29.2447 180.029 30.0212 180.258 30.756C180.486 31.4907 180.876 32.1649 181.4 32.7288C181.923 33.2928 182.567 33.7322 183.283 34.0146C191.016 37.4625 198.502 41.4381 205.689 45.9135C207.607 47.0761 206.926 49.789 204.641 50.3127C129.343 70.0882 73.7674 122.219 49.412 196.157C40.0115 205.71 32.1201 216.205 24.5221 227.245Z"
            fill="#FCB03E"
          />
        </svg>
      </div>
      <div className="curveArrow topRight">
        <svg
          width="232"
          height="207"
          viewBox="0 0 232 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.27403 24.117C-1.01393 20.0739 -0.374664 11.9772 7.1185 9.68336C21.3774 5.32376 36.0001 2.25635 50.8094 0.518313C142.121 29.9826 207.799 99.8989 231.652 192.775C232.301 195.582 230.142 197.562 228.214 196.011C221.259 190.322 213.927 185.108 206.269 180.405C205.631 179.975 204.904 179.694 204.142 179.582C203.381 179.47 202.604 179.531 201.869 179.759C201.134 179.987 200.459 180.377 199.895 180.9C199.33 181.424 198.891 182.067 198.608 182.782C195.16 190.526 191.182 198.022 186.703 205.218C185.54 207.135 182.825 206.454 182.301 204.171C162.547 128.902 110.378 73.3569 36.3995 49.0145C26.8208 39.5876 16.3199 31.7004 5.27403 24.117Z"
            fill="#156C3C"
            fillOpacity="0.5"
          />
        </svg>
      </div>
    </>
  );
};
