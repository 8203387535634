import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";

import privateAxios from "../../../axios/privateAxios";
import { authAtom } from "../../../state/atoms/authAtom";
import { courseAtom } from "../../../state/atoms/Course/courseAtom";
import { loginPopupAtom } from "../../../state/atoms/loginPopupAtom";

export const CourseThumbnailShortDetail = ({
  courseType = "academic",
  selectedCourseDetail,
}) => {
  const buttonColor = courseType === "academic" ? "secondary" : "primary";
  const color = {
    primary: "#0474ba",
    secondary: "#C4262F",
  };
  let featureImage = {
    1: "/images/courses/durationIcon.svg",
    3: "/images/courses/durationIcon.svg",
    9: "/images/courses/durationIcon.svg",
    2: "/images/courses/durationIcon.svg",
    4: "/images/courses/durationIcon.svg",
    5: "/images/courses/currentaffairs.svg",
    6: "/images/courses/extras.svg",
    8: "/images/courses/liveclasses.svg",
    10: "/images/courses/testSeriesIcon.svg",
    7: "/images/courses/costIcon.svg",
  };
  const courseDetail = useRecoilValue(courseAtom);
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const [, setLoginPopup] = useRecoilState(loginPopupAtom);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isAddToCart, setIsAddToCart] = useState(false);
  // const [saveCourse, setSaveCourse] = useState(false);
  const [validity, setValidity] = useState();
  const instructor = courseDetail?.instructor;
  const feature = selectedCourseDetail?.features;
  const defaultThumbnail = "/images/courses/thumbnail.png";
  useEffect(() => {
    const validateDate = (validity) => {
      const v_date = new Date(validity);
      const c_date = new Date();
      if (v_date && c_date) {
        if ((v_date.getTime() - c_date.getTime()) / (24 * 60 * 60 * 1000) > 0)
          return true;
      }
      return false;
    };
    // courseDetail?.saved && courseDetail?.saved === true && setSaveCourse(true);
    courseDetail?.validity &&
      courseDetail?.validity !== "" &&
      validateDate(courseDetail?.validity) &&
      setValidity(new Date(courseDetail?.validity));
  }, [courseDetail]);
  useEffect(() => {
    const loadMyCourse = () => {
      privateAxios
        .get("/mycourses", {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          if (res?.data.length) {
            const isSubscribe = res.data.filter(
              (ele) => ele.id == courseDetail[0].course
            );
            isSubscribe.length ? setIsSubscribe(true) : setIsSubscribe(false);
          }
        })
        .catch((e) => console.log(e));
    };

    auth && loadMyCourse();
    const loadCart = () => {
      privateAxios
        .get("/cart", {
          headers: {
            Authorization: "Bearer " + auth?.access,
          },
        })
        .then((res) => {
          if (res.data.product_quantity) {
            const isAddToCart = res.data.product_quantity.filter(
              (ele) => ele.course.id == courseDetail[0].course
            );
            isAddToCart.length ? setIsAddToCart(true) : setIsAddToCart(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    auth && loadCart();
  }, [courseDetail[0]]);

  const handleBuyNow = () => {
    //  addTocart();
    const addTocart = (course_id) => {
      const request_body = {
        type: "COURSE",
        course_id: course_id,
      };

      privateAxios
        .post("/cart", request_body, {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          navigate("/cart");
        })
        .catch((e) => console.log(e));
    };
    // check the status of logged in
    if (!auth) {
      setLoginPopup(true);
    } else courseDetail[0]?.course && addTocart(courseDetail[0].course);
  };

  // const handleSaveCourse = () => {
  //   const saveCourse = (id) => {
  //     privateAxios
  //       .post(`/course/${id}/save`)
  //       .then((res) => {
  //         setSaveCourse(true);
  //         // console.log(res.data);
  //       })
  //       .catch((e) => {
  //         console.log("error", e);
  //       });
  //   };

  //   // check the status of logged in
  //   if (!auth) {
  //     setLoginPopup(true);
  //   } else courseDetail?.id && saveCourse(courseDetail.id);
  // };

  return (
    <div className="courseDetailWrapper">
      <div>
        <img
          className="fluid-image"
          src={
            selectedCourseDetail?.resource?.url
              ? selectedCourseDetail?.resource?.url
              : defaultThumbnail
          }
          alt={selectedCourseDetail?.title}
        />
      </div>
      <div>
        <div className="sellingPrice display_medium">
          <span>{selectedCourseDetail?.title}</span>
        </div>
        {selectedCourseDetail?.language && (
          <div className="medium title_medium">
            {selectedCourseDetail?.language}
          </div>
        )}

        <div className="priceTag">
          <div className="sellingPrice display_medium">
            RS <span>{selectedCourseDetail?.price}</span>
          </div>
          {selectedCourseDetail?.mrp &&
            selectedCourseDetail?.mrp > selectedCourseDetail?.price && (
              <div
                className="discount display_small"
                style={{ color: color[buttonColor] }}
              >
                Rs. <span>{selectedCourseDetail?.mrp}</span>
              </div>
            )}
        </div>

        {validity && (
          <div
            className="valid title_medium"
            style={{ color: color[buttonColor] }}
          >
            Discount valid till {validity.toDateString()} only.
          </div>
        )}
        <div className="instructor">
          <ul className="title_large">
            {instructor &&
              instructor.slice(0, 2).map((item) => (
                <li key={`instrutor-top-${item.id}`}>
                  <div>
                    <img
                      src="/images/courses/profile.svg"
                      alt="profile"
                      className="fluid-image"
                    />
                  </div>
                  <div>{item.name}</div>
                </li>
              ))}
          </ul>
        </div>
        <div className="buy">
          {isSubscribe ? (
            <>
              <Button variant="contained" color={buttonColor} disabled={true}>
                Enrolled
              </Button>
              <Button
                variant="outlined"
                color={buttonColor}
                onClick={() => navigate("/dashboard/courses")}
              >
                Continue
              </Button>
            </>
          ) : isAddToCart ? (
            <Button
              variant="outlined"
              color={buttonColor}
              onClick={() => {
                navigate("/cart");
              }}
            >
              Checkout
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color={buttonColor}
                onClick={handleBuyNow}
              >
                Buy Now
              </Button>
              <Button
                variant="outlined"
                color={buttonColor}
                onClick={handleBuyNow}
              >
                Add to Cart
              </Button>
            </>
          )}
        </div>
        <div className="extra title_medium">
          {feature &&
            feature.map((item) => (
              <div className="item" key={`feature-item-${item.id}`}>
                <div>
                  <img
                    src={featureImage[item.id]}
                    alt={item.name}
                    className="fluid-image"
                  />
                </div>
                <div>{item.name}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
