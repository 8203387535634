import { atom } from "recoil";

let user = JSON.parse(localStorage.getItem("userData"));
const userAtom = atom({
  key: "userAtom",
  default: {
    id: user?.id || "",
    phone: user?.phone || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email_verified: user?.email_verified || "",
    address: user?.address || "",
    city: user?.city || "",
    country: user?.country || "",
    state: user?.state || "",
    profile_image: {
      id: null,
      value: null,
      url: "",
    },
  },
});

export { userAtom };
