import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import Loader from "../Tools/Loader";
import { catchError } from "../../helper/useError";
import { toastAtom } from "../../state/atoms/toastAtom";

export const AnalyticsTable = ({
  dateRange,
  selectedStatus,
  setCsvData,
  data,
}) => {
  const auth = useRecoilValue(authAtom);

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 25 });
  // const [data, setData] = useState([]);
  const [, setShowToast] = useRecoilState(toastAtom);

  const getInfo = async (pagination, dateRange, selectedStatus) => {
    const { start_date, end_date } = dateRange;
    await publicAxios
      .get("/allorders", {
        params: {
          page: pagination.page,
          limit: pagination.pageSize,
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD"),
          status: selectedStatus,
        },
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // setData(res.data);
          setCsvData([
            [
              "id",
              "name",
              "email",
              "number",
              "billing_detail",
              "sub_total",
              "tax",
              "total",
              "status",
              "order_placed",
              "payment_id",
            ],

            ...res.data.map((ele) => [
              ele.id,
              ele.name,
              ele.email,
              ele.number,
              ele.billing_detail,
              ele.sub_total,
              ele.tax,
              ele.total,
              ele.status,
              ele.order_placed,
              ele.payment_id,
            ]),
          ]);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          const err = catchError(e);
          setShowToast({
            status: true,
            type: "error",
            errorMessage: err,
          });
          setLoading(false);
        }
        console.info("error", e);
        setLoading(false);
      });
  };
  useEffect(() => {
    getInfo(pagination, dateRange, selectedStatus);
  }, [pagination, dateRange, selectedStatus]);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Customer Name", width: 150 }, // Assuming 'name' is present in your data
    { field: "email", headerName: "Email", width: 180 },
    { field: "number", headerName: "Phone Number", width: 150 },
    { field: "billing_detail", headerName: "Billing Detail", width: 150 },
    { field: "sub_total", headerName: "Sub Total", width: 100 },
    { field: "tax", headerName: "Tax", width: 80 },
    { field: "total", headerName: "Total", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "order_placed",
      headerName: "Order Placed",
      width: 150,
    },
    { field: "payment_id", headerName: "Payment ID", width: 200 },
  ];

  return (
    <Box sx={{ height: "max-content", width: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: {
              paginationModel: pagination,
            },
          }}
          onPaginationModelChange={(page) => setPagination(page)}
        />
      )}
    </Box>
  );
};
