import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Stack, Typography } from "@mui/material";

import { useEffect } from "react";
import publicAxios from "../../../axios/publicAxios";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import AddTestSeriesForm1 from "../../../components/AdminTest/AddTestSeriesForm1";
import AddTestSeriesForm2 from "../../../components/AdminTest/AddTestSeriesForm2";
import AddTestSeriesForm3 from "../../../components/AdminTest/AddTestSeriesForm3";
import { RESOURCE_TYPE } from "../../../components/AdminCourse/ChapterResourceItem";
import Loader from "../../../components/Tools/Loader";
import { formatMeridiem } from "@mui/x-date-pickers/internals/utils/date-utils";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

let testSeriesId;
export const AddTestSeries = () => {
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const totalPages = 3;

  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState();
  const [allCategories, setAllCategories] = useState(null);
  const [allFeatures, setAllFeatures] = useState([]);
  const [faqs, setFAQs] = useState([]);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    title: {
      value: "",
      error: false,
      message: "Title is required",
    },
    thumbnail: {
      value: null,
      error: false,
      message: null,
      id: null,
      url: "/images/admin/addCourseImage.png",
    },
    about: {
      value: "",
      error: false,
      message: null,
    },
    mrp: {
      value: 0,
      error: false,
      message: "MRP is required",
    },
    price: {
      value: 0,
      error: false,
      message: null,
    },
    // language: {
    //   value: "",
    //   error: false,
    //   message: null,
    // },

    startDate: {
      value: dayjs().format("YYYY-MM-DD"),
      error: false,
      message: "Start date is required",
    },
    features: {
      value: [],
      error: false,
      message: null,
    },
    // category: {
    //   value: "",
    //   error: false,
    //   message: "Category is required",
    // },
    // subcategory: {
    //   value: "",
    //   error: false,
    //   message: "Sub-Category is required",
    // },
    // product: {
    //   value: "",
    //   error: false,
    //   message: "Product code is required",
    // },
  });
  // console.log(formData);
  const [chapters, setChapters] = useState([]);

  function onNameConfirm(name) {
    //  console.log(name);
    const request_body = {
      course_id: testSeriesId,
      name: name,
    };
    publicAxios
      .post(`v2/chapters`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        setChapters((chapters) => [
          ...chapters,
          { id: res.data.id, resources: [], test: [], document: [] },
        ]);
        console.log(chapters);
      })
      .catch((e) => {
        //   console.log(e);
      });
  }

  function addVideos(chapterId, videos) {
    setChapters((chapters) => {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = [
        ...selectedChapter.resources,
        ...videos.map((video) => ({
          type: RESOURCE_TYPE.VIDEO,
          id: video.id,
          file: video,
        })),
      ];
      return newChapters;
    });
    console.log(chapters);
  }

  function addTests(chapterId, tests) {
    console.log(tests);
    setChapters((chapters) => {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = [
        ...selectedChapter.resources,
        ...tests.files.map((test) => ({
          type: RESOURCE_TYPE.TEST,
          id: test.id,
          file: test,
        })),
      ];

      selectedChapter.test = [
        ...selectedChapter.test,
        {
          testName: tests.testName,
          testDescription: tests.testDescription,
          testdocumentFile: tests.testdocumentFile,
        },
      ];
      return newChapters;
    });
    console.log(chapters);
  }

  const uploadResource = (id, type, file, resourceId) => {
    console.log(file);
    const fileData = new FormData();
    //  type === 'video' && fileData.append('name',file.name)
    fileData.append("file", file);
    fileData.append("type", type);
    id && fileData.append("chapter_id", id);
    return publicAxios
      .post(
        `v2/${resourceId ? "resource/" + resourceId : "resources"}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  function addNotes(chapterId, notes) {
    console.log(notes);
    setChapters((chapters) => {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = [
        ...selectedChapter.resources,
        ...notes.files.map((note) => ({
          type: RESOURCE_TYPE.DOCUMENT,
          id: note.id,
          file: note,
        })),
      ];
      selectedChapter.document = [
        ...selectedChapter.document,
        {
          documentName: notes.documentName,
          documentDescription: notes.documentDescription,
          documentFile: notes.documentFile,
        },
      ];
      return newChapters;
    });
  }

  async function onDelete(chapterId, resourceId, btnType) {
    console.log(chapterId, resourceId, btnType);
    console.log(chapters);
    setLoading(true);
    if (resourceId) {
      // topic set delete individual
    } else if (chapterId) {
      // topic set delete
      return publicAxios
        .delete(`v2/topic/${chapterId}`, {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          setLoading(false);
          return res;
          // console.log(chapters);
        })
        .catch((e) => {
          //   console.log(e);
        });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    });
  }

  function handleStartDateChange(date) {
    setFormData((formData) => ({
      ...formData,
      startDate: {
        ...formData.startDate,
        value: date.format("YYYY-MM-DD"),
      },
    }));
  }

  function handleThumbnailImage(event) {
    //console.log(event.target.files);
    const resourceId = formData.thumbnail.id;
    uploadResource("", "image", event.target.files[0], resourceId).then(
      (res) => {
        setFormData((formData) => ({
          ...formData,
          thumbnail: {
            ...formData.thumbnail,
            value: event.target.files[0],
            id: res.data.id,
          },
        }));
      }
    );
  }

  // function removeInstructor(instructor) {
  //   setFormData((formData) => ({
  //     ...formData,
  //     instructors: {
  //       ...formData,
  //       value: formData.instructors.value.filter((id) => id !== instructor.id),
  //     },
  //   }));
  // }
  const [checkedState, setCheckedState] = useState({});

  function handleFeatureChange(event) {
    console.log(event);
    const { name, checked } = event.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
    setFormData((formData) => ({
      ...formData,
      features: {
        ...formData.features,
        value: checked
          ? [...formData.features.value, name]
          : [...formData.features.value.filter((item) => item !== name)],
      },
    }));
  }

  const getALLInstructors = async () => {
    await publicAxios
      .get("/v2/faculty", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          setInstructors(
            res.data.map((data) => ({ id: data.id, name: data.name }))
          );
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setInstructors([]);
          setLoading(false);
        }
        console.info("error", e);
      });
  };

  const getCategoryInfo = async () => {
    await publicAxios
      .get("/v2/category/all", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllCategories(res.data);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setAllCategories(null);
        }
        console.info("error", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function onFAQAdded(faqModel) {
    if (faqModel.id) {
      const newFAQs = [...faqs];
      const idx = newFAQs.findIndex((faq) => faq.id === faqModel.id);
      if (idx !== -1) newFAQs[idx] = faqModel;
      const res = await createFaq(
        faqModel.question,
        faqModel.answer,
        faqModel.id
      );
      if (res.data) {
        setFAQs(newFAQs);
      }
    } else {
      const res = await createFaq(faqModel.question, faqModel.answer, "");
      if (res.data) {
        setFAQs([...faqs, { id: res.data.id, ...faqModel }]);
      }
    }
  }

  const createFaq = (question, answer, id) => {
    const request_body = {
      test_series_id: testSeriesId,
      question: question,
      answer: answer,
      type: "TEST_SERIES",
    };
    return publicAxios
      .post(`v2/${id ? "faq/" + id : "faqs"}`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        //   console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //   console.log(e);
      });
  };

  const submitAllData = () => {
    const request_body = {
      test_series_id: testSeriesId,
    };
    return publicAxios
      .post(`/v2/testseries/submit`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        //   console.log(res);
        testSeriesId = "";
        setLoading(false);
        navigate("/u1NzT9Pq2s5/admin/test/allTestSeries");
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        setLoading(false);
        //   console.log(e);
      });
  };

  function deleteFAQ(faqId) {
    setFAQs((faqs) => faqs.filter((faq) => faq.id !== faqId));
  }

  async function getAllFeatures() {
    const response = await publicAxios("/v2/features?type=TEST_SERIES", {
      headers: {
        Authorization: "Bearer " + auth.access,
      },
    });
    if (response.status === 200) {
      setAllFeatures(response.data);
    } else {
      setAllFeatures([]);
    }
  }

  const createCourse = () => {
    setLoading(true);
    newCourse();
    // navigate("/addCourse");
  };
  const newCourse = () => {
    const request_body = {
      name: formData.title.value,
      resource: formData.thumbnail.id,
      about: formData.about.value,
      sales_price: formData.mrp.value,
      discount_price: formData.price.value,
      publish: false,
      startDate: formData.startDate.value,
      features: testSeriesId
        ? formData.features.value?.id
        : formData.features.value,
    };
    publicAxios
      .post(
        `/v2/testseries${testSeriesId ? "/" + testSeriesId : ""}`,
        request_body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((res) => {
        testSeriesId = res.data.id;
        setLoading(false);
        setPage((page) => page + 1);
      })
      .catch((e) => {
        setLoading(false);
        //  console.log(e);
      });
  };

  const path = window.location.pathname;
  const pathSegments = path.split("/"); // Split the path by '/'
  const variable = pathSegments[4]; // Accessing the third segment, change the index as needed
  useEffect(() => {
    if (variable) {
      testSeriesId = variable;
      const apiData = JSON.parse(sessionStorage.getItem("apiData"));
      const data = apiData.filter((ele) => ele.id == testSeriesId);
      console.log({data});
      formData.title.value = data?.[0]?.name;
      formData.thumbnail.url = data?.[0]?.resource?.url
        ? data?.[0]?.resource?.url
        : "/images/admin/addCourseImage.png";
      formData.thumbnail.id = data?.[0]?.resource?.id;
      formData.about.value = data?.[0]?.about;
      formData.mrp.value = data?.[0]?.sales_price;
      formData.price.value = data?.[0]?.price;
      formData.startDate.value = data?.[0]?.startDate;
      formData.features.value = data?.[0]?.features;
      const newFormData = { ...formData };
      setFormData(newFormData);
      
    }
  }, [testSeriesId]);
  const goToNextPage = () => {
    // if (page === totalPages) {
    //   createCourse();
    //   navigate("/admin/dashboard/courses")
    // } else {

    // }

    if (page === 1) {
      //  console.log(page);
      createCourse();
    } else if (page === 2) {
      // setLoading(true);
      // chapterSubmited();
      //   console.log(page);
      setPage((page) => page + 1);
    } else if (page === 3) {
      //   console.log(page);
      setLoading(true);
      submitAllData();
    }
  };

  const goToPrevPage = () => {
    setPage((page) => page - 1);
  };
  //console.log(testSeriesId);
  useEffect(() => {
    setLoading(true);
    getALLInstructors();
    getCategoryInfo();
    getAllFeatures();
    setLoading(false);
  }, []);
  console.log(page);

  let addCourseForm;
  if (page === 1) {
    addCourseForm = (
      <AddTestSeriesForm1
        checkedState={checkedState}
        formData={formData}
        handleChange={handleChange}
        handleFeatureChange={handleFeatureChange}
        handleStartDateChange={handleStartDateChange}
        handleThumbnailImage={handleThumbnailImage}
        createCourse={goToNextPage}
        allCategories={allCategories}
        allFeatures={allFeatures}
        instructors={instructors}
        // removeInstructor={removeInstructor}
      />
    );
  } else if (page === 2) {
    addCourseForm = (
      <AddTestSeriesForm2
        testSeriesImage={
          formData.thumbnail.value
            ? URL.createObjectURL(formData.thumbnail.value)
            : formData.thumbnail?.url
        }
        courseName={formData.title.value}
        chapters={chapters}
        onNameConfirm={onNameConfirm}
        addVideos={addVideos}
        addTests={addTests}
        addNotes={addNotes}
        onDelete={onDelete}
        uploadResource={uploadResource}
        testSeriesId={testSeriesId}
      />
    );
  } else if (page === 3) {
    addCourseForm = (
      <AddTestSeriesForm3
        courseImage={
          formData.thumbnail.value
            ? URL.createObjectURL(formData.thumbnail.value)
            : formData.thumbnail?.url
        }
        courseName={formData.title.value}
        faqs={faqs}
        onFAQAdded={onFAQAdded}
        deleteFAQ={deleteFAQ}
      />
    );
  }

  return (
    <div className="main-user-dash">
      <Stack sx={{ width: "90%" }}>
        <div className="all-user-strip">
          <Typography>
            Test Series Details {page}/{totalPages}
          </Typography>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                onClick={goToPrevPage}
                disabled={page === 1}
              >
                Previous
              </Button>
              <Button variant="outlined" onClick={goToNextPage}>
                {page === totalPages ? "Submit" : "Next"}
              </Button>
            </Stack>
          </div>
        </div>
        {loading && <Loader />}
        <div className="UserPaper">{addCourseForm}</div>
      </Stack>
    </div>
  );
};
