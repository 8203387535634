import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Dialog, IconButton, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import publicAxios from "../../axios/publicAxios";
import { useState } from "react";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Tests } from "./Tests";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const TestLibrary = (props) => {
  const auth = useRecoilValue(authAtom);
  console.log("TEST SERIES NAME: ", props.name);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setRow] = useState();

  const handleClickOpen = (params) => {
    setOpen(true);
    setRow(params.row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const getInfo = async () => {
    try {
      const res = await publicAxios.get("/v2/testset?publish=false", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      if (res.status === 200) {
        console.log(res.data);
        setData(res.data); // Store the API response data in state
        setLoading(false);
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setData([]); // Set an empty array if there's an error
        setLoading(false);
      }
      console.error("Error:", e);
    }
  };
  useEffect(() => {
    setLoading(true);
    getInfo();
    setOpen(false);
    setLoading(false);
  }, [props.fetchTest]);
  // publicAxios.post(url, body).then((response) => {
  //   console.log("API WORKED?: ", response)
  // }).catch((e) => console.log(e);)
  const columns = [
    {
      field: "No",
      width: 10,
      headerName: "",
    },
    {
      field: "title",
      headerName: "",
      width: 300,
      renderCell: (params) => {
        const currentRow = params.row;
        console.log(currentRow);
        // console.log("CURRENT ROW: ", currentRow);
        return (
          <Button
            id={currentRow.id}
            onClick={() => handleClickOpen(params)}
            style={{ textDecoration: "none" }}
          >
            <Stack sx={{ textAlign: "start" }}>
              <span style={{ fontWeight: "bold" }}>{currentRow?.name}</span>
              {/* <span style={{ color: "#666", fontSize: "0.6rem" }}>
                UPSC FULL TEST PAPER, UPSC PRELIMS, UPSC 2022
              </span> */}
            </Stack>
          </Button>
        );
      },
    },
    {
      field: "language",
      headerName: "",
      width: 150,
    },
    {
      field: "draft",
      headerName: "",
      width: 100,

      renderCell: (params) => {
        const currentRow = params.row;
        return (
          currentRow.publish && (
            <Button
              variant="contained"
              sx={{ background: "#DDD", color: "#666" }}
              disabled
            >
              Draft
            </Button>
          )
        );
      },
    },
    {
      field: "duration",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack sx={{ textAlign: "start" }}>
            <span>{currentRow?.duration} Minutes</span>
            {/* <span style={{ color: "#666", fontSize: "0.6rem" }}>
                UPSC FULL TEST PAPER, UPSC PRELIMS, UPSC 2022
              </span> */}
          </Stack>
        );
      },
    },
    {
      field: "price",
      headerName: "",
      width: 100,
    },
  ];
  // Otherwise filter will be applied on fields such as the hidden column id

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {data ? (
        <>
          {/* {data.map((test)=> {
          return (<>
          {test.title} <br />
          </>)})} */}
          <DataGrid
            rows={data}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            checkboxSelection
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />

          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            // TransitionComponent={Transition}
          >
            <Tests Rowdata={selectedRow} />
          </Dialog>
        </>
      ) : (
        <>Loading</>
      )}
    </Box>
  );
};
