import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Container } from "@mui/material";
import { toastAtom } from "../../state/atoms/toastAtom";
import { courseAtom } from "../../state/atoms/Course/courseAtom";
import { Coursesection } from "../../components/Learning/Coursesection/Coursesection";
import { LectureDetail } from "../../components/Learning/LearningDetail/LectureDetail";
import { LectureHeader } from "../../components/Learning/LearningHeader/LearningHeader";
import Loader from "../../components/Tools/Loader";
import privateAxios from "../../axios/privateAxios";
import publicAxios from "../../axios/publicAxios";
import { authAtom } from "../../state/atoms/authAtom";

import "./Learning.scss";
import { catchError } from "../../helper/useError";

export const Learning = () => {
  const { id } = useParams();
  const [, setShowToast] = useRecoilState(toastAtom);
  const [resource, setResource] = useState([]);
  const auth = useRecoilValue(authAtom);

  useEffect(() => {
    const loadCourse = (courseId) => {
      publicAxios
        .get(`/study/mycourses/${courseId}/chapters`, {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setResource((prevRes) => [...prevRes, ...res.data]);
          }
        })
        .catch((e) => {
          console.error("Error loading course", e);
          const err = catchError(e);
          setShowToast({
            status: true,
            type: "error",
            errorMessage: err,
          });
        });
    };

    id && loadCourse(id);
  }, [id]);

  const handleFinishVideoMain = (lectureDetailData) => {
    console.log("lectureDetailData", lectureDetailData);
    const updateLectureStatus = () => {
      const req_body = {
        course_id: lectureDetailData?.course_id,
        chapter_id: lectureDetailData?.coursesection_id,
        resource_id: lectureDetailData?.lecture?.id,
      };
      privateAxios
        .post("/study/mycourses/resource/progress", req_body, {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          setResource((prevState) =>
            prevState.map((item) => {
              if (item.id === lectureDetailData?.coursesection_id) {
                const updatedResources = item.resources.map((resource) =>
                  resource.id === lectureDetailData?.lecture?.id
                    ? { ...resource, complete_status: true }
                    : resource
                );
                return { ...item, resources: updatedResources };
              } else {
                return item;
              }
            })
          );
        })
        .catch((e) => console.log(e));
    };
    lectureDetailData?.lecture && updateLectureStatus();
  };

  const uniqueData = [
    ...new Map(resource.map((obj) => [obj.id, obj])).values(),
  ];

  console.log("resource resource resource", resource);
  return (
    <Container
      maxWidth="false"
      sx={{ padding: "0 !important" }}
      className="learningContainer"
    >
      {!uniqueData.length ? (
        <Loader />
      ) : (
        <>
          <LectureHeader course={uniqueData[0].chapter} />
          <div className="grid">
            <LectureDetail
              course={resource}
              handleFinishVideoMain={handleFinishVideoMain}
            />
            <Coursesection coursesection={[]} course={resource} courseId={id} />
          </div>
        </>
      )}
    </Container>
  );
};
