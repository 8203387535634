import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import Loader from "../Tools/Loader";

export const Table = () => {
  const auth = useRecoilValue(authAtom);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const getInfo = async () => {
    try {
      const res = await publicAxios.get("allorders", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      if (res.status === 200) {
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setData([]);
        setLoading(false);
      }
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getInfo();
  }, []);

  const handleRowSelection = (selectedRowIds) => {
    setSelectedRows(Object.keys(selectedRowIds));
  };

  const handleRowClick = (row) => {
    const selectedIndex = selectedRows.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  // const columns = [
  //   {
  //     field: "rowNumber",
  //     headerName: "No",
  //     width: 70,
  //     renderCell: (params) => {
  //       const index = data.findIndex((row) => row.id === params.row.id);
  //       const count = index !== -1 ? index + 1 : "NA";
  //       return count;
  //     },
  //   },
  //   { field: "id", headerName: "Order Id.", width: 120 },
  //   { field: "name", headerName: "Name", width: 170 },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     width: 100,
  //   },
  //   {
  //     field: "state",
  //     headerName: "State",
  //     width: 100,
  //   },
  //   {
  //     field: "coupon_code",
  //     headerName: "Coupon Code",
  //     width: 100,
  //   },
  //   {
  //     field: "coupon_value",
  //     headerName: "Coupon Value",
  //     width: 100,
  //   },
  //   {
  //     field: "number",
  //     headerName: "Mobile No.",
  //     width: 120,
  //   },
  //   {
  //     field: "order_placed",
  //     headerName: "Order Placed",
  //     width: 120,
  //   },
  //   {
  //     field: "payment_id",
  //     headerName: "Payment Id",
  //     width: 120,
  //   },
  //   {
  //     field: "tax",
  //     headerName: "Tax",
  //     width: 120,
  //   },
  //   {
  //     field: "sub_total",
  //     headerName: "Sub Total",
  //     width: 120,
  //   },
  //   {
  //     field: "total",
  //     headerName: "Total",
  //     width: 120,
  //   },
  //   {
  //     field: "action",
  //     headerName: "Action",
  //     width: 150,
  //     renderCell: (params) => {
  //       // const currentRow = params.row;
  //       return (
  //         <Stack direction="row" spacing={-3}>
  //           <Button
  //             onClick={() => {
  //               alert("You sure want to Download this user?");
  //             }}
  //           >
  //             <img src="/images/admin/download.png" alt="" />
  //           </Button>
  //         </Stack>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    {
      field: "rowNumber",
      headerName: "No",
      width: 70,
      renderCell: (params) => {
        const index = data?.findIndex((row) => row.id === params.row.id);
        const count = index !== -1 ? index + 1 : "NA";
        return count;
      },
    },
    { field: "id", headerName: "Order Id.", width: 120 },
    { field: "name", headerName: "Name", width: 170 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => params.row.status || "NA",
    },
    {
      field: "state",
      headerName: "State",
      width: 100,
      renderCell: (params) => params.row.state || "NA",
    },
    {
      field: "coupon_code",
      headerName: "Coupon Code",
      width: 100,
      renderCell: (params) => params.row.coupon_code || "NA",
    },
    {
      field: "coupon_value",
      headerName: "Coupon Value",
      width: 100,
      renderCell: (params) => params.row.coupon_value || "NA",
    },
    {
      field: "number",
      headerName: "Mobile No.",
      width: 120,
      renderCell: (params) => params.row.number || "NA",
    },
    {
      field: "order_placed",
      headerName: "Order Placed",
      width: 120,
      renderCell: (params) => params.row.order_placed || "NA",
    },
    {
      field: "payment_id",
      headerName: "Payment Id",
      width: 120,
      renderCell: (params) => params.row.payment_id || "NA",
    },
    {
      field: "tax",
      headerName: "Tax",
      width: 120,
      renderCell: (params) => params.row.tax || "NA",
    },
    {
      field: "sub_total",
      headerName: "Sub Total",
      width: 120,
      renderCell: (params) => params.row.sub_total || "NA",
    },
    {
      field: "total",
      headerName: "Total",
      width: 120,
      renderCell: (params) => params.row.total || "NA",
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                alert("You sure want to Download this user?");
              }}
            >
              <img src="/images/admin/download.png" alt="" />
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {data.length > 0 ? (
            <DataGrid
              rows={data}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              checkboxSelection
              selected={selectedRows}
              onSelectionModelChange={handleRowSelection}
              onRowClick={(params) => handleRowClick(params.row)}
            />
          ) : (
            <p>No data available</p>
          )}
        </>
      )}
    </Box>
  );
};
