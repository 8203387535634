import {
  Button,
  Stack,
  Grid,
  Divider,
  Typography,
  Paper,
  Box,
  styled,
  Modal,
  TextField,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

function CreateTestTopic(prop) {
  const auth = useRecoilValue(authAtom);
  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    testName: "",
    testSets: [],
    testSeriesId: "",
  });
  const getInfo = async () => {
    try {
      const res = await publicAxios.get("/v2/testset?publish=true", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      if (res.status === 200) {
        console.log(res.data);
        setData(res.data); // Store the API response data in state
        // setLoading(false);
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        setData([]); // Set an empty array if there's an error
        //  setLoading(false);
      }
      console.error("Error:", e);
    }
  };

  useEffect(() => {
    if (prop.modalOpen) {
      getInfo();
    }
  }, [prop.modalOpen]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "testSets") {
      let setArray = [];
      if (checked) {
        setArray = [...formData.testSets];
        setArray.push(value);
      } else {
        setArray = formData.testSets.filter((item) => item !== value);
      }
      setFormData((pre) => ({
        ...pre,
        [name]: setArray,
      }));
    } else {
      setFormData((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };
  const handleSubmit = () => {
    console.log(formData);
    const req_body = {
      name: formData.testName,
      test_series: prop.testSeriesId,
      test_sets: formData.testSets,
      number: 1,
    };

    publicAxios
      .post(`/v2/topic`, req_body, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        setFormData({ testName: "", testSets: [], testSeriesId: "" });
        prop.getTopics();
        prop.handleClose();
      })
      .catch((e) => {
        console.info("error", e);
      });
  };
  return (
    <Modal
      open={prop.modalOpen}
      //   onClose={handleClose}
      top="50%"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflowY: "scroll" }}
    >
      <Box
        borderRadius={2}
        bgcolor="white"
        width="60%"
        padding={2}
        margin="auto"
      >
        <Box paddingX={1} paddingY={1.25} borderBottom={1} borderColor="#ddd">
          <Typography fontWeight={700}>Create a Topic</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingY={2}
        >
          <Grid container sm={12} gap={2}>
            <Grid item sm={7} direction="column">
              <Typography fontWeight={700}>Test Name</Typography>
              <TextField
                fullWidth
                placeholder="Chapter Test"
                name="testName"
                type="text"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container sm={12} direction="column" sx={{ marginTop: "30px" }}>
            <Grid item sm={7} direction="row">
              <Typography fontWeight={700}>Test Series Content</Typography>
              {/* <TextField fullWidth placeholder="Enter Name" name="testName"  type="text"/> */}
            </Grid>
            <Grid item sm={4} direction="row">
              <Typography>
                Select the tests that you want under this topic
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <Box style={{ overflowY: "scroll", height: "350px" }}>
          {data &&
            data.length &&
            data.map((item, index) => (
              <Grid container spacing={3}>
                {/* First and Second Columns (Left Side) */}
                <Grid item xs={6} key={"test-set-" + index}>
                  <Grid container spacing={3}>
                    {/* First Column */}
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          color="primary"
                          value={item.id}
                          name="testSets"
                          onChange={handleChange}
                        />
                        <Typography
                          variant="body1"
                          style={{ marginLeft: "10px" }}
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body1"
                          style={{ marginLeft: "10px" }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Third and Fourth Columns (Right Side) */}
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    {/* Third Column */}
                    {/* <Grid item xs={6}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" style={{ marginLeft: '10px' }}>
                                        56 Questions
                                    </Typography>
                                </Box>
                            </Grid> */}

                    {/* Fourth Column */}
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body1"
                          style={{ marginLeft: "10px" }}
                        >
                          {item.duration} Minutes
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Box>

        {/* {loading && <Loader />} */}
        <Box
          marginBottom={2}
          sx={{ overflow: "auto" }}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {/* {
          tests.files.map((test) => (
            <TestItem
              key={test.id}
              testFile={test}
              onDelete={handleTestDelete.bind(this, test.id)}
            />
          ))} */}
        </Box>
        <Box
          paddingX={1}
          paddingY={1.25}
          marginLeft="auto"
          borderTop={1}
          display="flex"
          gap={2}
          borderColor="#ddd"
          justifyContent="end"
        >
          <Button sx={{ color: "black" }} onClick={prop.handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            disableElevation={true}
            // disabled={tests.files.length === 0}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateTestTopic;
