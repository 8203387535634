import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Button, Stack } from "@mui/material";
import publicAxios from "../../axios/publicAxios";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Tools/Loader";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const StudentTable = () => {
  const auth = useRecoilValue(authAtom);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getInfo = async () => {
    await publicAxios
      .get("account/students", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  const columns = [
    {
      field: "avatar",
      headerName: "",
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <>
            <Avatar src={currentRow.resource.url} />
          </>
        );
      },
      flex: 1, // Set flex to 1 for auto width
    },
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => {
        const currentRow = params.row;
        return <>{currentRow?.first_name + " " + currentRow?.last_name}</>;
      },
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Mobile",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                navigate("/u1NzT9Pq2s5/admin/student/" + currentRow.id);
              }}
            >
              <img src="/images/admin/download.png" alt="" />
            </Button>
            <Button
              onClick={() => {
                const confirm = window.confirm(
                  "You sure want to Delete this user?"
                );
                if (confirm) {
                  publicAxios
                    .delete(`v2/account/${currentRow.id}`, {
                      headers: {
                        Authorization: "Bearer " + auth.access,
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        getInfo();
                        setLoading(false);
                      }
                    })
                    .catch((e) => {
                      if (e.response && e.response.status === 404) {
                        setLoading(false);
                      }
                      console.info("error", e);
                    });
                }
              }}
            >
              <img src="/images/admin/delete.png" alt="" />
            </Button>
          </Stack>
        );
      },
      flex: 1,
    },
  ];

  return (
    <>
      <div className="all-user-strip">All Students</div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {data.length > 0 ? (
            <Stack style={{ width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                autoHeight={true}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Stack>
          ) : (
            <>No Data available</>
          )}
        </>
      )}
    </>
  );
};
