/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import privateAxios from "../../axios/privateAxios";
import { useRazorpay } from "../../helper/useRazorpay";
import { toastAtom } from "../../state/atoms/toastAtom";
import { userAtom } from "../../state/atoms/userAtom";
import { useCart } from "../Cart/useCart";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import publicAxios from "../../axios/publicAxios";
import { catchError } from "../../helper/useError";
import selectedCouponAtom from "../../state/couponAtom";

export const useCheckout = () => {
  const {
    cartData,
    totalData,
    loading,
    emptyCart,
    setTotalData,
    setTotalDataWithCoupon,
  } = useCart();
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [disabledApply, setDisableApply] = useState(false);
  const [billingValues, setBillingValues] = useRecoilState(userAtom);
  const displayRazorpay = useRazorpay(couponCode, totalData);
  const [, setShowToast] = useRecoilState(toastAtom);
  const [checkEmail, setCheckEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [couponsData, setCouponsData] = useState([]);

  // load userData
  useEffect(() => {
    const loadUserData = () => {
      privateAxios
        .get("/account/profile", {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          let data = {
            id: res.data.id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            email_verified: res.data.email_verified,
            email: res.data.email,
            phone: res.data.phone,
            city: res.data.city,
            state: res.data.state,
            address: res.data.address,
            country: "IN",
            profile_image: {
              id: null,
              value: null,
              url: "",
            },
          };
          setBillingValues(res.data);
        })
        .catch((e) => console.log(e));
    };
    if (billingValues.id) {
      loadUserData();
    }
  }, [billingValues.id]);

  const getInfo = async (id) => {
    await publicAxios
      .get("v2/coupons/all", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          console.log("res.data res.data res.data", res.data);
          setCouponsData(res.data);
        }
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      });
  };

  // if cart is empty
  useEffect(() => {
    if (loading && emptyCart) {
      navigate("/cart");
    }
    getInfo();
  }, [loading, emptyCart]);

  const handleOnChange = (e) => {
    setBillingValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();
    displayRazorpay();
  };

  const handleOnChangeCoupon = (e) => {
    setCouponCode(e.target.value);
  };

  const handleApplyCoupon = () => {
    const validateCouponCode = () => {
      const successToast = {
        status: true,
        type: "success",
        successMessage: "Coupon code applied",
      };
      const errorToast = {
        status: true,
        type: "warning",
        errorMessage: "Invalid coupon code",
      };
      const request_body = { coupon_code: couponCode };

      privateAxios
        .post("/coupon/validate-coupon", request_body)
        .then((res) => {
          const coupon_discount = res?.data?.coupon_discount;
          if (coupon_discount > 0) {
            setDisableApply(true);
            setShowToast(successToast);
            updateCartTotal("add_coupon_discount", coupon_discount);
          } else {
            setDisableApply(false);
            setShowToast(errorToast);
            updateCartTotal("remove_coupon_discount");
          }
        })
        .catch((e) => {
          setDisableApply(false);
          setShowToast(errorToast);
          updateCartTotal("remove_coupon_discount");
          console.log(e);
        });
    };
    couponCode !== "" && validateCouponCode();
  };

  const verifyOtp = async (otp) => {
    try {
      setIsLoading(true);
      let successToast;
      let data = {
        otp: otp,
      };
      const response = await publicAxios.post("/email/verify-otp", data, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      });
      if (response.status === 200) {
        successToast = {
          status: true,
          type: "success",
          successMessage: "Your email has been verified!",
        };
        setIsLoading(false);
      } else {
        successToast = {
          status: true,
          type: "error",
          errorMessage:
            "Sorry we couldn't verify your email at this moment please try again!",
        };
        setIsLoading(false);
      }
      setShowToast(successToast);
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
      setShowToast({
        status: true,
        type: "error",
        errorMessage:
          "Sorry we couldn't verify your email at this moment please try again!",
      });
    }
  };
  const handleVarifyEmail = () => {
    privateAxios
      .post(`/email/send-otp`, null, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data === "SUCCESS") {
          setCheckEmail(true);
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  // const handleCheckEmailStatus = () => {
  //   privateAxios
  //     .post(`/email/status`, null, {
  //       headers: {
  //         "content-type": "application/json",
  //         Authorization: "Bearer " + auth.access,
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res.data);
  //       if (res.data.status === "Success") {
  //         setEmailStatus(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // };
  const handleRemoveCoupon = () => {
    const successToast = {
      status: true,
      type: "success",
      successMessage: "Coupon code removed",
    };
    setCouponCode("");
    setDisableApply(false);
    setShowToast(successToast);
    updateCartTotal("remove_coupon_discount");
  };

  const updateCartTotal = (action = "add_coupon_discount", coupon_discount) => {
    if (action === "add_coupon_discount" && coupon_discount > 0) {
      const updated_total = totalData?.total - coupon_discount;
      if (updated_total > 0) {
        setTotalData((pre) => ({
          ...pre,
          coupon: coupon_discount,
          total: updated_total,
        }));

        return true;
      }
    }
    if (action === "remove_coupon_discount" && totalData?.coupon > 0) {
      const updated_total = totalData?.total + totalData?.coupon;
      if (updated_total > 0) {
        setTotalData((pre) => ({ ...pre, coupon: 0, total: updated_total }));
        return true;
      }
    }
    return false;
  };

  return {
    loading: loading,
    cartData: cartData,
    totalData: totalData,
    couponCode: couponCode,
    billingValues: billingValues,
    disabledApply: disabledApply,
    checkEmail: checkEmail,
    handleOnChange: handleOnChange,
    handlePlaceOrder: handlePlaceOrder,
    handleApplyCoupon: handleApplyCoupon,
    handleRemoveCoupon: handleRemoveCoupon,
    handleOnChangeCoupon: handleOnChangeCoupon,
    handleVarifyEmail: handleVarifyEmail,
    isLoading: isLoading,
    verifyOtp: verifyOtp,
    couponsData: couponsData,
    setTotalDataWithCoupon: setTotalDataWithCoupon,
  };
};
