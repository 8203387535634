// couponAtom.js
import { atom } from "recoil";

const selectedCouponAtom = atom({
  key: "selectedCoupon",
  default: {
    id: "",
    value: "",
    r: {},
  },
});

export default selectedCouponAtom;
