import React, { useState } from "react";
import "./Admin.scss";
import { Box, Button, Container, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setAuthTokens } from "../../axios/tokens";
import { userAtom } from "../../state/atoms/userAtom";
import { useRecoilState } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import publicAxios from "../../axios/publicAxios";

export const AdminPanel = () => {
  const [_, setAdmin] = useRecoilState(userAtom);
  const [, setAuth] = useRecoilState(authAtom);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePass = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async () => {
    if (username !== "" && password !== "") {
      const response = await publicAxios.post("/v2/account/login", {
        username,
        password,
      });
      if (response.status === 200) {
        const { token, refresh, user } = response.data;
        const isAdmin = user.role === "ADMIN";
        const access_data = {
          access: token,
          refresh: refresh,
          admin: isAdmin,
        };
        setAuth(access_data);
        setAuthTokens(token, refresh, isAdmin);
        setAdmin(user);
        navigate("/u1NzT9Pq2s5/admin/dashboard");
      }
    }
  };

  return (
    <Container>
      <Box className="admin-main">
        <img src="/images/admin/logo.png" alt="" style={{ margin: "5%" }} />
        <Box
          sx={{ border: "1px solid #DDD", textAlign: "start", padding: "10px" }}
          className="stacking"
        >
          <Stack spacing={2}>
            <TextField
              id="outlined-number"
              type="text"
              value={username}
              onChange={handleChange}
              label="Username or Email Address"
              fullWidth
            />
            <TextField
              id="outlined-number"
              type="password"
              label="Password"
              fullWidth
              value={password}
              onChange={handlePass}
            />
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <div style={{ margin: "auto", padding: "2%", paddingTop: "0%" }}>
                <Button variant="contained" onClick={handleSubmit}>
                  Log In
                </Button>
              </div>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};
