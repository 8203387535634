import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Pagination,
  Skeleton,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { courseData } from "./data";
import "./CourseGrid.scss";
import publicAxios from "../../axios/publicAxios";
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import ConfirmationModal from "../ConfirmationModal";
import { toastAtom } from "../../state/atoms/toastAtom";
import { catchError } from "../../helper/useError";
import Loader from "../Tools/Loader";

export const CourseGridView = () => {
  const auth = useRecoilValue(authAtom);
  const [loading, setLoading] = useState(false);
  const [, setShowToast] = useRecoilState(toastAtom);
  const [list, setList] = useState(true);
  const [catList, setCatList] = useState([]);
  const [filters, setFilters] = useState({
    publish: true,
    category_id: null,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 20,
    total_page: undefined,
  });
  const [courses, setCourses] = useState([]);
  const [openModal, setOpenModal] = useState({
    open: false,
    id: null,
    loading: false,
    type: null,
  });
  const [ins, setIns] = useState([]);

  useEffect(() => {}, [ins]);

  const getCat = async () => {
    try {
      // "v2/category/all?type=POST"
      const response = await publicAxios.get("/v2/category/all?type=COURSE", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      let responseData = response.data;
      setCatList(responseData);
    } catch (error) {
      const err = await catchError(error);
      setShowToast({
        status: true,
        type: "error",
        errorMessage: err,
      });
    }
  };

  const fetchCourseInfo = async (queryParams) => {
    setLoading(true);
    await publicAxios
      .get("/v2/course", {
        params: queryParams,
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("apiData", JSON.stringify(res.data));
          setPagination({
            ...pagination,
            total_page: res.data?.total_page,
            per_page: res.data?.per_page,
            page: res.data?.current_page,
          });
          setCourses(res?.data?.data ?? []);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setCourses([]);
          setLoading(false);
        }
        console.info("error", e);
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      });
  };

  const fetchInstructorInfo = async () => {
    await publicAxios
      .get("/v2/faculty", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((instr) => {
        if (instr.status === 200) {
          setIns(instr.data ?? []);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setIns([]);
          setLoading(false);
        }
        console.info("error", e);
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchCourseInfo();
    getCat();
    fetchInstructorInfo();
  }, []);

  const openAreYourSureModal = (id, type) => {
    setOpenModal({
      open: true,
      id: id,
      loading: false,
      type: type,
    });
  };

  const closeModal = async () => {
    setOpenModal({
      open: false,
      id: null,
      loading: false,
      type: null,
    });
  };

  const handleActions = async () => {
    try {
      setOpenModal((prevState) => ({ ...prevState, loading: true }));
      const url_ = openModal.type === "active" ? "unsubmit" : "submit";
      let data = {
        course_id: openModal.id,
      };
      const response = await publicAxios.post(`/v2/course/${url_}`, data, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      if (response.status === 200) {
        const updatedCourses = courses.filter((course) => {
          return course.id !== openModal.id;
        });
        setCourses(updatedCourses);
        setShowToast({
          status: true,
          type: "success",
          successMessage:
            url_ === "submit"
              ? "Course has been activated!"
              : "Course has been deactivated!",
        });
        await closeModal();
        setOpenModal((prevState) => ({
          ...prevState,
          open: false,
          loading: false,
        }));
      }
    } catch (error) {
      const err = await catchError(error);
      setShowToast({
        status: true,
        type: "error",
        errorMessage: err,
      });
      setOpenModal((prevState) => ({ ...prevState, loading: false }));
      await closeModal();
    }
  };

  const handleFilters = async (type, id) => {
    let queryParams;
    if (type === "publish") {
      setFilters({
        publish: !filters.publish,
        category_id: filters.category_id,
      });
      queryParams = {
        publish: !filters.publish,
        category_id: filters.category_id,
      };
    } else if (type === "all") {
      queryParams = {
        publish: filters.publish,
      };

      setFilters({
        publish: filters.publish,
        category_id: null,
      });
    } else {
      setFilters({
        publish: filters.publish,
        category_id: id,
      });
      queryParams = { publish: filters.publish, category_id: id };
    }
    await fetchCourseInfo(queryParams);
  };

  return (
    <Stack sx={{ width: "90%" }}>
      <div className="all-user-strip">Bundle Courses</div>
      <div>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          justifyContent="space-between"
        >
          <Stack direction="row" style={{ width: "60%" }}>
            <div className="legends">
              <Checkbox
                checked={!filters.publish}
                onChange={() => handleFilters("publish")}
              />
              Inactive Courses
            </div>
            {catList.map((item, index) => (
              <div className="legends">
                <Checkbox
                  checked={filters.category_id === item.id}
                  onChange={() => handleFilters("categor", item.id)}
                />
                {item.name}
              </div>
            ))}
            <div className="legends">
              <Checkbox
                checked={!filters.category_id}
                onChange={() => handleFilters("All")}
              />
              All
            </div>
            {/*<div className="legends">*/}
            {/*  <Checkbox />*/}
            {/*  Bundled Courses*/}
            {/*</div>*/}
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            spacing={1}
            style={{ width: "40%" }}
          >
            <Pagination
              onChange={(e, page) => {
                setPagination({ ...pagination, page });
                fetchCourseInfo({ ...pagination, page });
              }}
              count={pagination.total_page}
              shape="rounded"
            />
            {/* <Button
                variant="contained"
                sx={{
                  background: "#025D97",
                }}
                onClick={handleDelCourse}
              >
                Delete Bundle
              </Button> */}
            {/*<Button*/}
            {/*  onClick={() => setList(true)}*/}
            {/*  variant="contained"*/}
            {/*  sx={{*/}
            {/*    background: "#025D97",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Make Bundle*/}
            {/*</Button>*/}
          </Stack>
        </Stack>
      </div>
      <div className="userGrid">
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflowY: "scroll",
            fontFamily: "Lato",
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              {courses?.length > 0 ? (
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {courses?.map((course, index) => (
                    <Grid item xs={12} sm={4} md={4} key={course.id}>
                      {ins ? (
                        <div
                          style={{
                            background: "white",
                            boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                course?.resource?.url
                                  ? course?.resource?.url
                                  : "/images/courses/thumbnail.png"
                              }
                              style={{
                                width: "-webkit-fill-available",
                                height: "200px",
                              }}
                              alt="Thumbail"
                            />
                          </div>
                          <Stack direction="column">
                            <div className="course-name">{course.title}</div>
                            <div className="course-card-body">
                              <Stack direction="column" spacing={2}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ paddingX: "2.5%" }}
                                >
                                  <div className="course-date">Price:</div>
                                  <div className="course-price">
                                    ₹ {course?.price}
                                  </div>
                                </Stack>
                                <Divider />
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ paddingX: "2.5%" }}
                                >
                                  <div className="course-date">Duration:</div>
                                  <div className="course-price">
                                    {course.duration} Months
                                  </div>
                                </Stack>
                                <Divider />
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ paddingX: "2.5%" }}
                                >
                                  <div className="course-date">Educators:</div>
                                  {/* <div className="course-price">{ins[course?.instructor[1]]?.name}</div> */}
                                  <div className="course-price text-end">
                                    {ins &&
                                      ins
                                        .filter((ele) =>
                                          course.faculties
                                            .map((itme) => itme.id)
                                            .includes(ele.id)
                                        )
                                        .map((ele) => ele.name)
                                        .join()}
                                  </div>
                                </Stack>
                                <Divider />
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ paddingX: "2.5%" }}
                                >
                                  <div className="course-date">
                                    <img
                                      src="/images/admin/student.svg"
                                      alt=""
                                    />
                                    Student
                                  </div>
                                  <div className="course-price">2,50,000</div>
                                </Stack>
                                <Divider />
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  <div className="readMore">
                                    <Button
                                      component={Link}
                                      to={`/u1NzT9Pq2s5/admin/course/editCourse/?id=${course.id}`}
                                      variant="contained"
                                      sx={{ borderRadius: "8px" }}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                  <div
                                    onClick={() => {
                                      const type = course.publish
                                        ? "active"
                                        : "deactive";
                                      openAreYourSureModal(course.id, type);
                                    }}
                                  >
                                    <label>Status</label>
                                    <Checkbox
                                      checked={course && course.publish}
                                      readOnly
                                      // disabled
                                    />
                                  </div>
                                </Stack>
                              </Stack>
                            </div>
                          </Stack>
                        </div>
                      ) : (
                        <>Loading</>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <>No Data</>
              )}
            </>
          )}
          {openModal.open ? (
            <ConfirmationModal
              message={`Are you sure you want to ${
                openModal.type === "active" ? "Deactivate" : "Active"
              } this course?`}
              closeModal={closeModal}
              handleActions={handleActions}
              data={openModal}
            />
          ) : null}
        </Box>
      </div>
    </Stack>
  );
};
