import React, { useState } from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Paper,
  Typography,
  FormControlLabel,
  FormGroup,
  DialogContentText,
} from "@mui/material";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
// import './Product.scss';
import { TestTable } from "../../../components/AdminTest/TestTable";
import { TestLibrary } from "../../../components/AdminTest/TestLibrary";
import { TestGridView } from "../../../components/AdminTest/TestGridView";
import publicAxios from "../../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

export const TestMgmt = (prop) => {
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState({
    name: "",
    description: "",
    hours: "",
    minutes: "",
  });
  const [testSeriesDel, setTestSeriesDel] = useState([]);

  // const [testSeries, setTestSeries] = useState();

  const handleCreateTest = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    const req_body = {
      name: title.name,
      description: title.description,
      access_type: "paid",
      duration:
        Number(title.hours * 60) + Number(title.minutes ? title.minutes : "0"),
      publish: false,
    };
    publicAxios
      .post("v2/testset", req_body, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((response) => {
        title.fetchTest = true;
        handleClose();
        console.log("CREATE TEST SERIES: ", response);
      })
      .catch((e) => {
        console.info("error", e);
      });
    // setTestSeries(event.tagrget.value)
  };

  const handleSelectTestSeries = (id) => {
    console.log(id);
    testSeriesDel.includes(id)
      ? setTestSeriesDel((prev) => [
          ...testSeriesDel.filter((ele) => id !== ele),
        ])
      : setTestSeriesDel((prev) => [...prev, id]);
  };
  const handleDelTestSeries = () => {
    console.log(testSeriesDel);
    testSeriesDel.forEach((id) => {
      //setLoading(true);
      publicAxios
        .delete("/v2/testseries/" + id, {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            //setLoading(false);
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            //setLoading(false);
          }
          console.info("error", e);
        });
    });
  };
  return (
    <div className="main-user-dash">
      <Stack sx={{ width: "90%" }}>
        <div
          className="all-user-strip"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p>All Test Series</p>
          {/* <Button variant="contained" onClick={handleDelTestSeries}>Delete Test Series</Button> */}
        </div>
        <div>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            justifyContent="end"
          >
            {prop.type === "testLibrary" && (
              <Button
                variant="contained"
                sx={{
                  background: "#025D97",
                }}
                onClick={handleCreateTest}
              >
                Create a Test
              </Button>
            )}
          </Stack>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Create Test Series</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="body1" fontWeight="bold">
                  Test Name
                </Typography>
              </DialogContentText>
              <TextField
                onChange={(event) => {
                  const { name, value } = event.target;
                  setTitle((prev) => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                name="name"
                value={title.name}
                fullWidth
                placeholder="Enter Name of the Test Set"
                margin="normal"
              />
              <DialogContentText>
                <Typography variant="body1" fontWeight="bold">
                  Test Description
                </Typography>
              </DialogContentText>
              <TextField
                onChange={(event) => {
                  const { name, value } = event.target;
                  setTitle((prev) => ({
                    ...prev,
                    [name]: value,
                  }));
                }}
                name="description"
                value={title.description}
                fullWidth
                placeholder="Enter Description of the Test Set"
                margin="normal"
              />
            </DialogContent>
            <DialogContentText fontWeight="bold" sx={{ marginLeft: "24px" }}>
              Test Duration
            </DialogContentText>
            <DialogActions
              sx={{
                justifyContent: "space-between !important",
                marginBottom: "10px",
              }}
            >
              <Stack direction="row">
                <FormGroup row>
                  <Grid container sx={{ marginRight: "10px" }}>
                    <FormControlLabel
                      sx={{
                        paddingLeft: "10px",
                        marginLeft: "10px",
                        marginRight: "15px",
                      }}
                      control={
                        <TextField
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setTitle((prev) => ({
                              ...prev,
                              [name]: value,
                            }));
                          }}
                          name="hours"
                          value={title.hours}
                          fullWidth
                          placeholder="0"
                        />
                      }
                    />
                    <DialogContentText
                      sx={{ marginTop: "9px" }}
                      fontWeight="bold"
                    >
                      Hours
                    </DialogContentText>
                  </Grid>
                </FormGroup>
                <FormGroup row>
                  <Grid container>
                    <FormControlLabel
                      sx={{
                        paddingLeft: "10px",
                        marginLeft: "10px",
                      }}
                      control={
                        <TextField
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setTitle((prev) => ({
                              ...prev,
                              [name]: value,
                            }));
                          }}
                          name="minutes"
                          value={title.minutes}
                          fullWidth
                          placeholder="30"
                        />
                      }
                    />
                    <DialogContentText
                      sx={{ marginTop: "9px" }}
                      fontWeight="bold"
                    >
                      Minutes
                    </DialogContentText>
                  </Grid>
                </FormGroup>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Button onClick={handleSubmit} variant="contained">
                  Submit
                </Button>
                <Button onClick={handleClose} variant="contained">
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
        </div>
        <div className="userGrid">
          {/* <TestTable name={title} toggle={true} /> */}
          {prop.type === "testLibrary" && (
            <TestLibrary
              name={title}
              toggle={true}
              fetchTest={title?.fetchTest}
            />
          )}
          {prop.type === "testGrid" && (
            <TestGridView handleSelectTestSeries={handleSelectTestSeries} />
          )}
        </div>
      </Stack>
    </div>
  );
};
