/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSmoothScroll } from "../../helper/useSmoothScroll";
import Loader from "../../components/Tools/Loader";
import successSvg from './success.svg'
import errorSvg from './error.svg'
import "./Order.scss";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { catchError } from "../../helper/useError";
import { toastAtom } from "../../state/atoms/toastAtom";
export const Success = () => {
  const { order_id } = useParams();
  const [, setShowToast] = useRecoilState(toastAtom);
  const auth = useRecoilValue(authAtom);
  const [timer, setTimer] = useState(5);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymenLoading, setIsPaymentLoading] = useState(true);

  useSmoothScroll();

  const navigate = useNavigate();


const successTitle = "Your Payment is Successful !";
const successMessage = `Thankyou for your payment. An automated payment receipt will be sent to your registered email. You will be redirected to your Dashboard in ${timer} seconds.`;
  

  const errorTitle = "Payment Failed !";
  const errorMessage = `Please try again later. You will be redirected to your Dashboard in ${timer} seconds.`;
  


  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const handleSuccess = async (order_id) => {
      try {
        const data = {
          order_id: order_id,
        };
        const response = await publicAxios.post(
          "/order/success-payment",
          data,
          {
            headers: {
              Authorization: "Bearer " + auth.access,
            },
          }
        );
        if (response.status === 200 && response.data.message==='success') {
          setIsPaymentLoading(false)
          setIsPaymentSuccess(true)
          setShowToast({
            status: true,
            type: "success",
            successMessage: "Payment verified!",
          });
        }
      } catch (error) {
        setIsPaymentLoading(false)
        const err = await catchError(error);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      } finally {
        const timeoutId = setTimeout(() => {
          navigate("/dashboard");
        }, 5000);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    };
    if (order_id) {
      handleSuccess(order_id);
    }
  }, [order_id]);

  return (
    <>
  {isPaymenLoading ? (
        <Loader />
      ) : (  <Container>
      <div className="success" style={{backgroundImage: `url(${isPaymentSuccess ? successSvg : errorSvg})`}}>
        <div className="content">
          <h2>{ isPaymentSuccess ?  successTitle:errorTitle}</h2>
          <p>{isPaymentSuccess ?  successMessage:errorMessage}</p>
        </div>
      </div>
    </Container>)}
    </>
  );
};
