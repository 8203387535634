import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";

import { ReactComponent as DragHandleIcon } from "../../assets/svg/admin/drag_handle.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/admin/delete-icon.svg";
import * as React from "react";
import ConfirmationModal from "../ConfirmationModal";
import { useState } from "react";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { toastAtom } from "../../state/atoms/toastAtom";
import { catchError } from "../../helper/useError";

export const RESOURCE_TYPE = {
  VIDEO: "video",
  TEST: "test",
  DOCUMENT: "document",
  IMAGE: "image",
  CHAPTER: "chapter",
};

export default function ChapterResourceItem({
  orderNo,
  title,
  image,
  length,
  type,
  selected,
  onSelect: onItemSeleted,
  onDelete,
  handleVideoName,
  accessType,
  id,
  chapterId,
  resourceId,
  updateState,
}) {
  const auth = useRecoilValue(authAtom);
  const [, setShowToast] = useRecoilState(toastAtom);

  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
    id: null,
  });
  const handleClose = () => {
    setOpenModal({
      open: false,
      type: null,
      id: null,
      loading: false,
    });
  };

  const handleMarkFree = async () => {
    try {
      setOpenModal((prevState) => ({ ...prevState, loading: true }));
      let data = {
        resource_id: openModal.id,
      };
      let url = openModal.type === "paid" ? "paid" : "free";
      const response = await publicAxios.post(`v2/resource/${url}`, data, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      if (response.status === 200) {
        let data = response.data?.access_type;
        await updateState(chapterId, resourceId, data);
        setShowToast({
          status: true,
          type: "success",
          successMessage:
            openModal.type === "paid"
              ? "Resource has been marked as paid!"
              : "Resource has been marked as free!",
        });
      }
    } catch (error) {
      const err = await catchError(error);
      setShowToast({
        status: true,
        type: "error",
        errorMessage: err,
      });
    } finally {
      setOpenModal({
        open: false,
        type: null,
        id: null,
        loading: false,
      });
    }
  };
  return (
    <>
      <Stack
        direction="row"
        padding={2}
        justifyContent="space-between"
        bgcolor="#F1F2F5"
        borderRadius="5px"
        onClick={onItemSeleted}
      >
        <Stack direction="row" rowGap={2} alignItems="center">
          {type === RESOURCE_TYPE.CHAPTER && <Radio checked={selected} />}
          <DragHandleIcon />
          <Typography marginLeft={2}>{orderNo}</Typography>
          {!!image && (
            <Box
              width="15%"
              marginLeft={1}
              border={1}
              borderRadius={1}
              overflow="hidden"
              bgcolor="white"
              borderColor="#00000033"
              paddingY={type === RESOURCE_TYPE.VIDEO ? 0 : 1}
            >
              {image}
            </Box>
          )}
          <Typography
            maxWidth="100%"
            noWrap
            marginLeft={2}
            marginRight={3}
            color="#0373BA"
          >
            {title}
          </Typography>
          {type === RESOURCE_TYPE.VIDEO && (
            <img
              src="/images/admin/edit.png"
              onClick={handleVideoName}
              alt=""
            />
          )}
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          {/*{type === RESOURCE_TYPE.VIDEO && id && (*/}
          {id && (
            <div
              className="legends"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div> Mark as Free</div>
              <Checkbox
                checked={accessType === "FREE"}
                onChange={() =>
                  setOpenModal({
                    open: true,
                    id: id,
                    type: accessType === "FREE" ? "paid" : "free",
                  })
                }
              />
            </div>
          )}
          {/*{length !== "00:00:00" ? (*/}
          {/*  <Typography style={{ fontSize: "8px" }}>{length}</Typography>*/}
          {/*) : null}*/}
          <Button btnType={type} onClick={onDelete}>
            <DeleteIcon />
          </Button>
        </Stack>
      </Stack>
      {openModal.open ? (
        <ConfirmationModal
          message={`Are you sure you want to ${
            openModal.type === "paid"
              ? "mark this lecture as paid"
              : "mark this lecture as free"
          } of this course?`}
          closeModal={handleClose}
          handleActions={handleMarkFree}
          data={openModal}
        />
      ) : null}
    </>
  );
}
