/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className=""
          style={{ textAlign: "center", margin: "auto", padding: "4em" }}
        >
          <img
            width={"256px"}
            height={"225px"}
            src="https://i.imgur.com/qIufhof.png"
          />

          <h1
            style={{ fontSize: "2em", marginTop: "1rem", textAlign: "center" }}
          >
            <span style={{ fontSize: "3em" }}>500</span> <br />
            Internal server error
          </h1>
          <p
            style={{
              textAlign: "center",
              marginTop: "1rem",
              fontSize: "12px",
            }}
          >
            We are currently trying to fix the problem.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
