import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Stack, Typography } from "@mui/material";

import { useEffect } from "react";
import publicAxios from "../../../axios/publicAxios";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import AddCourseForm1 from "../../../components/AdminCourse/AddCourseForm1";
import AddCourseForm2 from "../../../components/AdminCourse/AddCourseForm2";
import AddCourseForm3 from "../../../components/AdminCourse/AddCourseForm3";
import { RESOURCE_TYPE } from "../../../components/AdminCourse/ChapterResourceItem";
import Loader from "../../../components/Tools/Loader";
import { formatMeridiem } from "@mui/x-date-pickers/internals/utils/date-utils";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";
import { catchError } from "../../../helper/useError";
import { toastAtom } from "../../../state/atoms/toastAtom";

let courseId;
export const AddCourse = () => {
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const totalPages = 3;
  const [, setShowToast] = useRecoilState(toastAtom);
  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState();
  const [allCategories, setAllCategories] = useState(null);
  const [allFeatures, setAllFeatures] = useState([]);
  const [sequenceNumbersData, setSequenceNumbersData] = useState({});
  const [faqs, setFAQs] = useState([]);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    title: {
      value: "",
      error: false,
      message: "Title is required",
    },
    thumbnail: {
      value: null,
      error: false,
      message: null,
      id: null,
      url: "/images/admin/addCourseImage.png",
    },
    about: {
      value: "",
      error: false,
      message: null,
    },
    mrp: {
      value: 0,
      error: false,
      message: "MRP is required",
    },
    price: {
      value: 0,
      error: false,
      message: null,
    },
    language: {
      value: "",
      error: false,
      message: null,
    },
    duration: {
      value: 0,
      error: false,
      message: null,
    },
    purchaseDuration: {
      value: 0,
      error: false,
      message: null,
    },
    start_course_type: {
      value: 'FIXED_DURATION',
      error: false,
      message: null,
    },
    startDate: {
      value: dayjs().format("YYYY-MM-DD"),
      error: false,
      message: "Start date is required",
    },
    features: {
      value: [],
      error: false,
      message: null,
    },
    instructors: {
      value: [],
      error: false,
      message: "Instructor(s) is required",
    },
    category: {
      value: "",
      error: false,
      message: "Category is required",
    },
    subcategory: {
      value: "",
      error: false,
      message: "Sub-Category is required",
    },
    product: {
      value: "",
      error: false,
      message: "Product code is required",
    },
  });
  // console.log(formData);
  const [chapters, setChapters] = useState([]);

  function onNameConfirm(name) {
    //  console.log(name);
    const request_body = {
      course_id: courseId,
      name: name,
    };
    publicAxios
      .post(`v2/chapters`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        setChapters((chapters) => [
          ...chapters,
          { id: res.data.id, resources: [], test: [], document: [] },
        ]);
        console.log(chapters);
      })
      .catch((e) => {
        //   console.log(e);
      });
  }

  function addVideos(chapterId, videos) {
    setChapters((chapters) => {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = [
        ...selectedChapter.resources,
        ...videos.map((video) => ({
          type: RESOURCE_TYPE.VIDEO,
          id: video.id,
          file: video,
        })),
      ];
      return newChapters;
    });
    console.log(chapters);
  }

  function addTests(chapterId, tests) {
    console.log(tests);
    setChapters((chapters) => {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = [
        ...selectedChapter.resources,
        ...tests.files.map((test) => ({
          type: RESOURCE_TYPE.TEST,
          id: test.id,
          file: test,
        })),
      ];

      selectedChapter.test = [
        ...selectedChapter.test,
        {
          testName: tests.testName,
          testDescription: tests.testDescription,
          testdocumentFile: tests.testdocumentFile,
        },
      ];
      return newChapters;
    });
    console.log(chapters);
  }

  const uploadResource = (id, type, file, resourceId) => {
    const fileData = new FormData();
    type === "video" &&
      fileData.append("duration", parseInt(file.duration / 1000)); // duration uploaded in seconds
    type === "video" && fileData.append("file_name", file.name);
    type !== "video" && fileData.append("file", file);
    fileData.append("type", type);
    id && fileData.append("chapter_id", id);
    return publicAxios
      .post(
        `v2/${resourceId ? "resource/" + resourceId : "resources"}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
          timeout: 600000,
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  function addNotes(chapterId, notes) {
    console.log(notes);
    setChapters((chapters) => {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = [
        ...selectedChapter.resources,
        ...notes.files.map((note) => ({
          type: RESOURCE_TYPE.DOCUMENT,
          id: note.id,
          file: note,
        })),
      ];
      selectedChapter.document = [
        ...selectedChapter.document,
        {
          documentName: notes.documentName,
          documentDescription: notes.documentDescription,
          documentFile: notes.documentFile,
        },
      ];
      return newChapters;
    });
  }

  async function onDelete(chapterId, resourceId, btnType) {
    console.log(chapterId, resourceId, btnType);
    console.log(chapters);
    setLoading(true);
    if (btnType === "chapter") {
      setLoading(false);
      setChapters(chapters.filter((chapter) => chapter.id !== chapterId));
      //  return chapters.filter((chapter) => chapter.id !== chapterId);
    } else {
      const newChapters = [...chapters];
      const selectedChapter = newChapters.find(
        (chapter) => chapter.id === chapterId
      );
      selectedChapter.resources = selectedChapter.resources.filter(
        (resource) => resource.id !== resourceId
      );
      if (btnType === "document" || btnType === "test") {
        selectedChapter[btnType] = selectedChapter[btnType].filter((resource) =>
          btnType === "document"
            ? resource.documentFile !== resourceId
            : resource.testdocumentFile !== resourceId
        );
        return resourceDelete(resourceId)
          .then((res) => {
            setLoading(false);
            setChapters(newChapters);

            //  return newChapters;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        return resourceDelete(resourceId)
          .then((res) => {
            setLoading(false);
            setChapters(newChapters);
            // return newChapters;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  const resourceDelete = async (resourceId) => {
    try {
      const res = await publicAxios.delete(`v2/resource/${resourceId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.access,
        },
      });
      // console.log(res);
      return res;
    } catch (e) {
      // console.log(e);
      throw e; // Re-throw the error to handle it in the onDelete function
    }
  };
  console.log(chapters);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: {
        ...formData[name],
        value,
      },
    });
  }

  function handleStartDateChange(date) {
    setFormData((formData) => ({
      ...formData,
      startDate: {
        ...formData.startDate,
        value: date.format("YYYY-MM-DD"),
      },
    }));
  }

  function handleThumbnailImage(event) {
    //console.log(event.target.files);
    const resourceId = formData.thumbnail.id;
    uploadResource("", "image", event.target.files[0], resourceId).then(
      (res) => {
        setFormData((formData) => ({
          ...formData,
          thumbnail: {
            ...formData.thumbnail,
            value: event.target.files[0],
            id: res.data.id,
          },
        }));
      }
    );
  }

  function removeInstructor(instructor) {
    setFormData((formData) => ({
      ...formData,
      instructors: {
        ...formData,
        value: formData.instructors.value.filter((id) => id !== instructor.id),
      },
    }));
  }

  const [checkedState, setCheckedState] = useState({});

  function handleFeatureChange(event) {
    console.log(event);
    const { name, checked } = event.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
    setFormData((formData) => ({
      ...formData,
      features: {
        ...formData.features,
        value: checked
          ? [...formData.features.value, name]
          : [...formData.features.value.filter((item) => item !== name)],
      },
    }));
  }

  const getALLInstructors = async () => {
    await publicAxios
      .get("/v2/faculty", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          setInstructors(
            res.data.map((data) => ({ id: data.id, name: data.name }))
          );
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setInstructors([]);
          setLoading(false);
        }
        console.info("error", e);
      });
  };

  const getCategoryInfo = async () => {
    await publicAxios
      .get("/v2/category/all?type=COURSE", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllCategories(res.data);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setAllCategories(null);
        }
        console.info("error", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function onFAQAdded(faqModel) {
    if (faqModel.id) {
      const newFAQs = [...faqs];
      const idx = newFAQs.findIndex((faq) => faq.id === faqModel.id);
      if (idx !== -1) newFAQs[idx] = faqModel;
      const res = await createFaq(
        faqModel.question,
        faqModel.answer,
        faqModel.id
      );
      ///console.log(res);
      if (res.data) {
        setFAQs(newFAQs);
      }
    } else {
      const res = await createFaq(faqModel.question, faqModel.answer, "");
      //   console.log(res);
      if (res.data) {
        setFAQs([...faqs, { id: res.data.id, ...faqModel }]);
      }
    }
  }

  const createFaq = (question, answer, id) => {
    const request_body = {
      course_id: courseId,
      question: question,
      answer: answer,
      type: "COURSE",
    };
    return publicAxios
      .post(`v2/${id ? "faq/" + id : "faqs"}`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        //   console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //   console.log(e);
      });
  };

  const submitAllData = async () => {
    const request_body = {
      course_id: courseId,
    };
    return publicAxios
      .post(`/v2/course/submit`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        //   console.log(res);
        courseId = "";
        setLoading(false);
        navigate("/u1NzT9Pq2s5/admin/dashboard");
        setShowToast({
          status: true,
          type: "success",
          successMessage: "Course added successful!",
        });
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      });
  };

  function deleteFAQ(faqId) {
    setFAQs((faqs) => faqs.filter((faq) => faq.id !== faqId));
  }

  async function getAllFeatures() {
    const response = await publicAxios("/v2/features?type=COURSE", {
      headers: {
        Authorization: "Bearer " + auth.access,
      },
    });
    if (response.status === 200) {
      setAllFeatures(response.data);
    } else {
      setAllFeatures([]);
    }
  }

  const createCourse = () => {
    const missingFields = Object.entries(formData)
      .filter(([key, value]) => {
        if (typeof value === "object" && key !== "product") {
          if (key === "duration" || key === "purchaseDuration") {
            // Check if at least one of duration or purchaseDuration is filled out
            return !formData.duration.value && !formData.purchaseDuration.value;
          } else {
            // Regular validation for other fields
            return (
              !value.value ||
              (Array.isArray(value.value) && value.value.length === 0)
            );
          }
        } else {
          return !value;
        }
      })
      .map(([key, value]) => {
        return formData[key].message
          ? `${formData[key].message}`
          : `${key} is required`;
      });

    if (missingFields.length > 0) {
      setShowToast({
        status: true,
        type: "error",
        errorMessage: `Please fill in all required fields: ${missingFields.join(
          ", "
        )}.`,
      });
      return; // Stop execution if any required field is missing
    }
    setLoading(true);
    newCourse();
    // navigate("/addCourse");
  };
  const newCourse = () => {
    const request_body = {
      title: formData.title.value,
      resource: formData.thumbnail.id,
      about: formData.about.value,
      mrp: formData.mrp.value,
      price: formData.price.value,
      language: formData.language.value,
      duration: formData.duration.value || 0,
      purchaseDuration: formData.purchaseDuration.value || 0,
      start_course_type:formData.start_course_type.value,
      startDate: formData.startDate.value,
      features: formData.features.value,
      instructors: formData.instructors.value,
      category: formData.category.value,
      subcategory: formData.subcategory.value,
      product: formData.product.value,
    };
    //   courseId && (request_body.course = courseId) ;
    // console.log(request_body)
    publicAxios
      .post(`/v2/course${courseId ? "/" + courseId : ""}`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        courseId = res.data.id;
        setLoading(false);
        setPage((page) => page + 1);
        //  console.log(courseId);
        // navigate("/admin/course")
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  const chapterSubmited = () => {
    const request_body = {
      id: courseId,
      chapters: chapters.map(({ resources, ...rest }) => rest),
      sequence_numbers: sequenceNumbersData,
    };
    publicAxios
      .post(`/v2/course/addDetails`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        setLoading(false);
        setPage((page) => page + 1);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };
  const goToNextPage = () => {
    // if (page === totalPages) {
    //   createCourse();
    //   navigate("/admin/dashboard/courses")
    // } else {

    // }

    if (page === 1) {
      //  console.log(page);
      createCourse();
    } else if (page === 2) {
      setLoading(true);
      chapterSubmited();
      //   console.log(page);
    } else if (page === 3) {
      //   console.log(page);
      setLoading(true);
      submitAllData();
    }
  };

  const goToPrevPage = () => {
    setPage((page) => page - 1);
  };
  //console.log(courseId);
  useEffect(() => {
    setLoading(true);
    getALLInstructors();
    getCategoryInfo();
    getAllFeatures();
    setLoading(false);
  }, []);
  let addCourseForm;
  if (page === 1) {
    addCourseForm = (
      <AddCourseForm1
        checkedState={checkedState}
        formData={formData}
        handleChange={handleChange}
        handleFeatureChange={handleFeatureChange}
        handleStartDateChange={handleStartDateChange}
        handleThumbnailImage={handleThumbnailImage}
        createCourse={goToNextPage}
        allCategories={allCategories}
        allFeatures={allFeatures}
        instructors={instructors}
        removeInstructor={removeInstructor}
      />
    );
  } else if (page === 2) {
    addCourseForm = (
      <AddCourseForm2
        courseImage={
          formData.thumbnail.value
            ? URL.createObjectURL(formData.thumbnail.value)
            : formData.thumbnail?.url
        }
        courseName={formData.title.value}
        chapters={chapters}
        onNameConfirm={onNameConfirm}
        addVideos={addVideos}
        addTests={addTests}
        addNotes={addNotes}
        onDelete={onDelete}
        uploadResource={uploadResource}
      />
    );
  } else if (page === 3) {
    addCourseForm = (
      <AddCourseForm3
        courseImage={
          formData.thumbnail.value
            ? URL.createObjectURL(formData.thumbnail.value)
            : formData.thumbnail?.url
        }
        courseName={formData.title.value}
        faqs={faqs}
        onFAQAdded={onFAQAdded}
        deleteFAQ={deleteFAQ}
      />
    );
  }

  useEffect(() => {
    const sequenceNumbers = {};
    if (chapters) {
      chapters.forEach((chapter) => {
        const chapterId = chapter.id;

        const chapterSequence = [];

        chapter.resources.forEach((resource, resourceIndex) => {
          chapterSequence.push({
            resource_id: resource.id,
            sequence_number: resourceIndex + 1,
          });
        });

        sequenceNumbers[chapterId] = chapterSequence;
      });
      console.log({ sequence_numbers: sequenceNumbers });
    }
    setSequenceNumbersData(sequenceNumbers);
  }, [chapters]);

  return (
    <div className="main-user-dash">
      <Stack sx={{ width: "90%" }}>
        <div className="all-user-strip">
          <Typography>
            Add Course {page}/{totalPages}
          </Typography>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Stack direction="row" gap={2}>
              <Button
                variant="contained"
                onClick={goToPrevPage}
                disabled={page === 1}
              >
                Previous
              </Button>
              <Button variant="outlined" onClick={goToNextPage}>
                {page === totalPages ? "Submit" : "Next"}
              </Button>
            </Stack>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="UserPaper">{addCourseForm}</div>
        )}
      </Stack>
    </div>
  );
};
