import { useNavigate } from "react-router-dom";
import privateAxios from "../../axios/privateAxios";
import { authAtom } from "../../state/atoms/authAtom";
import { useRecoilValue } from "recoil";

export const useSubmitOnlineTest = (onlineTestData) => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);

  const handleSubmitTest = () => {
    const submitTest = (onlineTestData) => {
      privateAxios
        .post(`onlinetest/${onlineTestData?.id}/submit`,null,{
          headers: {
            "Authorization": "Bearer " + auth.access,
          }
        })
        .then((res) => {
          navigate(
            `/test_series/${onlineTestData?.test_series}/testset/${onlineTestData?.test_set}/performance`
          );
        })
        .catch((e) => console.log(e));
    };

    onlineTestData?.id && submitTest(onlineTestData);
  };

  return [handleSubmitTest];
};
