import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { catchError } from "../../helper/useError";
import { toastAtom } from "../../state/atoms/toastAtom";
import Loader from "../../components/Tools/Loader";
import dayjs from "dayjs";

const LiveClass = () => {
  const auth = useRecoilValue(authAtom);
  const [, setShowToast] = useRecoilState(toastAtom);
  const [liveClasses, setLiveClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedLiveClass, setSelectedLiveClass] = useState(null);
  const [newLiveClass, setNewLiveClass] = useState({
    title: "",
    link: "",
    course_id: "",
    start_time: "",
  });
  const rowsPerPage = 5;
  const [formErrors, setFormErrors] = useState({});

  // Fetch available live classes from backend
  useEffect(() => {
    fetchLiveClasses();
    fetchCourseInfo();
  }, []);

  const fetchLiveClasses = async () => {
    try {
      const response = await publicAxios.get("/v2/liveclass", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      setLiveClasses(response.data);
    } catch (error) {
      console.error("Error fetching live classes:", error);
      setIsLoading(false);
    }
  };

  const fetchCourseInfo = async (queryParams) => {
    await publicAxios
      .get("/v2/course", {
        params: queryParams,
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCourses(res.data.data ?? []);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
        setIsLoading(false);
      });
  };

  const handleAddLiveClass = async () => {
    if (validateForm()) {
      try {
        setIsLoading(true);
        if (editMode) {
          await publicAxios.post(
            `/v2/liveclass/${selectedLiveClass.id}`,
            newLiveClass
          );
        } else {
          await publicAxios.post("/v2/liveclass", newLiveClass, {
            headers: {
              Authorization: "Bearer " + auth.access,
            },
          });
        }
        let message = "Added Successfully !";
        if (editMode) {
          message = "Updated Successfully !";
        }
        setShowToast({
          status: true,
          type: "success",
          successMessage: message,
        });
        fetchLiveClasses();
        setIsLoading(false);
        setOpenModal(false);
        setNewLiveClass({
          title: "",
          link: "",
          course_id: "",
          start_time: "",
        });
        setFormErrors({});
        setEditMode(false);
        setSelectedLiveClass(null);
      } catch (error) {
        const err = catchError(error);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
        setIsLoading(false);
      }
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setFormErrors({});
    setEditMode(false);
    setSelectedLiveClass(null);
  };

  const handleChange = (e) => {
    setNewLiveClass({ ...newLiveClass, [e.target.name]: e.target.value });
  };

  const handleEdit = (liveClass) => {
    setSelectedLiveClass(liveClass);
    setNewLiveClass({
      title: liveClass.title,
      link: liveClass.link,
      course_id: liveClass?.course?.id,
      start_time: dayjs(liveClass.start_time),
    });
    setEditMode(true);
    setOpenModal(true);
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    console.log("newLiveClass newLiveClass", newLiveClass);

    if (!newLiveClass.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }

    if (!newLiveClass.course_id) {
      errors.course_id = "Course is required";
      isValid = false;
    }

    if (!newLiveClass.link.trim()) {
      errors.link = "Link is required";
      isValid = false;
    }

    if (!newLiveClass?.start_time) {
      errors.start_time = "Start Time is required";
      isValid = false;
    }

    console.log("isValid", isValid);

    setFormErrors(errors);
    return isValid;
  };

  return (
    <Stack style={{ padding: "1rem" }}>
      <div className="all-user-strip">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <div>Live Class</div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditMode(false);
              setNewLiveClass({
                title: "",
                link: "",
                course_id: "",
                start_time: "",
              });
              setOpenModal(true);
            }}
            disabled={isLoading}
          >
            Add Live Class
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Dialog open={openModal} onClose={handleModalClose}>
            <DialogTitle>
              {editMode ? "Edit Live Class" : "Add New Live Class"}
            </DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Title"
                fullWidth
                name="title"
                value={newLiveClass.title}
                onChange={handleChange}
                error={!!formErrors.title}
                helperText={formErrors.title}
              />
              <TextField
                margin="dense"
                label="Link"
                fullWidth
                name="link"
                value={newLiveClass.link}
                onChange={handleChange}
                error={!!formErrors.link}
                helperText={formErrors.link}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  name="start_time"
                  fullWidth
                  value={dayjs(newLiveClass.start_time)}
                  error={!!formErrors.start_time}
                  helperText={formErrors.start_time}
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "start_time",
                        value: new Date(date),
                      },
                    })
                  }
                />
              </LocalizationProvider>
              <TextField
                select
                margin="dense"
                label="Course"
                fullWidth
                name="course_id"
                type="number"
                value={newLiveClass.course_id}
                helperText={formErrors.course_id}
                error={!!formErrors.course_id}
                onChange={handleChange}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Cancel</Button>
              <Button onClick={handleAddLiveClass} color="primary">
                {editMode ? "Save" : "Add"}
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {liveClasses
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((liveClass, index) => (
                    <TableRow key={index}>
                      <TableCell>{liveClass.title}</TableCell>
                      <TableCell>{liveClass.link}</TableCell>
                      <TableCell>{liveClass.course?.title}</TableCell>
                      <TableCell>{liveClass.start_time}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleEdit(liveClass)}>
                          Edit
                        </Button>
                      </TableCell>{" "}
                      {/* Add Edit button */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
              Previous
            </Button>
            <Button
              onClick={() => setPage(page + 1)}
              disabled={(page + 1) * rowsPerPage >= liveClasses.length}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Stack>
  );
};

export default LiveClass;
