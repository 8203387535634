import { Box } from "@mui/material";

import TopicTestSet, { RESOURCE_TYPE } from "./TopicTestSet";
import { ReactComponent as DocumentLogo } from "../../assets/svg/admin/notes-icon.svg";
import { ReactComponent as ImageLogo } from "../../assets/svg/admin/image.svg";
import { ReactComponent as TestLogo } from "../../assets/svg/admin/test.svg";
import { getFormattedTime } from "../../utils";

export default function TopicView({topics, topicIdx, onSelect, onDelete }) {
  console.log(topics.id);
  const resources = topics?.topic?.map((item, index) => {
    console.log(item.id, item)
    
    return (
      <TopicTestSet
        key={item.id}
        id={item.id}
        duration={item.duration}
        orderNo={`${topicIdx + 1}.${index + 1}`}
        title={item.name}
        onDelete={onDelete.bind(this, item.id, item.id)}
      />
    );
  });
  return (
    <Box>
      <TopicTestSet
        orderNo={`${topicIdx + 1}.`}
        title={topics.name}
        onSelect={onSelect}
        id={topics.id}
        onDelete={onDelete.bind(this, topics.id, null)}
      />
      <Box display="flex" flexDirection="column" gap={1} marginLeft={3} marginTop={1}>
        {resources}
      </Box>
    </Box>
  );
}
