import { atom } from "recoil";
import { getStates } from "../axios/tokens";

const reasons = getStates();

const stateReasonAtom = atom({
  key: "stateReasonAtom",
  default: reasons,
});

export { stateReasonAtom };
