// import { Box } from "@mui/material";
//
// import ChapterResourceItem, { RESOURCE_TYPE } from "./ChapterResourceItem";
// import { ReactComponent as DocumentLogo } from "../../assets/svg/admin/notes-icon.svg";
// import { ReactComponent as ImageLogo } from "../../assets/svg/admin/image.svg";
// import { ReactComponent as TestLogo } from "../../assets/svg/admin/test.svg";
// import { getFormattedTime } from "../../utils";
//
// export default function ChapterView({
//   chapterIdx,
//   chapter,
//   selected,
//   onSelect,
//   onDelete,
//   handleVideoName,
//   videoNameRef,
//   updateState,
// }) {
//   let totalDuration = 0;
//   const resources = chapter?.resources?.map((resource, index) => {
//     let image, length;
//     switch (resource.type) {
//       case RESOURCE_TYPE.VIDEO:
//         image = resource.file?.thumbnail ? (
//           <img
//             width="100%"
//             height="100%"
//             style={{ objectFit: "contain" }}
//             src={resource.file.thumbnail}
//             alt={resource.file.name}
//           />
//         ) : (
//           <video
//             width="100%"
//             height="100%"
//             style={{ objectFit: "contain" }}
//             src={resource.file.url}
//             alt={resource.file.url.split("video/")[1]}
//           />
//         );
//         if (resource.file.duration) {
//           length = getFormattedTime(resource.file.duration);
//           totalDuration += resource.file.duration;
//         } else {
//           length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
//         }
//         break;
//       case RESOURCE_TYPE.DOCUMENT:
//         image = <DocumentLogo />;
//         length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
//         break;
//       case RESOURCE_TYPE.IMAGE:
//         image = <ImageLogo />;
//         length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
//         break;
//       case RESOURCE_TYPE.TEST:
//         image = <TestLogo />;
//         length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
//         break;
//       default:
//         image = null;
//     }
//     return (
//       <>
//         <ChapterResourceItem
//           key={resource.id}
//           id={resource.id}
//           chapterId={chapter.id}
//           resourceId={resource.id}
//           orderNo={`${chapterIdx + 1}.${index + 1}`}
//           title={
//             resource.type === "video" ? resource.file.name : resource.file.name
//           }
//           image={image}
//           length={length}
//           accessType={resource.access_type}
//           type={resource.type}
//           onDelete={onDelete.bind(this, chapter.id, resource.id)}
//           handleVideoName={handleVideoName.bind(this, chapter.id, resource.id)}
//           updateState={updateState}
//         />
//       </>
//     );
//   });
//   return (
//     <Box>
//       <ChapterResourceItem
//         orderNo={`${chapterIdx + 1}.`}
//         title={chapter.name}
//         length={getFormattedTime(totalDuration)}
//         type={RESOURCE_TYPE.CHAPTER}
//         selected={selected}
//         onSelect={onSelect}
//         accessType={chapter.access_type}
//         onDelete={onDelete.bind(this, chapter.id, null)}
//       />
//       <Box
//         display="flex"
//         flexDirection="column"
//         gap={1}
//         marginLeft={3}
//         marginTop={1}
//       >
//         {resources}
//       </Box>
//     </Box>
//   );
// }

import { Box, Button, TextField } from "@mui/material";

import ChapterResourceItem, { RESOURCE_TYPE } from "./ChapterResourceItem";
import { ReactComponent as DocumentLogo } from "../../assets/svg/admin/notes-icon.svg";
import { ReactComponent as ImageLogo } from "../../assets/svg/admin/image.svg";
import { ReactComponent as TestLogo } from "../../assets/svg/admin/test.svg";
import { getFormattedTime } from "../../utils";
import { useState } from "react";

export default function ChapterView({
  chapterIdx,
  chapter,
  selected,
  onSelect,
  onDelete,
  handleVideoName,
  videoNameRef,
  updateState,
  updateSequenceNumbers,
  key,
}) {
  const [isSeq, setIsSeq] = useState(false);
  console.log("chapter00000000000", key);
  const handleSeqData = (e) => {};
  const handleSeq = () => {
    setIsSeq(!isSeq);
  };
  let totalDuration = 0;
  const resources = chapter?.resources?.map((resource, index) => {
    let image, length;
    let sequenceInput = null;
    if (resource.type === RESOURCE_TYPE.VIDEO) {
      sequenceInput = (
        <input
          style={{ width: "50px" }}
          type="number"
          defaultValue={index + 1}
          max={chapter.resources.length}
          min={1}
          onChange={(e) => {
            const number = Number(e.target.value);
            updateSequenceNumbers(chapter.id, resource.id, number);
          }}
        />
      );
    }
    switch (resource.type) {
      case RESOURCE_TYPE.VIDEO:
        image = resource.file?.thumbnail ? (
          <img
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
            src={resource.file.thumbnail}
            alt={resource.file.name}
          />
        ) : (
          <video
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
            src={resource.file.url}
            alt={resource.file.url.split("video/")[1]}
          />
        );
        if (resource.file.duration) {
          length = getFormattedTime(resource.file.duration);
          totalDuration += resource.file.duration;
        } else {
          length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
        }
        break;
      case RESOURCE_TYPE.DOCUMENT:
        image = <DocumentLogo />;
        length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
        break;
      case RESOURCE_TYPE.IMAGE:
        image = <ImageLogo />;
        length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
        break;
      case RESOURCE_TYPE.TEST:
        image = <TestLogo />;
        length = `${(resource.file.size / (1024 * 1024)).toFixed(2)} MB`;
        break;
      default:
        image = null;
    }
    return (
      <>
        <Box key={resource.id} display="flex" alignItems="center">
          {isSeq ? sequenceInput : ""}
          <ChapterResourceItem
            id={resource.id}
            chapterId={chapter.id}
            resourceId={resource.id}
            orderNo={`${chapterIdx + 1}.${index + 1}`}
            title={
              resource.type === "video"
                ? resource.file.name
                : resource.file.name
            }
            image={image}
            length={length}
            accessType={resource.access_type}
            type={resource.type}
            onDelete={onDelete.bind(this, chapter.id, resource.id)}
            handleVideoName={handleVideoName.bind(
              this,
              chapter.id,
              resource.id
            )}
            updateState={updateState}
          />
        </Box>
      </>
    );
  });
  return (
    <Box>
      <Button aria-label="delete" sx={{ minWidth: "0" }} onClick={handleSeq}>
        Change sequence
      </Button>
      <ChapterResourceItem
        orderNo={`${chapterIdx + 1}.`}
        title={chapter.name}
        length={getFormattedTime(totalDuration)}
        type={RESOURCE_TYPE.CHAPTER}
        selected={selected}
        onSelect={onSelect}
        accessType={chapter.access_type}
        onDelete={onDelete.bind(this, chapter.id, null)}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        marginLeft={3}
        marginTop={1}
      >
        {resources}
      </Box>
    </Box>
  );
}
