import { useState } from "react";
import { Typography, Container, Paper, TextField, Grid,Box, Stack  } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// import Loader from "../../Tools/Loader";

const EvaluationFormNav = ({data}) => {
    const paperStyle = {
        backgroundColor: 'transparent',   // Setting background color
        boxShadow: 'none',
        marginTop: "1%"

    };
    const columns = [
        { 
         field: "id",
         headerName: "No.",
         width: 50 
        },
        {
          field: "name",
          headerName: "Name",
          width: 150,
        //   renderCell: (params) => {
        //     const currentRow = params.row;
        //    return currentRow.participants + ' Participants';
        //  },
        },
        {
          field: "answered_questions",
          headerName: "Attempted Questions",
          width: 100,
          
        },
        {
          field: "evaluated_answered_question",
          headerName: "EvaluatedQuestions",
          width: 100,
          renderCell: (params) => {
            const currentRow = params.row;
            return currentRow.evaluated_answered_question + "/" +currentRow.comp_count;
          },
        },
      ];
    
      const rows = [
        
        {
          id: 1,
          Name: "Navya Chopr",
          AttemptedQuestions: "6",
          EvaluatedQuestions:"6/6",
        },
        {
            id: 2,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 3,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 4,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 5,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 6,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 7,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 8,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 9,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
        {
            id: 10,
            Name: "Navya Chopr",
            AttemptedQuestions: "6",
            EvaluatedQuestions:"6/6",
        },
      ];

    return (
        
        <>
            { data.length && <DataGrid
                rows={data}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                enablePagination={false}
                hideFooter={true}
                
            />}
        </>
    );
};

export default EvaluationFormNav;
