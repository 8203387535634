export const IdeaSvg = () => {
  return (
    <svg
      width="177"
      height="341"
      viewBox="0 0 177 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M106.11 340.543H75.3379V335.116L106.11 330.227V340.543Z" fill="#FCB03E" />
      <path
        d="M120.15 315.436L63.5934 325.619L61.499 312.926L118.211 303.414L120.15 315.436Z"
        fill="#FCB03E"
      />
      <path
        d="M126.076 288.326L54.6183 301.176L52.8574 289.278L124.525 276.218L126.076 288.326Z"
        fill="#FCB03E"
      />
      <path
        d="M151.466 193.258C132.244 235.931 124.471 256.959 124.471 256.959L56.7827 269.778C56.7827 269.778 48.3352 228.454 38.0804 216.524C38.0804 216.524 1.56776 159.521 38.0804 116.934C75.9427 72.7794 178.398 92.724 151.466 193.258Z"
        fill="#FCB03E"
        stroke="#FCB03E"
        strokeWidth="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6355 180.596C56.6355 180.596 43.27 130.96 87.912 124.278"
        stroke="white"
        strokeWidth="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M135.881 100.107C105.512 78.6736 72.1328 78.6813 41.7793 100.107L42.237 71.4458L88.8804 57.5906L135.423 71.4458L135.881 100.107Z"
        fill="#0373BA"
      />
      <path
        d="M139.348 71.3211L88.8885 56.2106L38.4209 71.3211L0.830078 47.3922L88.2912 0.158203L176.939 47.3922L139.348 71.3211Z"
        fill="#0373BA"
      />
      <path
        d="M159.245 56.3198V101.511"
        stroke="#0373BA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.997 93.3164L152.481 99.8581"
        stroke="#0373BA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.494 93.3164L166.01 99.8581"
        stroke="#0373BA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
