import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Loader from "../Tools/Loader";
import Divider from "../Tools/Divider";
import { Coupon } from "../../components/Coupon/Coupon";
import { useCheckout } from "./useCheckout";

import * as constant from "./constant";

import "./Checkout.scss";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { stateReasonAtom } from "../../state/stateReason";
import selectedCouponAtom from "../../state/couponAtom";

export const CheckoutCard = () => {
  const {
    loading,
    cartData,
    totalData,
    couponCode,
    billingValues,
    disabledApply,
    checkEmail,
    handleOnChange,
    handlePlaceOrder,
    handleApplyCoupon,
    handleRemoveCoupon,
    handleOnChangeCoupon,
    handleVarifyEmail,
    isLoading,
    verifyOtp,
    couponsData,
    setTotalDataWithCoupon,
  } = useCheckout();

  return (
    <>
      <h1 className="headline_medium">Checkout</h1>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handlePlaceOrder} className="checkoutCard">
          <BillingCard
            billingValues={billingValues}
            handleOnChange={handleOnChange}
            handleVarifyEmail={handleVarifyEmail}
            checkEmail={checkEmail}
            isLoading={isLoading}
            verifyOtp={verifyOtp}
          />
          <div className="checkoutDetail">
            {/*<CartProductDetail cartData={cartData} />*/}
            <Coupon
              couponsData={couponsData}
              couponCode={couponCode}
              handleOnChangeCoupon={handleOnChangeCoupon}
              handleApplyCoupon={handleApplyCoupon}
              disabledApply={disabledApply}
              handleRemoveCoupon={handleRemoveCoupon}
              setTotalDataWithCoupon={setTotalDataWithCoupon}
            />
            <CartTotal totalData={totalData} />
            <div
              style={{ backgroundColor: "whitesmoke", borderRadius: "16px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <img
                  src="/images/order/cashfree.png"
                  alt="razorpay"
                  style={{
                    height: "50px",
                    width: "150px",
                  }}
                />
                <div>
                  <p style={{ color: "gray", fontSize: "14px" }}>
                    {" "}
                    Credit Card/Debit Card/NetBanking/UPI
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "lightgray",
                  marginTop: "10px",
                  padding: "10px",
                }}
              >
                <p style={{ color: "gray", fontSize: "14px" }}>
                  Pay secure through cashfree payments
                </p>
              </div>
            </div>

            <FormControlLabel
              label={
                <>
                  <span>I agree to Study Glows </span>
                  <a href="/reading/terms_and_condition" target="_blank">
                    Terms & Privacy
                  </a>
                </>
              }
              labelPlacement="end"
              control={<Checkbox name="checkbox" required />}
            />
            <Button type="submit" variant="contained">
              Place Order
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

const BillingCard = ({
  billingValues,
  handleOnChange,
  handleVarifyEmail,
  checkEmail,
  isLoading,
  verifyOtp,
}) => {
  const [otp, setOtp] = useState("");

  const states = useRecoilValue(stateReasonAtom);

  return (
    <div className="billingCard">
      <div className="cardContent">
        <h2 className="headline_small">Billing Details</h2>
        <div className="formFields">
          {constant.inputFields.map((item) =>
            item.name === "phone" ? (
              <PhoneInput
                key={`checkout-${item.id}`}
                specialLabel={""}
                value={billingValues[item.name]}
                country={"in"}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                disabled={true}
                containerClass="phoneContainer"
                inputClass="phoneInput"
              />
            ) : item.type === "select" ? (
              <TextField
                select
                key={`checkout-${item.id}`}
                variant="outlined"
                name={item.name}
                label={item.label}
                sx={item.sx}
                disabled={item.disabled}
                required={item.required}
                value={billingValues[item.name]}
                onChange={handleOnChange}
                containerClass="phoneContainer"
                inputClass="phoneInput"
              >
                <MenuItem value="" disabled>
                  {item.placeholder}
                </MenuItem>
                {states.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <>
                <TextField
                  key={`checkout-${item.id}`}
                  variant="outlined"
                  name={item.name}
                  label={item.label}
                  disabled={item.disabled}
                  type={item.type}
                  placeholder={item.placeholder}
                  sx={item.sx}
                  required={item.required}
                  value={billingValues[item.name]}
                  InputProps={item.inputProps}
                  onChange={handleOnChange}
                  error={item.error}
                  helperText={item.helperText}
                />
                {!billingValues?.email_verified ? (
                  <>
                    {item.name === "email" && checkEmail && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: 10,
                        }}
                      >
                        <p style={{ color: "green", margin: 0 }}>
                          Please check your email to verify your account.
                        </p>

                        <TextField
                          label="Enter OTP"
                          type="number"
                          className="otpField"
                          inputProps={{ maxLength: 6 }}
                          variant="outlined"
                          value={otp}
                          onChange={(e) => {
                            const inputVal = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 6);
                            setOtp(inputVal);
                          }}
                        />
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => verifyOtp(otp)}
                          disabled={!otp || otp.length !== 6 || isLoading}
                        >
                          {isLoading ? <CircularProgress size={24} /> : null}
                          Verify OTP
                        </Button>
                      </div>
                    )}
                  </>
                ) : null}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const CartProductDetail = ({ cartData }) => {
  return (
    <div className="productDetailsWrapper">
      {cartData &&
        cartData.map((item) =>
          item?.type === "course" ? (
            <div
              className="productDetails"
              key={`checkout-productdetail-${item.id}`}
            >
              <div>
                <img
                  className="fluid-image"
                  src={item?.course?.thumbnail}
                  alt={item?.course?.title}
                />
              </div>
              <h3 className="title_medium">{item?.course?.title}</h3>
              <div className="price">{item?.total_amount}</div>
            </div>
          ) : (
            <div
              className="productDetails"
              key={`checkout-productdetail-${item.id}`}
            >
              <div>
                <img
                  className="fluid-image"
                  src={item?.testseries?.thumbnail}
                  alt={item?.testseries?.title}
                />
              </div>
              <h3 className="title_medium">{item?.testseries?.title}</h3>
              <div className="price">{item?.total_amount}</div>
            </div>
          )
        )}
    </div>
  );
};
const CartTotal = ({ totalData }) => {
  const [selectedCoupon, setSelectedCoupon] =
    useRecoilState(selectedCouponAtom);

  let couponData = selectedCoupon?.r || {};

  return (
    <div className="cartTotal title_medium">
      <div className="wrapper">
        <div>Subtotal</div>
        <div>Rs. {couponData.amount ?? totalData.subTotal}</div>
      </div>
      <div className="wrapper">
        <div>Tax</div>
        <div>Rs. {couponData.tax_amount ?? totalData.tax}</div>
      </div>
      <div className="wrapper">
        <div>Coupon Off</div>
        {totalData.coupon > 0 ? (
          <div
            style={{
              color: "red",
            }}
          >
            - Rs. {couponData.coupon_discount ?? totalData.coupon}
          </div>
        ) : (
          <div>Rs. {couponData.coupon_discount ?? totalData.coupon}</div>
        )}
      </div>
      <Divider customStyle={{ backgroundColor: "#E9EEF2" }} />
      <div className="wrapper total">
        <div>Total</div>
        <div>Rs. {couponData.total_amount ?? totalData.total}</div>
      </div>
    </div>
  );
};
