/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useRecoilValue } from "recoil";
import privateAxios from "../../axios/privateAxios";
import { toastAtom } from "../../state/atoms/toastAtom";
import { userAtom } from "../../state/atoms/userAtom";
import { authAtom } from "../../state/atoms/authAtom";
import publicAxios from "../../axios/publicAxios";

export const useProfile = () => {
  const profileRef = useRef();
  const [values, setValues] = useRecoilState(userAtom);
  const [, setShowToast] = useRecoilState(toastAtom);
  const auth = useRecoilValue(authAtom);
  console.log(values);
  useEffect(() => {
    const fetchProfile = () => {
      privateAxios
        .get("/account/profile", {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          let data = {
            id: res.data.id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            email_verified: res.data.email_verified,
            email: res.data.email,
            phone: res.data.phone,
            city: res.data.city,
            state: res.data.state,
            address: res.data.address,
            country: "IN",
            profile_image: {
              id: null,
              value: null,
              url: "",
            },
          };
          setValues(data);
        })
        .catch((e) => {
          console.log("error", e);
        });
    };
    fetchProfile();
  }, []);

  const handleOnChange = (e) => {
    if (e.target.name === "profile_image") {
      const resourceId = values.profile_image.id;
      uploadResource("", "image", e.target.files[0], resourceId).then((res) => {
        console.log(res.data.id);
        setValues((pre) => ({
          ...pre,
          [e.target.name]: {
            value: e.target.files[0],
            id: res.data.id,
            url: URL.createObjectURL(e.target.files[0]),
          },
        }));
      });
    } else setValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };
  const uploadResource = (id, type, file, resourceId) => {
    console.log(file);
    const fileData = new FormData();
    //  type === 'video' && fileData.append('name',file.name)
    fileData.append("file", file);
    fileData.append("type", type);
    return publicAxios
      .post(
        `v2/${resourceId ? "resource/" + resourceId : "resources"}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const updateProfile = () => {
      // const { profile_image, ...request_body } = values;
      // let formdata = new FormData();
      // for (let key in request_body) {
      //   formdata.append(key, request_body[key]);
      // }
      const req_body = {
        // username:values.username ? values.phone:'',
        first_name: values.first_name,
        last_name: values.last_name,
        state: values.state,
        city: values.city,
        // password:values.password,
        email: values.email,
        phone: values.phone,
        resource_id: values.profile_image.id,
        //role:values.role,
        //designation:values.designation,
        //additional_details:values.additional_details,
        address: values.address,
        //first_login:values.first_login,
        //joining_date:"2023-01-12"
      };
      // console.log(formdata)
      const successToast = {
        status: true,
        type: "success",
        successMessage: "updated successfully",
      };
      privateAxios
        .post(`/account/profile`, req_body, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + auth.access,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setShowToast(successToast);
        })
        .catch((e) => {
          const successToast = {
            status: true,
            type: "error",
            errorMessage: "Something went wrong, please try again!",
          };
          setShowToast(successToast);
          console.log("error", e);
        });
    };
    updateProfile();
  };

  const handleVarifyEmail = () => {
    const req_body = {
      email_verify: values.email,
    };
    privateAxios
      .post(`/email/verify`, null, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  return {
    values: values,
    profileRef: profileRef,
    handleOnChange: handleOnChange,
    handleSubmit: handleSubmit,
    handleVarifyEmail: handleVarifyEmail,
  };
};
