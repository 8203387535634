import { Lecturesection } from "./Lecturesection";
import { useCourseSection } from "./useCourseSection";
import { CoursesectionWrapper } from "./CoursesectionWrapper";
import { CoursesectionDropdown } from "./CoursesectionDropdown";
import { useEffect, useState } from "react";
import "./Coursesection.scss";

export const Coursesection = ({ course, coursesection, courseId }) => {
  const { opensection, openLecture, handleOpenLecture, handleOpenSection } =
    useCourseSection(course, coursesection, courseId);

  console.log("7777777777777777777777777777777777777", course);

  return (
    <div className="coursesection">
      {console.log("course111", course)}
      <ul>
        {course.map(
          (item, key) =>
            item?.resources && (
              <li key={`coursesection-key-${key}`}>
                <CoursesectionWrapper
                  section={item?.name}
                  open_status={opensection[key]}
                  index={key}
                  handleOpenSection={handleOpenSection}
                />
                {item?.resources.length > 0 && (
                  <CoursesectionDropdown open_status={opensection[key]}>
                    {item?.resources.map(
                      (lecture, lecture_key) =>
                        lecture?.type === "video" && (
                          <>
                            <Lecturesection
                              first_key={key}
                              sequence_number={lecture.sequence_number}
                              key={`lecturesection-${lecture?.id}`}
                              count={lecture_key}
                              resources={item.resources}
                              lecture={lecture}
                              coursesectionId={item?.id}
                              openLecture={openLecture}
                              handleOpenLecture={handleOpenLecture}
                            />
                          </>
                        )
                    )}
                  </CoursesectionDropdown>
                )}
              </li>
            )
        )}
      </ul>
    </div>
  );
};
