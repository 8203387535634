import React from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { Stack } from "@mui/material";
import "../User.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { StudentTable } from "../../../components/AdminStudent/StudentTable";
import { EvaluationTable } from "../../../components/AdminTest/EvaluationTable";
export const EvaluationMgmt = () => {
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash">
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack >
            <div className="all-user-strip">Comprehensive Evaluation</div>
            <div className="userTable">
              <EvaluationTable />
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
