import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import privateAxios from "../../axios/privateAxios";

export const useCart = () => {
  const navigate = useNavigate();
  const [emptyCart, setEmptyCart] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [totalData, setTotalData] = useState({
    subTotal: 0,
    tax: 0,
    coupon: 0,
    total: 0,
  });
  const [initial, setInitial] = useState({
    subTotal: 0,
    tax: 0,
    coupon: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const auth = useRecoilValue(authAtom);
  /* update subtotal and total */
  useEffect(() => {
    let subTotal, tax, total;
    subTotal = tax = total = 0;

    cartData &&
      cartData.forEach((element) => {
        subTotal += parseFloat(element?.amount);
        tax += parseFloat(element?.tax_amount);
        total += parseFloat(element?.total_amount);
      });

    setTotalData((pre) => ({
      ...pre,
      subTotal: subTotal,
      tax: tax,
      total: total,
    }));
    setInitial((pre) => ({
      ...pre,
      subTotal: subTotal,
      tax: tax,
      total: total,
    }));
  }, [cartData]);

  const setTotalDataWithCoupon = (couponPrice) => {
    const subTotalAfterCoupon = initial.subTotal - couponPrice;
    let total = subTotalAfterCoupon + initial.tax;
    total = total.toFixed(2);

    setTotalData((prevState) => ({
      ...prevState,
      coupon: couponPrice.toFixed(2),
      total: total,
    }));
  };

  /* load cart data */
  useEffect(() => {
    const loadCart = () => {
      setLoading(true);
      privateAxios
        .get("/cart", {
          headers: {
            Authorization: "Bearer " + auth?.access,
          },
        })
        .then((res) => {
          // 403
          if (res !== 403) {
            setCartData(res.data?.product_quantity);
            setLoading(false);
            document.getElementById("countIcon").innerText =
              res.data?.product_quantity.length;
          } else {
            navigate("/");
          }
        })
        .catch((e) => {
          if (e?.response?.status === 404) {
            setEmptyCart(true);
          }
          setLoading(false);
          console.log(e);
        });
    };
    loadCart();
  }, []);

  /* remove product from cart */
  const handleRemoveProductFromCart = (product) => {
    const removeFromCart = (type, id) => {
      let request_body = {};
      type === "COURSE"
        ? (request_body = {
            type: "COURSE",
            course_id: id,
          })
        : (request_body = {
            type: "TEST_SERIES",
            test_series_id: id,
          });

      privateAxios
        .delete("/cart", {
          data: request_body,
          headers: {
            Authorization: "Bearer " + auth?.access,
          },
        })
        .then((res) => {
          /** update the cart ui */
          setCartData((pre) => {
            const remainingProduct = pre.filter(
              (item) => item.course.id !== id
            );

            console.log(remainingProduct, id, "Asdasdasdasdsad");

            remainingProduct.length === 0 && navigate("/");

            const c = document.getElementById("countIcon");

            if (c) {
              c.innerText = remainingProduct.length;
            }

            return remainingProduct;
          });

          // console.log(res.data);
        })
        .catch((e) => console.log(e));
    };

    if (product) {
      /* remove from cart(backend) */
      if (product?.type === "COURSE")
        product?.course && removeFromCart(product?.type, product?.course?.id);
      else if (product?.type === "TEST_SERIES")
        product?.test_series &&
          removeFromCart(product?.type, product?.test_series?.id);
    }
  };

  return {
    cartData: cartData,
    totalData: totalData,
    loading: loading,
    emptyCart: emptyCart,
    setTotalData: setTotalData,
    handleRemoveProductFromCart: handleRemoveProductFromCart,
    setTotalDataWithCoupon: setTotalDataWithCoupon,
  };
};
