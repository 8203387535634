import { Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";

import Loader from "../../Tools/Loader";
import { DoubtModal } from "./DoubtModal";
import { VideoPlayer } from "../../VideoPlayer/VideoPlayer";
import { useLectureDetail } from "./useLectureDetail";

import "./LearningDetail.scss";
import DownloadIcon from "@mui/icons-material/Download";

export const LectureDetail = ({ course, handleFinishVideoMain }) => {
  const {
    openStatus,
    openDoubtModal,
    lectureDetailData,
    handleOpenStatus,
    handleDoubtClose,
  } = useLectureDetail();

  return (
    <div>
      {lectureDetailData?.loading ? (
        <Loader />
      ) : (
        <>
          {lectureDetailData?.lecture?.type === "video" && (
            <VideoPlayer
              videoId="1"
              videoType={"mp4"}
              videoDuration={lectureDetailData?.lecture?.duration}
              videoURL={lectureDetailData?.lecture?.url}
              handleFinishVideo={handleFinishVideoMain}
              lectureDetailData={lectureDetailData}
            />
          )}
          <div className="lecturedetail">
            <div className="navbar title_large">
              <ul>
                {/* <li
                  className={openStatus?.overview ? "active" : undefined}
                  onClick={() => handleOpenStatus("overview")}
                >
                  Overview
                </li> */}
                {/*<li*/}
                {/*  className={openStatus?.question ? "active" : undefined}*/}
                {/*  onClick={() => handleOpenStatus("question")}*/}
                {/*>*/}
                {/*  Ask a Doubt*/}
                {/*</li>*/}
                <li
                  className={openStatus?.resource ? "active" : undefined}
                  onClick={() => handleOpenStatus("resource")}
                >
                  Resources
                </li>
              </ul>
            </div>
            <div
              className={`content body_large ${
                openStatus?.overview ? "content-active" : undefined
              }`}
            >
              {lectureDetailData?.lecture?.overview}
            </div>

            {/*<div*/}
            {/*  className={`content body_large ${*/}
            {/*    openStatus?.question ? "content-active" : undefined*/}
            {/*  }`}*/}
            {/*>*/}
            {/*  <p className="title_medium">*/}
            {/*    You can ask any doubt regarding the course to the Educator.*/}
            {/*  </p>*/}
            {/*  <Button variant="contained" onClick={handleDoubtOpen}>*/}
            {/*    Ask a Doubt*/}
            {/*  </Button>*/}
            {/*</div>*/}

            <div
              className={`content body_large ${
                openStatus?.resource ? "content-active" : undefined
              }`}
            >
              {/*{course &&*/}
              {/*  // eslint-disable-next-line array-callback-return*/}
              {/*  course*/}
              {/*    .filter((ele) => ele.id === 10)[0]*/}
              {/*    ?.resources.map((item, key) => {*/}
              {/*      console.log("99999999999999999999999999999", item);*/}
              {/*      if (item?.type === "image") {*/}
              {/*        return (*/}
              {/*          <React.Fragment*/}
              {/*            key={`lecture-detail-${item?.id}-${key}`}*/}
              {/*          >*/}
              {/*            <h4>{item?.name}</h4>*/}
              {/*            <p>*/}
              {/*              <a href={item?.url}>{item?.url}</a>*/}
              {/*            </p>*/}
              {/*          </React.Fragment>*/}
              {/*        );*/}
              {/*      } else if (item?.type === "document") {*/}
              {/*        return (*/}
              {/*          <React.Fragment*/}
              {/*            key={`lecture-detail-${item?.id}-${key}`}*/}
              {/*          >*/}
              {/*            <h4>item?.name</h4>*/}
              {/*            <p>*/}
              {/*              <a href={item?.url}>{item?.url}</a>*/}
              {/*            </p>*/}
              {/*          </React.Fragment>*/}
              {/*        );*/}
              {/*      } else if (item?.type === "test") {*/}
              {/*        return (*/}
              {/*          <React.Fragment*/}
              {/*            key={`lecture-detail-${item?.id}-${key}`}*/}
              {/*          >*/}
              {/*            <h4>{item?.name}</h4>*/}
              {/*            <p>*/}
              {/*              <a href={item?.url}>{item?.url}</a>*/}
              {/*            </p>*/}
              {/*          </React.Fragment>*/}
              {/*        );*/}
              {/*      }*/}
              {/*    })}*/}

              {course &&
                course.map((chapter) => (
                  <React.Fragment key={`chapter-${chapter.id}`}>
                    <h3>{chapter.name}</h3>
                    {chapter.resources.map((item, key) => {
                      if (
                        item.type === "document" ||
                        item.type === "test" ||
                        item.type === "image"
                      ) {
                        return (
                          <Card
                            key={`resource-${item.id}`}
                            variant="outlined"
                            style={{ marginTop: "1rem", width: "50%" }}
                          >
                            <CardContent>
                              <Typography variant="h5" component="h2">
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    fontSize: "18px",
                                    color: "gray",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p> {item.name || item.type}</p>
                                  <DownloadIcon />
                                </a>
                              </Typography>
                            </CardContent>
                          </Card>
                        );
                      }
                      return null; // Exclude items of type "video"
                    })}
                  </React.Fragment>
                ))}
            </div>
            <DoubtModal open={openDoubtModal} handleClose={handleDoubtClose} />
          </div>
        </>
      )}
    </div>
  );
};
