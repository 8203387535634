export const title1 = "Enter mail id";
export const title2 = "Enter OTP";
export const description1 = "6 Digit OTP will be sent to your email";
export const description2 = "We've sent an OTP to your Email id";
export const initialValues = {
  // phone: "+91",
  // country_code: "in",
  // country_dialcode: "91",
  // otp: "",
  // first_name: "",
  // last_name: "",
  email: "",
};
export const initialHero = {
  title: title1,
  description: description1,
};
export const initialToggleParams = {
  phoneDisabled: false,
  showResend: false,
  showReset: false,
  inputFields: [],
  loginStep: 1,
  login_user: false,
};
export const textFieldStyle = {
  color: "#3c4852",
  width: "100%",
  marginTop: "1rem",
};
export const inputFields = [
  {
    id: 1,
    name: "otp",
    label: "OTP",
    type: "text",
    placeholder: "One Time Password",
    inputProps: { maxLength: 6, minLength: 6 },
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 2,
    name: "first_name",
    label: "First Name",
    type: "text",
    placeholder: "First Name",
    inputProps: { maxLength: 50 },
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 3,
    name: "last_name",
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
    inputProps: { maxLength: 50 },
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 4,
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Enter a password",
    inputProps: { maxLength: 50 },
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 5,
    name: "reenterpassword",
    label: "Re-enter password",
    type: "password",
    placeholder: "Re-enter password",
    inputProps: { maxLength: 50 },
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 6,
    name: "state",
    label: "State",
    type: "select",
    placeholder: "State of residence",
    inputProps: { maxLength: 50 },
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 7,
    name: "phone",
    label: "Enter your phone number",
    type: "phone_number",
    placeholder: "Phone number",
    sx: textFieldStyle,
    required: true,
  },
  {
    id: 8,
    name: "checkbox",
    label: (
      <>
        <span>I agree to Study Glows </span>
        <a href="/reading/terms_and_condition" target="_blank">
          Terms & Privacy
        </a>
      </>
    ),
    type: "checkbox",
    placeholder: "",
    sx: textFieldStyle,
    required: true,
  },
];



export const countryCodeSelect = [
  { label: 'Afghanistan (+93)', value: '+93', code: 'AF' },
  { label: 'Albania (+355)', value: '+355', code: 'AL' },
  { label: 'Algeria (+213)', value: '+213', code: 'DZ' },
  { label: 'Andorra (+376)', value: '+376', code: 'AD' },
  { label: 'Angola (+244)', value: '+244', code: 'AO' },
  { label: 'Antigua and Barbuda (+1-268)', value: '+1-268', code: 'AG' },
  { label: 'Argentina (+54)', value: '+54', code: 'AR' },
  { label: 'Armenia (+374)', value: '+374', code: 'AM' },
  { label: 'Australia (+61)', value: '+61', code: 'AU' },
  { label: 'Austria (+43)', value: '+43', code: 'AT' },
  { label: 'Azerbaijan (+994)', value: '+994', code: 'AZ' },
  { label: 'Bahamas (+1-242)', value: '+1-242', code: 'BS' },
  { label: 'Bahrain (+973)', value: '+973', code: 'BH' },
  { label: 'Bangladesh (+880)', value: '+880', code: 'BD' },
  { label: 'Barbados (+1-246)', value: '+1-246', code: 'BB' },
  { label: 'Belarus (+375)', value: '+375', code: 'BY' },
  { label: 'Belgium (+32)', value: '+32', code: 'BE' },
  { label: 'Belize (+501)', value: '+501', code: 'BZ' },
  { label: 'Benin (+229)', value: '+229', code: 'BJ' },
  { label: 'Bhutan (+975)', value: '+975', code: 'BT' },
  { label: 'Bolivia (+591)', value: '+591', code: 'BO' },
  { label: 'Bosnia and Herzegovina (+387)', value: '+387', code: 'BA' },
  { label: 'Botswana (+267)', value: '+267', code: 'BW' },
  { label: 'Brazil (+55)', value: '+55', code: 'BR' },
  { label: 'Brunei (+673)', value: '+673', code: 'BN' },
  { label: 'Bulgaria (+359)', value: '+359', code: 'BG' },
  { label: 'Burkina Faso (+226)', value: '+226', code: 'BF' },
  { label: 'Burundi (+257)', value: '+257', code: 'BI' },
  { label: 'Cabo Verde (+238)', value: '+238', code: 'CV' },
  { label: 'Cambodia (+855)', value: '+855', code: 'KH' },
  { label: 'Cameroon (+237)', value: '+237', code: 'CM' },
  { label: 'Canada (+1)', value: '+1', code: 'CA' },
  { label: 'Central African Republic (+236)', value: '+236', code: 'CF' },
  { label: 'Chad (+235)', value: '+235', code: 'TD' },
  { label: 'Chile (+56)', value: '+56', code: 'CL' },
  { label: 'China (+86)', value: '+86', code: 'CN' },
  { label: 'Colombia (+57)', value: '+57', code: 'CO' },
  { label: 'Comoros (+269)', value: '+269', code: 'KM' },
  { label: 'Congo (+242)', value: '+242', code: 'CG' },
  { label: 'Costa Rica (+506)', value: '+506', code: 'CR' },
  { label: 'Croatia (+385)', value: '+385', code: 'HR' },
  { label: 'Cuba (+53)', value: '+53', code: 'CU' },
  { label: 'Cyprus (+357)', value: '+357', code: 'CY' },
  { label: 'Czech Republic (+420)', value: '+420', code: 'CZ' },
  { label: 'Denmark (+45)', value: '+45', code: 'DK' },
  { label: 'Djibouti (+253)', value: '+253', code: 'DJ' },
  { label: 'Dominica (+1-767)', value: '+1-767', code: 'DM' },
  { label: 'Dominican Republic (+1-809)', value: '+1-809', code: 'DO' },
  { label: 'East Timor (+670)', value: '+670', code: 'TL' },
  { label: 'Ecuador (+593)', value: '+593', code: 'EC' },
  { label: 'Egypt (+20)', value: '+20', code: 'EG' },
  { label: 'El Salvador (+503)', value: '+503', code: 'SV' },
  { label: 'Equatorial Guinea (+240)', value: '+240', code: 'GQ' },
  { label: 'Eritrea (+291)', value: '+291', code: 'ER' },
  { label: 'Estonia (+372)', value: '+372', code: 'EE' },
  { label: 'Eswatini (+268)', value: '+268', code: 'SZ' },
  { label: 'Ethiopia (+251)', value: '+251', code: 'ET' },
  { label: 'Fiji (+679)', value: '+679', code: 'FJ' },
  { label: 'Finland (+358)', value: '+358', code: 'FI' },
  { label: 'France (+33)', value: '+33', code: 'FR' },
  { label: 'Gabon (+241)', value: '+241', code: 'GA' },
  { label: 'Gambia (+220)', value: '+220', code: 'GM' },
  { label: 'Georgia (+995)', value: '+995', code: 'GE' },
  { label: 'Germany (+49)', value: '+49', code: 'DE' },
  { label: 'Ghana (+233)', value: '+233', code: 'GH' },
  { label: 'Greece (+30)', value: '+30', code: 'GR' },
  { label: 'Grenada (+1-473)', value: '+1-473', code: 'GD' },
  { label: 'Guatemala (+502)', value: '+502', code: 'GT' },
  { label: 'Guinea (+224)', value: '+224', code: 'GN' },
  { label: 'Guinea-Bissau (+245)', value: '+245', code: 'GW' },
  { label: 'Guyana (+592)', value: '+592', code: 'GY' },
  { label: 'Haiti (+509)', value: '+509', code: 'HT' },
  { label: 'Honduras (+504)', value: '+504', code: 'HN' },
  { label: 'Hungary (+36)', value: '+36', code: 'HU' },
  { label: 'Iceland (+354)', value: '+354', code: 'IS' },
  { label: 'India (+91)', value: '+91', code: 'IN' },
  { label: 'Indonesia (+62)', value: '+62', code: 'ID' },
  { label: 'Iran (+98)', value: '+98', code: 'IR' },
  { label: 'Iraq (+964)', value: '+964', code: 'IQ' },
  { label: 'Ireland (+353)', value: '+353', code: 'IE' },
  { label: 'Israel (+972)', value: '+972', code: 'IL' },
  { label: 'Italy (+39)', value: '+39', code: 'IT' },
  { label: 'Jamaica (+1-876)', value: '+1-876', code: 'JM' },
  { label: 'Japan (+81)', value: '+81', code: 'JP' },
  { label: 'Jordan (+962)', value: '+962', code: 'JO' },
  { label: 'Kazakhstan (+7)', value: '+7', code: 'KZ' },
  { label: 'Kenya (+254)', value: '+254', code: 'KE' },
  { label: 'Kiribati (+686)', value: '+686', code: 'KI' },
  { label: 'Korea, North (+850)', value: '+850', code: 'KP' },
  { label: 'Korea, South (+82)', value: '+82', code: 'KR' },
  { label: 'Kosovo (+383)', value: '+383', code: 'XK' },
  { label: 'Kuwait (+965)', value: '+965', code: 'KW' },
  { label: 'Kyrgyzstan (+996)', value: '+996', code: 'KG' },
  { label: 'Laos (+856)', value: '+856', code: 'LA' },
  { label: 'Latvia (+371)', value: '+371', code: 'LV' },
  { label: 'Lebanon (+961)', value: '+961', code: 'LB' },
  { label: 'Lesotho (+266)', value: '+266', code: 'LS' },
  { label: 'Liberia (+231)', value: '+231', code: 'LR' },
  { label: 'Libya (+218)', value: '+218', code: 'LY' },
  { label: 'Liechtenstein (+423)', value: '+423', code: 'LI' },
  { label: 'Lithuania (+370)', value: '+370', code: 'LT' },
  { label: 'Luxembourg (+352)', value: '+352', code: 'LU' },
  { label: 'Madagascar (+261)', value: '+261', code: 'MG' },
  { label: 'Malawi (+265)', value: '+265', code: 'MW' },
  { label: 'Malaysia (+60)', value: '+60', code: 'MY' },
  { label: 'Maldives (+960)', value: '+960', code: 'MV' },
  { label: 'Mali (+223)', value: '+223', code: 'ML' },
  { label: 'Malta (+356)', value: '+356', code: 'MT' },
  { label: 'Marshall Islands (+692)', value: '+692', code: 'MH' },
  { label: 'Mauritania (+222)', value: '+222', code: 'MR' },
  { label: 'Mauritius (+230)', value: '+230', code: 'MU' },
  { label: 'Mexico (+52)', value: '+52', code: 'MX' },
  { label: 'Micronesia (+691)', value: '+691', code: 'FM' },
  { label: 'Moldova (+373)', value: '+373', code: 'MD' },
  { label: 'Monaco (+377)', value: '+377', code: 'MC' },
  { label: 'Mongolia (+976)', value: '+976', code: 'MN' },
  { label: 'Montenegro (+382)', value: '+382', code: 'ME' },
  { label: 'Morocco (+212)', value: '+212', code: 'MA' },
  { label: 'Mozambique (+258)', value: '+258', code: 'MZ' },
  { label: 'Myanmar (+95)', value: '+95', code: 'MM' },
  { label: 'Namibia (+264)', value: '+264', code: 'NA' },
  { label: 'Nauru (+674)', value: '+674', code: 'NR' },
  { label: 'Nepal (+977)', value: '+977', code: 'NP' },
  { label: 'Netherlands (+31)', value: '+31', code: 'NL' },
  { label: 'New Zealand (+64)', value: '+64', code: 'NZ' },
  { label: 'Nicaragua (+505)', value: '+505', code: 'NI' },
  { label: 'Niger (+227)', value: '+227', code: 'NE' },
  { label: 'Nigeria (+234)', value: '+234', code: 'NG' },
  { label: 'North Macedonia (+389)', value: '+389', code: 'MK' },
  { label: 'Norway (+47)', value: '+47', code: 'NO' },
  { label: 'Oman (+968)', value: '+968', code: 'OM' },
  { label: 'Pakistan (+92)', value: '+92', code: 'PK' },
  { label: 'Palau (+680)', value: '+680', code: 'PW' },
  { label: 'Palestine (+970)', value: '+970', code: 'PS' },
  { label: 'Panama (+507)', value: '+507', code: 'PA' },
  { label: 'Papua New Guinea (+675)', value: '+675', code: 'PG' },
  { label: 'Paraguay (+595)', value: '+595', code: 'PY' },
  { label: 'Peru (+51)', value: '+51', code: 'PE' },
  { label: 'Philippines (+63)', value: '+63', code: 'PH' },
  { label: 'Poland (+48)', value: '+48', code: 'PL' },
  { label: 'Portugal (+351)', value: '+351', code: 'PT' },
  { label: 'Qatar (+974)', value: '+974', code: 'QA' },
  { label: 'Romania (+40)', value: '+40', code: 'RO' },
  { label: 'Russia (+7)', value: '+7', code: 'RU' },
  { label: 'Rwanda (+250)', value: '+250', code: 'RW' },
  { label: 'Saint Kitts and Nevis (+1-869)', value: '+1-869', code: 'KN' },
  { label: 'Saint Lucia (+1-758)', value: '+1-758', code: 'LC' },
  { label: 'Saint Vincent and the Grenadines (+1-784)', value: '+1-784', code: 'VC' },
  { label: 'Samoa (+685)', value: '+685', code: 'WS' },
  { label: 'San Marino (+378)', value: '+378', code: 'SM' },
  { label: 'Sao Tome and Principe (+239)', value: '+239', code: 'ST' },
  { label: 'Saudi Arabia (+966)', value: '+966', code: 'SA' },
  { label: 'Senegal (+221)', value: '+221', code: 'SN' },
  { label: 'Serbia (+381)', value: '+381', code: 'RS' },
  { label: 'Seychelles (+248)', value: '+248', code: 'SC' },
  { label: 'Sierra Leone (+232)', value: '+232', code: 'SL' },
  { label: 'Singapore (+65)', value: '+65', code: 'SG' },
  { label: 'Slovakia (+421)', value: '+421', code: 'SK' },
  { label: 'Slovenia (+386)', value: '+386', code: 'SI' },
  { label: 'Solomon Islands (+677)', value: '+677', code: 'SB' },
  { label: 'Somalia (+252)', value: '+252', code: 'SO' },
  { label: 'South Africa (+27)', value: '+27', code: 'ZA' },
  { label: 'South Sudan (+211)', value: '+211', code: 'SS' },
  { label: 'Spain (+34)', value: '+34', code: 'ES' },
  { label: 'Sri Lanka (+94)', value: '+94', code: 'LK' },
  { label: 'Sudan (+249)', value: '+249', code: 'SD' },
  { label: 'Suriname (+597)', value: '+597', code: 'SR' },
  { label: 'Sweden (+46)', value: '+46', code: 'SE' },
  { label: 'Switzerland (+41)', value: '+41', code: 'CH' },
  { label: 'Syria (+963)', value: '+963', code: 'SY' },
  { label: 'Taiwan (+886)', value: '+886', code: 'TW' },
  { label: 'Tajikistan (+992)', value: '+992', code: 'TJ' },
  { label: 'Tanzania (+255)', value: '+255', code: 'TZ' },
  { label: 'Thailand (+66)', value: '+66', code: 'TH' },
  { label: 'Togo (+228)', value: '+228', code: 'TG' },
  { label: 'Tonga (+676)', value: '+676', code: 'TO' },
  { label: 'Trinidad and Tobago (+1-868)', value: '+1-868', code: 'TT' },
  { label: 'Tunisia (+216)', value: '+216', code: 'TN' },
  { label: 'Turkey (+90)', value: '+90', code: 'TR' },
  { label: 'Turkmenistan (+993)', value: '+993', code: 'TM' },
  { label: 'Tuvalu (+688)', value: '+688', code: 'TV' },
  { label: 'Uganda (+256)', value: '+256', code: 'UG' },
  { label: 'Ukraine (+380)', value: '+380', code: 'UA' },
  { label: 'United Arab Emirates (+971)', value: '+971', code: 'AE' },
  { label: 'United Kingdom (+44)', value: '+44', code: 'GB' },
  { label: 'United States (+1)', value: '+1', code: 'US' },
  { label: 'Uruguay (+598)', value: '+598', code: 'UY' },
  { label: 'Uzbekistan (+998)', value: '+998', code: 'UZ' },
  { label: 'Vanuatu (+678)', value: '+678', code: 'VU' },
  { label: 'Vatican City (+379)', value: '+379', code: 'VA' },
  { label: 'Venezuela (+58)', value: '+58', code: 'VE' },
  { label: 'Vietnam (+84)', value: '+84', code: 'VN' },
  { label: 'Yemen (+967)', value: '+967', code: 'YE' },
  { label: 'Zambia (+260)', value: '+260', code: 'ZM' },
  { label: 'Zimbabwe (+263)', value: '+263', code: 'ZW' },
];

