import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import dayjs from "dayjs";
import {
  Button,
  Stack,
  Grid,
  Divider,
  Typography,
  TextField,
  Checkbox,
  Chip,
  ListItem,
 // Paper,
  styled,
  Select,
  MenuItem,
 // FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
function AddTestSeriesForm1({
  checkedState,
  formData,
  handleChange,
  handleFeatureChange,
  handleStartDateChange,
  handleThumbnailImage,
  createCourse,
  allCategories,
  allFeatures,
  instructors,
}) {
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
     console.log(formData);
  const formatStartDate = (startDateString) => {
    if (!startDateString) return ''; // Handle cases where startDateString is empty or undefined
    const startDate = new Date(startDateString);
    return startDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long',year: 'numeric' });
  };

  const handleCheckBox = (featureId,checked) => {
    // Toggle the state for the clicked checkbox
    
    
  // Update the form data with the selected features
 
  handleFeatureChange({ target: { name: featureId,checked:checked} });
  };
  return (
    <Grid container justifyContent="space-between" alignItems="space-between">
      <Grid item xs={12} md={4}>
        <div className="left-side-profile">
          <Stack
            direction="column"
            sx={{
              background: "#FFF",
              boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
              borderRadius: "5px",
            }}
          >
            <Grid
              className="profile-card"
              container
              gap={3}
              justifyContent="center"
              marginBottom={2}
            >
              <Grid item xs={12} justifyContent="center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Button
                            role={undefined}
                            variant="outlined"
                            > */}
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    // color="neutral"
                  > {
                    console.log(formData.thumbnail.value)
                  }
                    <img
                      src={ 
                       formData.thumbnail.value ? URL.createObjectURL(formData.thumbnail.value) : formData.thumbnail?.url
                      }
                      height={200}
                      width={250}
                      style={{ objectFit: "contain" }}
                      alt="Course Thumbnail"
                    />
                    {/* Upload the Thumbnail */}
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleThumbnailImage}
                    />
                  </Button>
                </div>
              </Grid>
              <span style={{ fontWeight: "bold" }}>{formData.title.value}</span>
              <Divider sx={{ marginTop: "5%", marginBottom: "5%" }} />
            </Grid>
          </Stack>
          {/* <Stack
            direction="column"
            sx={{
              background: "#FFF",
              boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
              marginTop: "5%",
              paddingTop: "5%",
              borderRadius: "5px",
            }}
          >
            <Grid
              className="profile-card"
              container
              gap={3}
              sx={{ textAlign: "start" }}
            >
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  justifyContent="space-around"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-date">About Course</div>
                  <div
                    className="course-price"
                    style={{ marginTop: "2%", marginoBottom: "2%" }}
                  >
                    <Typography variant="caption">
                     {formData.about.value}
                    </Typography>
                  </div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Duration</div>
                  <div className="course-date">{formData.duration.value} Months</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                  alignItems="center"
                >
                  <div className="course-price">Professor</div>
                  <div className="course-date" style={{ fontSize: "0.8rem" }}>
                    {instructors && formData.instructors.value && instructors
                        .filter((ele) => formData.instructors.value.includes(ele.id))
                        .map((ele) => ele.name).join()}
                  </div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Price</div>
                  <div className="course-date">₹{formData.mrp.value}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Date Launched</div>
                  <div className="course-date">{formatStartDate(formData.startDate.value)}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
            </Grid>
          </Stack> */}
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormGroup
          className="right-side-profile"
          style={{ background: "white" }}
        >
          <Stack>
            <div
              style={{
                textAlign: "start",
                color: "#666",
                marginBottom: "0.5rem",
              }}
            >
              <Typography>Name of The Test Series</Typography>
            </div>
            <div style={{ marginBottom: "1.2rem" }}>
              <TextField
                name="title"
                onChange={handleChange}
                value={formData.title.value}
                fullWidth
                error={formData.title.error}
                helperText={formData.title.error && formData.title.message}
                inputProps={{ style: { height: "10px" } }}
              />
            </div>
            
            <div
              style={{
                textAlign: "start",
                color: "#666",
                marginBottom: "0.5rem",
              }}
            >
              <Typography>Test Series Features</Typography>
            </div>
            <div style={{ marginBottom: "0.5rem" }}>
              <FormGroup row fullWidth>
                <Grid container>
                  {allFeatures.map((feature) =>  (
                    <Grid key={feature.id} item sm={4}>
                      <FormControlLabel
                        name={feature.id}
                        control={<Checkbox  checked={checkedState[feature.id] || false}
                        onChange={(e) => handleCheckBox(feature.id,e.target.checked)} />}
                        label={feature.name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </div>
            <div
              style={{
                textAlign: "start",
                color: "#666",
                marginBottom: "0.5rem",
              }}
            >
              <Typography>About The Test Series</Typography>
            </div>
            <div style={{ marginBottom: "1.2rem" }}>
              <TextField
                multiline
                rows={4} 
                name="about"
                onChange={handleChange}
                value={formData.about.value}
                fullWidth
                error={formData.about.error}
                helperText={formData.about.error && formData.about.message}
              />
            </div>
            <div
              style={{
                textAlign: "start",
                color: "#666",
                marginBottom: "0.5rem",
              }}
            >
              <Grid container alignItems="center">
                <Grid item md={12} paddingLeft="1rem">
                  <Stack direction="row" alignItems="center" gap={3}>
                    <Stack direction="column">
                      <Typography>Start From</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(formData.startDate.value)}
                          onChange={handleStartDateChange}
                        />
                      </LocalizationProvider>
                    </Stack>
                    <Stack direction="column">
                      <Typography>Sale Price</Typography>
                      <TextField
                        name="mrp"
                        type="number"
                        onChange={handleChange}
                        value={formData.mrp.value}
                      />
                    </Stack>
                    <Stack direction="column">
                      <Typography>Discounted Price</Typography>
                      <TextField
                        name="price"
                        type="number"
                        onChange={handleChange}
                        value={formData.price.value}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container marginTop="1rem">
                <Grid item md={6}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Stack direction="column" width="100%" marginTop="80px">
                      <Typography>Test Series Content</Typography>
                      {/* <TextField
                        fullWidth
                        name="language"
                        onChange={handleChange}
                        value={formData.language.value}
                      /> */}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={6} paddingLeft="1rem">
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Stack direction="row" gap={2} marginTop="4.5rem">
                      <Button variant="contained" onClick={createCourse}>
                        Create a Topic
                      </Button>
                      <Button variant="outlined" startIcon={<DeleteIcon />}></Button>
                    </Stack>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default AddTestSeriesForm1;
