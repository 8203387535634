import {
  Box,
  Button,
  Grid,
  //IconButton,
  Modal,
  Typography,
} from "@mui/material";

import { ReactComponent as UploadIcon } from "../../assets/svg/admin/cloud_upload.svg";
import { useState } from "react";
import { getFormattedTime } from "../../utils";
import { ReactComponent as DeleteIcon } from "../../assets/svg/admin/trash-can-solid.svg";
import publicAxios from "../../axios/publicAxios";
import Loader from "../Tools/Loader";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import axios from "axios";
// import S3 from 'aws-s3';
import AWS, { S3 } from "aws-sdk";

// AWS_ACCESS_KEY_ID=AKIAXCZ4E45K4M5TF5ND
// AWS_SECRET_ACCESS_KEY=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
// AWS_STORAGE_BUCKET_NAME=live-studyglow

const S3_BUCKET = "live-studyglow";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAXCZ4E45K4M5TF5ND",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function UploadVideoDialog({
  role,
  chapterSelected,
  open,
  handleClose,
  handleVideosConfirm,
  uploadResource,
}) {
  const auth = useRecoilValue(authAtom);
  const [videos, setVideos] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const access = "4nY5XeAFeIMVlZFRby0HG3c9jhzlSc6VmuTLOo4L"; // Replace this with your actual secret access key

  function onClose() {
    handleVideosConfirm(videos);
    setVideos([]);
    setImage([]);
    handleClose();
  }

  const uploadFileToS3 = async (url, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const headers = {
        "Content-Type": file.type,
      };

      const response = await axios.put(url, file, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressValue(percentCompleted);
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to upload video");
      }

      console.log("Video uploaded successfully");
    } catch (error) {
      console.error("There was a problem uploading the video:", error);
    }
  };

  async function setFileInfo({ target: { files } }) {
    console.log(files);
    setLoading(true);

    const processVideo = async (file) => {
      const resourceId = "";
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      video.src = URL.createObjectURL(file);
      video.load();
      video.play();
      video.muted = true;
      video.playsInline = true;

      await new Promise((resolve) => {
        video.onloadeddata = function () {
          setTimeout(() => {
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            file.thumbnail = canvas.toDataURL();
            file.duration = video.duration * 1000;
            resolve();
          }, 100);
        };
      });

      const res = await uploadResource(chapterSelected, role, file, resourceId);

      if (role === "video") {
        const policyData = res.data;
        const { pre_signed_url } = policyData;
        const { fields } = pre_signed_url;

        const url = pre_signed_url;
        await uploadFileToS3(url, file);

        const uploadRes = await publicAxios.post(
          `v2/resource/${res.data.resource_id}/uploaded`,
          { size: file.size },
          {
            headers: {
              Authorization: "Bearer " + auth.access,
            },
          }
        );
        console.log(uploadRes);
        video.muted = true;
        video.playsInline = true;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        file.id = uploadRes.data.id;
        setVideos((videos) => [
          ...videos,
          {
            id: file.id,
            name: file.name,
            size: file.size,
            thumbnail: file.thumbnail,
            duration: file.duration,
          },
        ]);
      }
    };

    try {
      // Convert the FileList to an array
      const filesArray = Array.from(files);
      if (role === "video") {
        await Promise.all(filesArray.map(processVideo));
      } else {
        // Upload images
        const imagePromises = filesArray.map(async (file) => {
          const res = await uploadResource(chapterSelected, role, file, "");
          file.id = res.data.id;
          return file;
        });
        const uploadedImages = await Promise.all(imagePromises);
        setImage((img) => [...img, ...uploadedImages]);
      }
      // All uploads are complete
      setLoading(false);
    } catch (error) {
      // Handle errors
      console.error("Error during uploads:", error);
      setLoading(false);
    }
  }

  // function setFileInfo({ target: { files } }) {
  //   console.log(files);
  //   setLoading(true);
  //   const processVideo = (file) => {
  //     const resourceId = '';
  //     const video = document.createElement("video");
  //     const canvas = document.createElement("canvas");
  //     video.src = URL.createObjectURL(file);
  //     video.load();
  //     video.play();
  //     video.muted = true;
  //     video.playsInline = true;
  //     video.onloadeddata = function () {
  //       setTimeout(() => {
  //         canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //         file.thumbnail = canvas.toDataURL();
  //         file.duration = video.duration * 1000;
  //         uploadResource(chapterSelected, role, file, resourceId).then((res) => {
  //           if (role === "video") {
  //             var policyData = res.data;
  //             var keyPath = new URL(policyData.pre_signed_url);
  //             publicAxios
  //             .put(`${keyPath}`,file, {
  //                headers: {
  //               "Content-Type": "multipart/form-data",
  //             },onUploadProgress: (progressEvent) => {
  //               const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //               console.log(percentCompleted);
  //               setProgressValue(percentCompleted)
  //               console.log(progressValue);
  //             },})
  //             .then((res1) => {
  //               publicAxios
  //               .post(`v2/resource/${res.data.resource_id}/uploaded`, { size: file.size })
  //               .then((res) => {
  //                 console.log(res);
  //                 video.muted = true;
  //                 video.playsInline = true;
  //                 canvas.width = video.videoWidth;
  //                 canvas.height = video.videoHeight;
  //                 file.id = res.data.id;
  //                 setVideos((videos) => [...videos, file]);
  //                 setLoading(false);
  //               })
  //               .catch((e) => {
  //                 // Handle error
  //               setLoading(false);

  //               });
  //             })
  //             .catch((e) => {
  //               // Handle error
  //              setLoading(false);

  //             });
  //           }
  //           //setLoading(false);
  //         });
  //       }, 100);
  //     };
  //   };

  //   for (let i = 0; i < files.length; i++) {
  //     if (role === "video") {
  //       processVideo(files[i]);
  //     } else if (role === "image") {
  //       setLoading(true);
  //       uploadResource(chapterSelected, role, files[i], '').then((res) => {
  //         files[i].id = res.data.id;
  //         setImage((img)=>[...img,files[i]]);
  //         setLoading(false);
  //       });
  //     }
  //   }
  // }
  function handleVideoDelete(videoId) {
    setVideos((videos) => videos.filter((video) => video.id !== videoId));
  }

  function handleImageDelete(imageId) {
    setImage([]);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      top="50%"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        borderRadius={2}
        bgcolor="white"
        width="80%"
        padding={2}
        margin="auto"
        maxHeight="80%"
        display="flex"
        flexDirection="column"
      >
        <Box paddingX={1} paddingY={1.25} borderBottom={1} borderColor="#ddd">
          <Typography fontWeight={700}>Upload a {role}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginY={3}
        >
          <Button
            component="label"
            startIcon={<UploadIcon />}
            variant="contained"
            disableElevation={true}
            sx={{
              fontWeight: "500",
              backgroundColor: "#0052CC",
              textTransform: "none",
            }}
          >
            Upload from your PC
            <input type="file" hidden multiple onChange={setFileInfo} />
          </Button>
          <Typography fontWeight={700} marginTop={2}>
            Supported file formats : mp4, webp, mkv. etc.
          </Typography>
          <Typography>
            Select multiple videos from your local storage.
          </Typography>
          {videos.length === 0 && (
            <>
              <Typography marginY={2}>or</Typography>
              <Button
                sx={{
                  fontWeight: "700",
                  color: "#42526E",
                  backgroundColor: "#091E420A",
                }}
              >
                Import from Library
              </Button>
            </>
          )}
        </Box>
        {loading && <Loader value={progressValue} />}
        <Box borderTop={1} borderColor="#ddd" sx={{ overflow: "auto" }}>
          {role === "video"
            ? videos.map((video) => (
                <VideoItem
                  key={video.id}
                  videoFile={video}
                  onDelete={handleVideoDelete.bind(this, video.id)}
                />
              ))
            : image.map((image) => (
                <ImageItem
                  key={image.id}
                  imageFile={image}
                  onDelete={handleImageDelete.bind(this)}
                />
              ))}
        </Box>
        <Box
          paddingX={1}
          paddingY={1.25}
          marginLeft="auto"
          borderTop={1}
          display="flex"
          gap={2}
          borderColor="#ddd"
          justifyContent="end"
        >
          <Button sx={{ color: "black" }} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            disableElevation={true}
            disabled={
              role === "video" ? videos.length === 0 : image.length === 0
            }
            onClick={onClose}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

function VideoItem({ videoFile, onDelete }) {
  return (
    <Grid
      container
      sm={12}
      padding={2}
      alignItems="center"
      borderBottom={1}
      borderColor="#ddd"
    >
      <Grid item sm={2} border={1}>
        <img
          height="100%"
          width="100%"
          style={{ objectFit: "contain" }}
          src={videoFile.thumbnail}
          alt={videoFile.name}
        />
      </Grid>
      <Grid item sm={6} marginX={3}>
        {videoFile.name}
      </Grid>
      <Grid item sm={2}>
        {getFormattedTime(videoFile.duration)}
      </Grid>
      <Grid item sm={1}>
        <Button
          aria-label="delete"
          sx={{ minWidth: "0", backgroundColor: "#C42730" }}
          onClick={onDelete}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
}

function ImageItem({ imageFile, onDelete }) {
  return (
    <Grid
      container
      sm={12}
      padding={2}
      alignItems="center"
      direction="row"
      sx={{ backgroundColor: "#ddd" }}
    >
      <Grid item sm={10} marginX={3}>
        <Typography fontWeight={700} color="#0373BA">
          {imageFile.name}
        </Typography>
      </Grid>
      <Grid item sm={1} marginLeft="auto">
        <Button
          aria-label="delete"
          sx={{ minWidth: "0", backgroundColor: "#C42730" }}
          onClick={onDelete}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
}
