import React, { useEffect, useState } from "react";
import { AdminNavbar } from "../../components/AdminNavbar/AdminNavbar";
import { Divider, Grid, Stack } from "@mui/material";
import { AdminCards } from "../../components/AdminCard/AdminCards";
import "./Dashboard.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdminSidebar } from "../../components/AdminSidebar/AdminSidebar";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import Loader from "../../components/Tools/Loader";
import { Link } from "@mui/icons-material";

export const Dashboard = () => {
  const auth = useRecoilValue(authAtom);

  const [chartData, setChartData] = useState({});
  const [totalSales, setTotalSales] = useState(null);
  const [upcomingLiveClasses, setUpcomingLiveClasses] = useState(null);
  const [students, setStudents] = useState(null);
  const [otherUsers, setOtherUsers] = useState(null);
  const [loading, setLoading] = useState({});

  // C42730: Academic course
  // 0373BA:Non academic
  // 156C3C: Test series

  const data = {
    labels: ["Test Series", "Academic", "Non-Academic"],
    datasets: [
      {
        data: [
          chartData.test_series,
          chartData.Academic,
          chartData.NonAcademic,
        ],
        backgroundColor: ["#156C3C", "#C42730", "#0373BA"],
      },
    ],
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);

      const salesResponse = await publicAxios.get("get/sales/growth?days=15", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      const salesData = salesResponse.data;
      setChartData(salesData.sales);
      setTotalSales(salesData.total_sales);

      const liveClassesResponse = await publicAxios.get(
        "v2/liveclass/upcoming?days=15",
        {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        }
      );
      const liveClassesData = liveClassesResponse.data;
      setUpcomingLiveClasses(liveClassesData);

      const studentsResponse = await publicAxios.get("account/students", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      const studentsData = studentsResponse.data;
      setStudents(studentsData);

      const usersResponse = await publicAxios.get("account/users", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      const usersData = usersResponse.data;
      setOtherUsers(usersData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // get/sales/amount.  -> queryParam: days, from_date, end_date

  return (
    <div className="dash-content">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container className="grid-container">
            <Grid item md={6} xs={12} className="grid-item">
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item md={6}>
                  <AdminCards
                    bg="#C4C4C4"
                    barColor="#FCB03E"
                    heading="Total Students"
                    number="3280"
                    value={50}
                    content="80% Increase in 20 Days"
                    margin="0"
                  />
                </Grid>
                <Grid item md={6}>
                  <AdminCards
                    bg="#C4C4C4"
                    barColor="#FCB03E"
                    heading="New Students"
                    number="245"
                    value={40}
                    content="50% Increase in 20 Days"
                    margin="0"
                  />
                </Grid>
                <Grid item md={6}>
                  <AdminCards
                    bg="#C4C4C4"
                    barColor="#C42730"
                    heading="Total Course"
                    number="28"
                    value={65}
                    content="76% Increase in 20 Days"
                  />
                </Grid>
                <Grid item md={6}>
                  <AdminCards
                    bg="#C4C4C4"
                    barColor="#C42730"
                    heading="Total Sales"
                    number="₹25160"
                    value={20}
                    content="30% Increase in 20 Days"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              className="grid-item"
              sx={{
                background: "white",
                boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "5px",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item md={6} xs={12}>
                  <div className="chart">
                    <Pie
                      option={{
                        legend: { display: false },
                        maintainAspectRatio: false,
                        // datalabels: {
                        //   display: true,
                        //   color: "white",
                        // },
                        tooltips: {
                          backgroundColor: "#5a6e7f",
                        },
                      }}
                      data={data}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className="chart-data">
                    <div className="col-1">
                      <h3 className="chart-head">Total Sales</h3>
                      <h4 className="chart-subhead">Academic Courses</h4>
                      <h4 className="chart-subhead">Non-Academic Courses</h4>
                      <h4 className="chart-subhead">Test Series</h4>
                    </div>
                    <div className="col-2">
                      <h1 className="price-head">₹{totalSales}</h1>
                      <h1 className="price-subhead">₹{chartData?.Academic}</h1>
                      <h1 className="price-subhead">
                        ₹{chartData?.NonAcademic}
                      </h1>
                      <h1 className="price-subhead">
                        ₹{chartData?.test_series}
                      </h1>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className="grid-container-2"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              md={4}
              xs={12}
              className="grid-item"
              sx={{
                height: "300px",
                padding: "1.5%",
                overflow: "scroll",
                background: "#FFF",
                boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "5px",
              }}
            >
              <Stack spacing={2}>
                <div>Upcoming Events</div>
                {upcomingLiveClasses.length > 0 ? (
                  <>
                    {upcomingLiveClasses.map((item, key) => (
                      <>
                        <Grid
                          container
                          className="grid-container"
                          // border={1}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            md={12}
                            xs={12}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Stack>
                              <div>{item.title}</div>
                              <div className="event-time">
                                {item.start_time} - {item.end_time}
                              </div>
                              <div className="event-time">
                                <b>Course:</b> {item.course?.title}
                              </div>
                              <a
                                href={item.link}
                                target={"_blank"}
                                rel="noreferrer"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Link />
                                &nbsp;
                                {item.link.length > 15
                                  ? `${item.link.substring(0, 15)}...`
                                  : item.link}
                              </a>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    ))}
                  </>
                ) : null}
              </Stack>
            </Grid>
            <Grid
              item
              md={7}
              xs={12}
              className="grid-item"
              sx={{
                height: "300px",
                padding: "1.5%",
                marginLeft: "2%",
                overflow: "scroll",
                background: "#FFF",
                boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
                borderRadius: "5px",
              }}
            >
              <Stack spacing={2}>
                <div>New Enrolments</div>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>STUDENT NAME</TableCell>
                        <TableCell>PHONE NAME</TableCell>
                        <TableCell align="center">COURSE NAME</TableCell>
                        <TableCell align="center">DATE</TableCell>
                        <TableCell align="center">TIME</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {students.map((student) => (
                        <TableRow
                          key={student.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {student.first_name} {student.last_name}
                          </TableCell>
                          <TableCell align="center">{student.phone}</TableCell>
                          <TableCell align="center">{student.course}</TableCell>
                          <TableCell align="center">
                            {student.joining_date}
                          </TableCell>
                          <TableCell align="center">
                            {student.joining_time}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
