import { CircularProgress,Box,Typography } from "@mui/material";

const Loader = ({value}) => {
  return (
    <div style={{ textAlign: "center", padding: "1rem" }}>
    <Box style={{position:'relative',display:"inline-flex" }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {value ? Math.round(value)+'%':""}
        </Typography>
      </Box>
    </Box>
    </div>
  );
};

export default Loader;
