import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import publicAxios from "../../axios/publicAxios";
import Loader from "../../components/Tools/Loader";

import {
  Button,
  Stack,
  Typography,
  TextField,
  Checkbox,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { catchError } from "../../helper/useError";
import { toastAtom } from "../../state/atoms/toastAtom";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../ConfirmationModal";
import EditIcon from "@mui/icons-material/Edit";

export const StudentDetails = () => {
  const auth = useRecoilValue(authAtom);
  const [showToast, setShowToast] = useRecoilState(toastAtom);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inactivefillter, setInactivefiler] = useState(true);

  const handleActiveInactive = (checked) => {
    setInactivefiler(checked);
  };

  useEffect(() => {
    getInfo();
  }, [inactivefillter]);

  const [openModal, setOpenModal] = useState({
    open: false,
    id: null,
    loading: false,
    type: null,
  });
  const closeModal = () => {
    setOpenModal({
      open: false,
      id: null,
      loading: false,
      type: null,
      objId: null,
    });
  };

  const handleCheckboxClick = async (id) => {
    if (!selectedIds.includes(id)) {
      setSelectedIds([...selectedIds, id]);
      await getInfo([...selectedIds, id]);
    } else {
      const updatedSelectedIds = selectedIds.filter(
        (selectedId) => selectedId !== id
      );
      setSelectedIds([...updatedSelectedIds]);
      await getInfo([...updatedSelectedIds]);
    }
  };

  // category_id=? Coupon filter param

  const handleActions = async (objId) => {
    try {
      setOpenModal((prevState) => ({ ...prevState, loading: true }));
      let data = {
        coupon_id: openModal.id,
      };
      const make_type =
        openModal.type === "published" ? "activate" : "deactivate";
      const make_text =
        openModal.type === "published" ? "activate" : "deactivate";
      const url = `/v2/coupon/${make_type}`;
      const response = await publicAxios.post(url, data, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      if (response.status === 200) {
        setData((prevState) => {
          return prevState.filter((obj) => {
            return obj.id !== openModal.id;
          });
        });
        setOpenModal({
          open: false,
          id: null,
          loading: false,
          type: null,
        });
      }
      setShowToast({
        status: true,
        type: "success",
        successMessage: `Post has been ${make_text} now!`,
      });
    } catch (error) {
      console.log("Error", error);
      const err = await catchError(error);
      setShowToast({
        status: true,
        type: "error",
        errorMessage: err,
      });
    }
  };

  const getFilterData = async () => {
    try {
      const response = await publicAxios.get("/v2/category/all?type=COURSE", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      const responseData = response.data;
      const newDataArray = responseData.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setFilters(newDataArray);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const getInfo = async (ids) => {
    setLoading(true);
    let url = `v2/coupons?valid=${!inactivefillter ? "False" : "True"}`;
    if (ids?.length > 0) {
      url += `&categories=${ids.join(",")}`;
    }

    try {
      const response = await publicAxios.get(url, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });

      if (response.status === 200) {
        setData(response.data);
      }
      console.log("response.datalkkkkkkkkkkkkk", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
    getFilterData();
  }, []);

  const columns = [
    { field: "id", headerName: "No.", width: 50 },
    {
      field: "code",
      headerName: "Coupon Code",
      width: 170,
    },
    {
      field: "method",
      headerName: "Coupon Type",
      width: 170,
    },
    {
      field: "value",
      headerName: "Coupon Amount",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "usage_limit",
      headerName: "Usage/Limit",
      width: 150,
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      width: 100,
    },
    {
      field: "delete",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() => handleDelete(params.row.id, params.row.valid)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <IconButton
          aria-label="edit"
          onClick={() => navigateToEdit(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const navigateToEdit = (rowData) => {
    navigate("/u1NzT9Pq2s5/admin/coupon/addCoupon", {
      state: { data: rowData },
    });
  };

  const handleDelete = (id) => {
    setOpenModal({
      open: true,
      id: id,
      loading: false,
      type: null,
    });
  };

  // Otherwise filter will be applied on fields such as the hidden column id

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Stack
            direction="row"
            spacing={1}
            p={2}
            border={1}
            borderColor="grey.300"
            alignItems="center"
            style={{
              marginBottom: "15px",
              border: "none",
              background: "white",
            }}
          >
            {/* <Typography variant="body1">Show</Typography>

            <TextField
              size="small"
              variant="outlined"
              select
              defaultValue="10"
            />

            <Typography variant="body1">entries</Typography> */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={inactivefillter}
                  onChange={(e) => handleActiveInactive(e.target.checked)}
                />
              }
              label={"Inactive"}
            />

            {/* Checkbox */}
            {filters.map((item) => (
              <FormControlLabel
                key={item.id} // Provide a unique key for each item
                control={
                  <Checkbox
                    checked={selectedIds.includes(item.id)}
                    onChange={() => handleCheckboxClick(item.id)}
                  />
                }
                label={item.name}
              />
            ))}

            {/*<Typography variant="body1">Search</Typography>*/}
            {/*<TextField size="small" variant="outlined" />*/}

            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/u1NzT9Pq2s5/admin/coupon/addCoupon");
                }}
                size="small"
              >
                Create
              </Button>
            </Stack>
          </Stack>

          {data.length ? (
            <DataGrid
              rows={data}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              // slots={{ toolbar: GridToolbar }}
              // slotProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   },
              // }}
              style={{ border: "none", background: "white" }}
            />
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                navigate("/u1NzT9Pq2s5/admin/coupon/addCoupon");
              }}
              size="small"
            >
              Add Coupon
            </Button>
          )}
        </Box>
      )}
      {openModal.open ? (
        <ConfirmationModal
          message={`Are you sure you want to ${
            openModal.type === "published" ? "active" : "deactivate"
          } this ?`}
          closeModal={closeModal}
          handleActions={handleActions}
          data={openModal}
        />
      ) : null}
    </>
  );
};
