import { useEffect, useState } from "react";

import { LoadMore } from "../../Tools/LoadMore";
import { MockTestCard } from "../MockTestCard/MockTestCard";
import { useFetchTestset } from "../../../helper/Testseries/useFetchTestset";
import Loader from "../../Tools/Loader";
import {Accordion,Typography,AccordionSummary,AccordionDetails} from '@mui/material';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import "./TestsetCollection.scss";

export const TestsetCollection = ({
  topics,
  selectedTestsetType,
  selectedCategory,
  testseriesId,
  setTestsetCategory,
}) => {
  const noTestset = "noTestset";
  const [page, setPage] = useState(1);
  // const [testsetData, testsetCategory, totalPages, loading, noTestsetData] = useFetchTestset(
  //   page,
  //   selectedTestsetType,
  //   selectedCategory
  // );

  // useEffect(() => {
  //   setTestsetCategory(testsetCategory);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [testsetCategory]);

  const handleLoadButton = () => {
    setPage((page) => page + 1);
  };

  return (
    <div className="testsetCollection">
      {!topics.length
        ? noTestset
        : topics.map((item) => (
            <Accordion key={`accordion-${item.id}`}>
              <AccordionSummary sx={{borderBottom:"2px solid #156C3C"}} expandIcon={<ArrowForwardIosSharpIcon />}>
                <Typography sx={{color:"#156C3C",fontWeight:600}}>{item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails >
                {
                  item.test_sets.map((ele)=>(<MockTestCard
                    key={`testsetCollection-${ele.id}`}
                    data={ele}
                    testseriesId={testseriesId}
                  />))
                }
              </AccordionDetails>
            </Accordion>
          ))}
      {/* {loading && <Loader />}
      {totalPages !== page && <LoadMore color="tertiary" handleLoadButton={handleLoadButton} />} */}
    </div>
  );
};
