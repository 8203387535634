export const catchError = async (error) => {
  try {
    console.log("Error:", error);
    const errorData =
      error.response?.data?.message || error.response?.data || error.message;
    if (errorData) {
      if (typeof errorData === "object") {
        const errs = [];
        const keys = Object.keys(errorData);
        const firstKey = keys[0];
        const firstValue = errorData[firstKey];
        errs.push(`${firstKey}: ${firstValue}`);
        if (errs.length > 0) {
          return errs.toString();
        }
      } else {
        return errorData.toString();
      }
    } else {
      return error.toString();
    }
  } catch (error) {
    return error.toString();
  }
};
