import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Button, Stack, Dialog, Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import publicAxios from "../../axios/publicAxios";
import { useNavigate } from "react-router-dom";
import Loader from "../Tools/Loader";
import EvaluationFormNav from "./EvaluationFormNav";
import EvaluationForm from "./EvaluationForm";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";

export const EvaluationTable = () => {
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [evaluateTest, setEvaluateTest] = useState([]);
  const [indEvaluateTest, setIndEvaluateTest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setRow] = useState();
  const navigate = useNavigate();

  const handleClickOpen = (params) => {
    setOpen(true);
    setRow(params);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getIndTestSetInfo = async (id) => {
    await publicAxios
      .get(`/v2/testset/${id}/question`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          res.data.map((ele, i) => (ele.id = i + 1));
          setIndEvaluateTest(res.data);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  const getAllTestSetInfo = async () => {
    await publicAxios
      .get("v2/testset/evaluate", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          res.data.map((ele, i) => (ele.id = i + 1));
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  const getTestSetInfo = (id) => {
    setLoading(true);
    return publicAxios
      .get(`v2/testset/${id}/evaluate`, {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          res.data.map((ele, i) => {
            ele.id = i + 1;
            ele.comp_count = selectedRow.comp_count;
          });
          setEvaluateTest(res.data);
          setLoading(false);
          return res.data;
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setData(true);
          setLoading(false);
        }
        console.info("error", e);
      });
  };
  useEffect(() => {
    getAllTestSetInfo();
  }, []);
  useEffect(() => {
    if (open) {
      selectedRow &&
        selectedRow.test_set_id &&
        getTestSetInfo(selectedRow.test_set_id).then((res) => {
          res && getIndTestSetInfo(res[0].user_test_set_id);
        });
    }
  }, [open]);
  console.log(evaluateTest);
  useEffect(() => {
    if (open) {
      evaluateTest.length &&
        evaluateTest[0].user_test_set_id &&
        getIndTestSetInfo(evaluateTest[0].user_test_set_id);
    }
  }, [open]);
  const columns = [
    {
      field: "id",
      headerName: "",
      width: 100,
    },
    {
      field: "test_set_name",
      headerName: "",
      width: 450,
    },
    {
      field: "participants",
      headerName: "",
      type: "",
      width: 200,
      renderCell: (params) => {
        const currentRow = params.row;
        return currentRow.participants + " Participants";
      },
    },
    {
      field: "comp_count",
      headerName: "",
      width: 250,
      renderCell: (params) => {
        const currentRow = params.row;
        return currentRow.comp_count + " Comprehensive Questions";
      },
    },
    {
      field: "action",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                handleClickOpen(currentRow);
              }}
            >
              Evaluate
            </Button>
          </Stack>
        );
      },
    },
  ];
  // Otherwise filter will be applied on fields such as the hidden column id

  return (
    <>
      {loading && <Loader />}
      {data ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            // TransitionComponent={Transition}
          >
            <div
              style={{
                background: "#F5F5F5",
                width: "100%",
                height: "100%",
                fontFamily: "Lato",
              }}
            >
              <div className="testnav" style={{ background: "white" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      padding: "0.7%",
                    }}
                  >
                    {/* <Link to="/admin/test"> */}
                    <IconButton>
                      <ArrowBackIcon sx={{ color: "black" }} />
                    </IconButton>
                    {/* </Link> */}
                    {selectedRow?.test_set_name ? (
                      <>{selectedRow?.test_set_name}</>
                    ) : (
                      <>Default</>
                    )}
                  </div>
                  {/* <div style={{ paddingRight: "0.7%" }}>
              <Button variant="contained" >
                Submit
              </Button>
            </div> */}
                </Stack>
                <Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <EvaluationFormNav data={evaluateTest} />
                    </Grid>
                    <Grid item xs={8}>
                      <EvaluationForm data={indEvaluateTest} />
                    </Grid>
                  </Grid>
                </Stack>
              </div>
            </div>
          </Dialog>
        </Box>
      ) : (
        <>No Data available</>
      )}
    </>
  );
};
