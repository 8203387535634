import { Container } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { Faq } from "../../components/Faq/Faq";
import { useCourseDetail } from "./useCourseDetail";
import { useSmoothScroll } from "../../helper/useSmoothScroll";
import { AndroidApp2 } from "../../components/AndroidApp/AndroidApp2";
import { DemoVideos } from "../../components/Course/CourseDetail/DemoVideos";
import { AboutCourse } from "../../components/Course/CourseDetail/AboutCourse";
import { CourseFaculty } from "../../components/Course/CourseDetail/CourseFaculty";
import { CourseCurriculumn } from "../../components/Course/CourseDetail/CourseCurriculumn";
import { CourseDetailNavbar } from "../../components/Course/CourseDetail/CourseDetailNavbar";
import { CourseThumbnailShortDetail } from "../../components/Course/CourseDetail/CourseThumbnailShortDetail";
import { courseAtom } from "../../state/atoms/Course/courseAtom";
import Requestcall from "../../components/Requestcall/Requestcall";
import publicAxios from "../../axios/publicAxios";
import "../../components/Course/CourseDetail/CourseDetail.scss";

export const CourseDetail = ({ courseType = "academic" }) => {
  const [resource, setResource] = useState([]);
  useSmoothScroll();

  const getVideo = (chapter_id, course_id) => {
    publicAxios
      .get(`/v2/course/${course_id}/chapter/${chapter_id}/resources`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setResource((prevRes) => [...prevRes, ...res.data]);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
        }
        console.info("error", e);
      })
      .finally(() => {});
  };
  courseType = courseType === "academic" ? "academic" : "nonAcademic";
  const courseDetail = useRecoilValue(courseAtom);
  const { refList, scrollTosection } = useCourseDetail(courseType);

  JSON.parse(sessionStorage.getItem("courseDetails"));
  const selectedCourseDetail = JSON.parse(
    sessionStorage.getItem("courseDetails")
  )?.filter((item) => item.id === courseDetail[0]?.course);
  useEffect(() => {
    if (courseDetail.length > 0) {
      courseDetail.map((item) => {
        getVideo(item.id, item.course);
      });
    }
  }, [courseDetail]);
  const uniqueIds = new Set();
  const uniqueData = resource.filter((obj) => {
    const id = obj.id;
    if (!uniqueIds.has(id) && obj.chapter.course === courseDetail[0].course) {
      uniqueIds.add(id);
      return true;
    }
    return false;
  });

  return (
    <Container>
      <CourseDetailNavbar
        refList={refList}
        scrollTosection={scrollTosection}
        courseType={courseType}
      />
      {selectedCourseDetail?.length > 0 ? (
        <CourseThumbnailShortDetail
          courseType={courseType}
          selectedCourseDetail={selectedCourseDetail[0]}
        />
      ) : null}
      {selectedCourseDetail?.length > 0 ? (
        <AboutCourse
          refName={refList?.aboutCourseRef}
          aboutDetail={selectedCourseDetail[0]?.about}
        />
      ) : null}
      <CourseCurriculumn
        refName={refList?.featureRef}
        curriculumnData={uniqueData}
      />
      {selectedCourseDetail?.length > 0 ? (
        <CourseFaculty
          refName={refList?.facultyRef}
          facultyData={selectedCourseDetail[0]?.faculties}
        />
      ) : null}
      <DemoVideos
        refName={refList?.demoRef}
        demoVideoData={uniqueData.filter((item) => item.type === "video")}
      />
      {courseDetail?.length > 0 ? (
        <Faq refName={refList?.faqRef} faqData={courseDetail[0]?.course} />
      ) : null}
      <AndroidApp2 />
      <Requestcall />
    </Container>
  );
};
