import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Avatar,
  Button,
  Stack,
  Grid,
  Divider,
  Typography,
  formData,
} from "@mui/material";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import Loader from "../Tools/Loader";

export const StudentDetails = ({ data, subsDetails,getSubsDetails }) => {
  const [loading, setLoading] = React.useState(true);
  const auth = useRecoilValue(authAtom);

  const columns = [
    {
      field: "Category",
      headerName: "Category",
      width: 170,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <>
            {currentRow?.type === "COURSE"
              ? currentRow?.course?.category?.name + " Course"
              : "Test Series"}
          </>
        );
      },
    },
    {
      field: "Title",
      headerName: "Title",
      width: 170,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <>
            {currentRow?.type === "COURSE"
              ? currentRow?.course?.title
              : currentRow?.test_series?.name}
          </>
        );
      },
    },
    {
      field: "enrolment_date",
      headerName: "Enrollment Date",
      width: 125,
      renderCell:(params)=>{
        const currentRow = params.row;
       return <span>{new Date(currentRow?.enrolment_date).toDateString()}</span>
      }
    },
    {
      field: "expire_status",
      headerName: "Expiry Status",
      width: 125,
      renderCell:(params)=>{
        const currentRow = params.row;
        return <span>{currentRow?.expire_status ? 'Expired' : 'Not Expired'}</span>
      }
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <Stack direction="row" spacing={-3}>
            <Button
              onClick={() => {
                const confirm = window.confirm(
                  "You sure want to de-activate this course?"
                );
                if (confirm) {
                  publicAxios
                    .post(`subscription/deactivate`,{
                      subscription_id:currentRow?.id
                    }, {
                      headers: {
                        Authorization: "Bearer " + auth.access,
                      },
                    })
                    .then(async(res) => {
                      if (res.status === 200) {
                        await getSubsDetails()
                        setLoading(false);
                      }
                    })
                    .catch((e) => {
                      if (e.response && e.response.status === 404) {
                        setLoading(false);
                      }
                      console.info("error", e);
                    });
                }
              }}
            >
              <img src="/images/admin/delete.png" alt="" />
            </Button>
          </Stack>
        );
      },
    },
  ];

  // Otherwise filter will be applied on fields such as the hidden column id

  return (
    <Grid
      sx={{ height: "100%" }}
      container
      justifyContent="space-between"
      alignItems="space-between"
    >
      <Grid item xs={12} md={4}>
        <div className="left-side-profile">
          <Stack
            direction="column"
            sx={{
              background: "#FFF",
              boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
              borderRadius: "5px",
            }}
          >
            <Grid
              className="profile-card"
              container
              gap={3}
              justifyContent="center"
              marginBottom={2}
            >
              <Grid item xs={12} justifyContent="center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    // color="neutral"
                  >
                    {" "}
                    {}
                    <img
                      src={data.resource.url}
                      height={200}
                      width={250}
                      style={{ objectFit: "contain" }}
                      alt="Course Thumbnail"
                    />
                    {/* Upload the Thumbnail */}
                  </Button>
                </div>
              </Grid>
              <span style={{ fontWeight: "bold" }}>
                {data.first_name + " " + data.last_name}
              </span>
              <Divider sx={{ marginTop: "5%", marginBottom: "5%" }} />
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Date Registered</div>
                  <div className="course-date">{data.joining_date}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Products Enrolled</div>
                  <div className="course-date">{subsDetails?.length}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="column"
            sx={{
              background: "#FFF",
              boxShadow: "4px 4px 20px 8px rgba(0, 0, 0, 0.08)",
              marginTop: "5%",
              paddingTop: "5%",
              borderRadius: "5px",
            }}
          >
            <Grid
              className="profile-card"
              container
              gap={3}
              sx={{ textAlign: "start" }}
            >
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Email</div>
                  <div className="course-date">{data.email}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Mobile</div>
                  <div className="course-date">{data.phone}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{ paddingX: "3%" }}
                >
                  <div className="course-price">Address</div>
                  <div className="course-date">{data.address}</div>
                </Stack>
                <Divider sx={{ marginTop: "5%" }} />
              </Grid>
            </Grid>
          </Stack>
        </div>
      </Grid>

      <Grid item xs={12} md={8}>
        <div>
          {subsDetails && (
            <DataGrid
              style={{ background: "white" }}
              rows={subsDetails}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
