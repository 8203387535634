import React from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import { Stack } from "@mui/material";
import "../User.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import { AddCoupon } from "../../../components/AdminCoupon/AddCoupon";

export const CouponData = () => {
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash" style={{ overflowY: "scroll" }}>
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack>
            <div className="all-user-strip">Coupon Management</div>
            <AddCoupon />
          </Stack>
        </div>
      </div>
    </div>
  );
};
