import { useEffect, useState } from "react";
import { VideoPlayer } from "../../VideoPlayer/VideoPlayer";

export const DemoVideos = ({ refName = null, demoVideoData }) => {
  const [playVideo, setPlayVideo] = useState();
  const handlePlayVideo = (video) => {
    setPlayVideo(video);
  };
  const newDemoVideo = demoVideoData.filter(
    (video) => video.access_type !== "PAID"
  );

  useEffect(() => {
    newDemoVideo &&
      newDemoVideo.length > 0 &&
      newDemoVideo[0]?.url &&
      handlePlayVideo(newDemoVideo[0]);
  }, [newDemoVideo]);
  const [thumbnail, setThumbnail] = useState("");

  const captureThumbnail = (event) => {
    const video = event.target;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    const thumbnailDataUrl = canvas.toDataURL("image/png");
    setThumbnail(thumbnailDataUrl);
  };

  return (
    newDemoVideo &&
    newDemoVideo.length > 0 && (
      <div className="demoVideoSection" ref={refName}>
        <h2 className="display_small">Demo Videos</h2>
        <div className="content">
          <div className="grid">
            <div className="lecture_video">
              {playVideo && playVideo?.url && playVideo?.id && (
                <>
                  <VideoPlayer
                    videoId={playVideo?.id}
                    videoType="mp4"
                    videoURL={playVideo?.url}
                    handleFinishVideo={undefined}
                  />
                  <p className="body_large">{playVideo.name}</p>
                </>
              )}
            </div>
            <div className="demo_playlist">
              <p className="heading_large">Course Playlist</p>
              <div className="wrapper">
                {newDemoVideo.map((item, key) => (
                  <div
                    key={`demo_video_${item?.id}_${key}`}
                    className={`item ${
                      item === playVideo ? "active" : undefined
                    }`}
                    onClick={() => handlePlayVideo(item)}
                  >
                    {item.name}
                    <span>{Math.floor(item.duration / 60)} Min.</span>
                    <video
                      src={item?.url}
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "fill",
                      }}
                      onLoadedData={captureThumbnail}
                    ></video>
                    {/* {thumbnail && <img src={thumbnail} style={{width:'250px',height:'50px'}} alt="Video Thumbnail" />} */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
