import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import publicAxios from "../../axios/publicAxios";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { catchError } from "../../helper/useError";
import { toastAtom } from "../../state/atoms/toastAtom";
import Loader from "../../components/Tools/Loader";

const Announcement = () => {
  const auth = useRecoilValue(authAtom);
  const [announcements, setAnnouncements] = useState([]);
  const [courses, setCourses] = useState([]);
  const [, setShowToast] = useRecoilState(toastAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [newAnnouncement, setNewAnnouncement] = useState({
    title: "",
    description: "",
    course_id: "",
  });
  const rowsPerPage = 5;
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetchAnnouncements();
    fetchCourseInfo();
  }, []);

  const fetchCourseInfo = async (queryParams) => {
    await publicAxios
      .get("/v2/course", {
        params: queryParams,
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCourses(res.data?.data ?? []);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
        setIsLoading(false);
      });
  };

  const fetchAnnouncements = async () => {
    try {
      const response = await publicAxios.get("/v2/announcement", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      setAnnouncements(response.data);
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  };

  const handleAddAnnouncement = async () => {
    if (validateForm()) {
      try {
        setIsLoading(true);
        if (editMode) {
          await publicAxios.post(
            `/v2/announcement/${selectedAnnouncement.id}`,
            newAnnouncement
          );
        } else {
          await publicAxios.post("/v2/announcement", newAnnouncement, {
            headers: {
              Authorization: "Bearer " + auth.access,
            },
          });
        }
        let message = "Added Successfully !";
        if (editMode) {
          message = "Updated Successfully !";
        }
        setShowToast({
          status: true,
          type: "success",
          successMessage: message,
        });
        fetchAnnouncements();
        setOpenModal(false);
        setNewAnnouncement({
          title: "",
          description: "",
          course_id: 1,
        });
        setFormErrors({});
        setEditMode(false);
        setSelectedAnnouncement(null);
        setIsLoading(false);
      } catch (error) {
        const err = catchError(error);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
        setIsLoading(false);
      }
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setFormErrors({});
    setEditMode(false);
    setSelectedAnnouncement(null);
  };

  const handleChange = (e) => {
    setNewAnnouncement({ ...newAnnouncement, [e.target.name]: e.target.value });
  };

  const handleEdit = (announcement) => {
    setSelectedAnnouncement(announcement);
    setNewAnnouncement({
      title: announcement.title,
      description: announcement.description,
      course_id: announcement?.course?.id,
    });
    setEditMode(true);
    setOpenModal(true);
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!newAnnouncement.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }

    if (!newAnnouncement.course_id) {
      errors.course_id = "Course is required";
      isValid = false;
    }

    if (!newAnnouncement.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <Stack style={{ padding: "1rem" }}>
      <div className="all-user-strip">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <div>Announcement</div>
          <Button
            variant="contained"
            disabled={isLoading}
            color="primary"
            onClick={() => {
              setOpenModal(true);
              setEditMode(false);
            }}
          >
            Add Announcement
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Dialog open={openModal} onClose={handleModalClose}>
            <DialogTitle>
              {editMode ? "Edit Announcement" : "Add New Announcement"}
            </DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Title"
                fullWidth
                name="title"
                value={newAnnouncement.title}
                onChange={handleChange}
                error={!!formErrors.title}
                helperText={formErrors.title}
              />
              <TextField
                select
                margin="dense"
                label="Course"
                fullWidth
                name="course_id"
                type="number"
                value={newAnnouncement.course_id}
                helperText={formErrors.course_id}
                error={!!formErrors.course_id}
                onChange={handleChange}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="dense"
                label="Description"
                rows={8}
                fullWidth
                name="description"
                value={newAnnouncement.description}
                onChange={handleChange}
                error={!!formErrors.description}
                helperText={formErrors.description}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose}>Cancel</Button>
              <Button onClick={handleAddAnnouncement} color="primary">
                {editMode ? "Save" : "Add"}
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {announcements
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((announcement, index) => (
                    <TableRow key={index}>
                      <TableCell>{announcement.title}</TableCell>
                      <TableCell>{announcement.description}</TableCell>
                      <TableCell>{announcement?.course?.title}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleEdit(announcement)}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
              Previous
            </Button>
            <Button
              onClick={() => setPage(page + 1)}
              disabled={(page + 1) * rowsPerPage >= announcements.length}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Stack>
  );
};

export default Announcement;
