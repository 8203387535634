import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import publicAxios from "../../axios/publicAxios";

import {
  Avatar,
  Button,
  Stack,
  Container,
  Typography,
  TextField,
  Grid,
  Checkbox,
  Select,
  FormGroup,
  DialogActions,
  DialogContent,
  Input,
  DialogTitle,
  Dialog,
  InputLabel,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { Multiselect } from "multiselect-react-dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { catchError } from "../../helper/useError";
import { toastAtom } from "../../state/atoms/toastAtom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const AddCoupon = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const data = location.state?.data;

  const auth = useRecoilValue(authAtom);
  const [allCategories, setAllCategories] = useState([]);
  const [, setShowToast] = useRecoilState(toastAtom);
  const [loading, setLoading] = useState(false);
  const [listCou, setListCou] = useState([]);
  const [catId, setCatId] = useState([]);
  const [categoriesData, setCategoriesData] = useState({
    selectedCategoryInclude: null,
    subCategoriesInclude: [],
    selectedCategoryExclude: null,
    subCategoriesExclude: [],
  });
  const multiselectRef = React.createRef();
  const multiselectRef2 = React.createRef();

  const [openName, setOpenName] = React.useState(false);
  const [formData, setFormData] = useState({
    name: {
      value: "",
    },
    code: {
      value: "",
    },
    type: {
      value: "",
    },
    amount: {
      value: "",
    },
    usage_limit: {
      value: 0,
    },
    single_usage: {
      value: false,
    },
    minimum_spend: {
      value: 0,
    },
    maximum_spend: {
      value: 0,
    },
    expiry_date: {
      value: "",
    },
  });

  useEffect(() => {
    const getData = async () => {
      let allCate;
      try {
        const response = await publicAxios.get("/v2/category/all?type=COURSE", {
          headers: {
            Authorization: "Bearer " + auth.access,
          },
        });
        const responseData = response.data;
        console.log("responseData", responseData);
        allCate = responseData;
        setAllCategories(responseData);
      } catch (error) {
        console.log("Error", error);
      }
      if (data) {
        setFormData({
          name: { value: data.name },
          code: { value: data.code },
          type: { value: data.method },
          amount: { value: data.value },
          usage_limit: { value: data.usage_limit },
          single_usage: { value: data.single_usage },
          minimum_spend: { value: data.minimum_spend },
          maximum_spend: { value: data.maximum_spend },
          expiry_date: { value: new Date(data.expiry_date) },
        });
        if (data.categories[0]) {
          let categoryId = data.categories[0];
          const selectedCategoryObj = allCate.find(
            (category) => category.id === categoryId
          );
          multiselectRef.current.resetSelectedValues();
          setCategoriesData((prevState) => ({
            ...prevState,
            selectedCategoryInclude: categoryId,
            subCategoriesInclude: selectedCategoryObj.subcategories,
          }));
          const defaultSelectedValues =
            selectedCategoryObj.subcategories.filter((obj) =>
              data.sub_categories.includes(obj.id)
            );
          setCatId(data.categories);
          setListCou(defaultSelectedValues);
        }
      }
    };
    getData();
  }, [data]);
  const handleModalOpen = () => {
    setOpenName(true);
  };
  const handleCloseModal = () => {
    setOpenName(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: {
        value: value,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      expiry_date: {
        value: date,
      },
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    formData.usage_limit.value = 1;
    for (const key in formData) {
      if (
        key !== "single_usage" &&
        key !== "expiry_date" &&
        !formData[key].value
      ) {
        setLoading(false);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: `Please provide a value for ${key}.`,
        });
        return;
      }
    }
    if (catId.length === 0 || listCou.length === 0) {
      setLoading(false);
      setShowToast({
        status: true,
        type: "error",
        errorMessage:
          "Please select at least one category and one subcategory.",
      });
      return;
    }
    const listCouIds = listCou.map((item) => item.id);

    const req_body = {
      name: formData.name.value,
      code: formData.code.value,
      type: formData.type.value,
      amount: formData.amount.value,
      usage_limit: formData.usage_limit.value,
      single_usage: formData.single_usage.value,
      expiry_date: formData.expiry_date.value,
      categories: catId,
      sub_categories: listCouIds,
      minimum_spend: formData.minimum_spend.value,
      maximum_spend: formData.maximum_spend.value,
    };

    publicAxios
      .post(`v2/${data?.id ? "coupon/" + data?.id : "coupons"}`, req_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        setLoading(false);
        setShowToast({
          status: true,
          type: "success",
          successMessage: "Coupon added !",
        });
        navigation("/u1NzT9Pq2s5/admin/coupon");
      })
      .catch((e) => {
        const err = catchError(e);
        setShowToast({
          status: true,
          type: "error",
          errorMessage: err,
        });
      });
  };

  // Otherwise filter will be applied on fields such as the hidden column id

  const handleCategoryChange = (event, type) => {
    const categoryId = event.target.value;
    let array = [categoryId];
    setCatId(array);
    const selectedCategoryObj = allCategories.find(
      (category) => category.id === categoryId
    );
    multiselectRef.current.resetSelectedValues();
    setCategoriesData((prevState) => ({
      ...prevState,
      selectedCategoryInclude: categoryId,
      subCategoriesInclude: selectedCategoryObj.subcategories,
    }));
  };

  const onSelect = (selectedList, selectedItem) => {
    setListCou([...listCou, selectedItem]);
  };

  const onRemove = (selectedList, removedItem) => {
    const updatedListCou = listCou.filter((item) => item.id !== removedItem.id);
    setListCou(updatedListCou);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="space-between"
        >
          <FormGroup className="">
            <Stack>
              <div
                style={{
                  textAlign: "start",
                  color: "#666",
                  marginBottom: "0.5rem",
                }}
              ></div>
              <div style={{ marginBottom: "1.2rem" }}>
                <TextField
                  name="name"
                  size="small"
                  fullWidth
                  value={formData.name.value}
                  onChange={handleChange}
                  placeholder="Coupon Name"
                  style={{ background: "white" }}
                />
              </div>
              <div
                style={{
                  textAlign: "start",
                  color: "#666",
                  marginBottom: "0.5rem",
                }}
              ></div>
              <div style={{ marginBottom: "1.2rem" }}>
                <TextField
                  style={{ background: "white" }}
                  name="code"
                  value={formData.code.value}
                  size="small"
                  onChange={handleChange}
                  placeholder="Coupon Code"
                  fullWidth
                />
              </div>
              <div
                style={{
                  textAlign: "start",
                  color: "#666",
                  marginBottom: "0.5rem",
                }}
              >
                <Typography>Coupon Information</Typography>
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                <FormGroup row fullWidth></FormGroup>
              </div>
              <div
                style={{
                  textAlign: "start",
                  color: "#666",
                  marginBottom: "0.5rem",
                }}
              >
                <Grid container alignItems="center">
                  <Grid item md={12} paddingLeft="1rem">
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={2}
                      style={{ width: "max-content" }}
                    >
                      <Stack direction="column">
                        <Typography>Discount Type</Typography>
                        <Select
                          labelId="coupon-type-label"
                          id="coupon-type"
                          value={formData.type.value}
                          size="small"
                          name="type"
                          onChange={handleChange}
                          defaultValue=""
                        >
                          <MenuItem value="FIXED_AMOUNT">
                            Fixed Amount
                          </MenuItem>
                          <MenuItem value="PERCENT">Fixed Percentage</MenuItem>
                        </Select>
                      </Stack>
                      <Stack direction="column">
                        <Typography>Coupon Amount</Typography>
                        <TextField
                          name="amount"
                          value={formData.amount.value}
                          size="small"
                          type="number"
                          onChange={handleChange}
                        />
                      </Stack>
                      <Stack direction="column">
                        <Typography>Coupon Expiry Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="expiry_date"
                            value={dayjs(formData.expiry_date.value)}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Typography style={{ marginTop: "10px" }}>
                  Usage Restriction
                </Typography>

                <Grid item md={6}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    style={{ width: "max-content" }}
                  >
                    <Stack direction="column">
                      <Typography>
                        Product Catagory that the coupon will be applied to
                      </Typography>
                      <Select
                        labelId="category-label"
                        id="category"
                        size="small"
                        name="category"
                        onChange={(e) => handleCategoryChange(e, "include")}
                        value={categoriesData.selectedCategoryInclude}
                      >
                        {allCategories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                    <Stack direction="column">
                      <Typography>
                        Product that the coupon will be applied to
                      </Typography>
                      <div style={{ maxWidth: "300px" }}>
                        <Multiselect
                          ref={multiselectRef}
                          selectedValues={listCou}
                          style={{ width: "100%" }}
                          options={categoriesData.subCategoriesInclude}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue="name"
                        />
                      </div>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={6}>
                  <Stack size="small" direction="column">
                    <Typography>
                      Minimum Speed allowed to use the coupon
                    </Typography>
                    <TextField
                      name="minimum_spend"
                      value={formData.minimum_spend.value}
                      size="small"
                      type="number"
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack size="small" direction="column">
                    <Typography>
                      Maximum Speed allowed to use the coupon
                    </Typography>
                    <TextField
                      name="maximum_spend"
                      size="small"
                      value={formData.maximum_spend.value}
                      type="number"
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>
                <Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.single_usage.value}
                            onChange={handleChange}
                          />
                        }
                      />
                      <Typography>Restrict the coupon to single use</Typography>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : null}
                        Publish
                      </Button>
                    </div>
                  </div>
                </Grid>
              </div>
            </Stack>
          </FormGroup>
        </Grid>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openName}
        sx={{
          maxHeight: "1000px",
        }}
      >
        <DialogTitle>
          <Stack direction="column" justifyContent="space-between">
            <Typography variant="h6">Products For Coupons</Typography>
            <Typography>Selected Products</Typography>
            <TextField size="small"></TextField>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography>Product List</Typography>
          <Typography>Academic Courses</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Typography>Non-Academic Courses</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Typography>Test Series</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="UPSC Foundation Course"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
