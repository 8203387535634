import {
  Button,
  Dialog,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  CircularProgress,
  IconButton,
  InputAdornment,Select
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import EditIcon from "@mui/icons-material/Edit";
import { useLogin } from "./useLogin";

import "./Login.scss";
import { useRecoilValue } from "recoil";
import { stateReasonAtom } from "../../state/stateReason";
import { countryCodeSelect, textFieldStyle } from "./constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

export const LoginModal = ({ open }) => {
  const {
    hero,
    error,
    values,
    toggleParams,
    handleClose,
    handleSubmit,
    handleOnChange,
    handlePhoneOnChange,
    loading,
    sendOTP,
    resetPassword,
    sendReset,
    handleEditEmail,
    setCountryCode,
    countryCode
  } = useLogin();

  return (
    <Dialog
      className="loginDialog"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { overflowY: "visible" } }}
      maxWidth="md"
    >
      <div className="closeIcon" onClick={handleClose}>
        <img
          className="fluid-image"
          src="/images/login/close.svg"
          alt="close"
        />
      </div>
      <div className="content">
        <div className="wrapper">
          <div>
            <img
              className="fluid-image"
              src="/images/login/login.svg"
              alt="login"
            />
          </div>
          <div className="right">
            <h1 className="headline_large">{hero.title}</h1>
            <p className="title_medium">{hero.description}</p>
            {error && <span className="message">Incorrect OTP</span>}
            <LoginForm
              values={values}
              handleOnChange={handleOnChange}
              handlePhoneOnChange={handlePhoneOnChange}
              handleSubmit={handleSubmit}
              toggleParams={toggleParams}
              loading={loading}
              sendOTP={sendOTP}
              resetPassword={resetPassword}
              sendReset={sendReset}
              handleEditEmail={handleEditEmail}
              setCountryCode={setCountryCode}
              countryCode={countryCode}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const LoginForm = ({
  values,
  handleOnChange,
  handlePhoneOnChange,
  handleSubmit,
  toggleParams,
  loading,
  sendOTP,
  resetPassword,
  sendReset,
  handleEditEmail,
  setCountryCode,
  countryCode
}) => {
  const states = useRecoilValue(stateReasonAtom);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <TextField
          value={values.email}
          specialLabel={""}
          inputProps={{
            name: "email",
            required: true,
          }}
          sx={textFieldStyle}
          disabled={toggleParams?.phoneDisabled}
          inputClass="loginPhone"
          onChange={handlePhoneOnChange}
          InputProps={{
            endAdornment:
              values.email && toggleParams?.inputFields.length > 0 ? (
                <IconButton onClick={handleEditEmail} edge="end">
                  <EditIcon />
                </IconButton>
              ) : null,
          }}
        />
      </div>

      {/*{toggleParams?.inputFields.map((item) =>*/}
      {/*  item.type === "checkbox" ? (*/}
      {/*    <FormControlLabel*/}
      {/*      key={`login-${item.id}`}*/}
      {/*      label={item.label}*/}
      {/*      labelPlacement="end"*/}
      {/*      sx={item.sx}*/}
      {/*      control={*/}
      {/*        <Checkbox*/}
      {/*          name={item.name}*/}
      {/*          checked={values[item.name]}*/}
      {/*          required={item.required}*/}
      {/*        />*/}
      {/*      }*/}
      {/*    />*/}
      {/*  ) : item.type === "select" ? (*/}
      {/*    <TextField*/}
      {/*      select*/}
      {/*      key={`login-${item.id}`}*/}
      {/*      variant="outlined"*/}
      {/*      name={item.name}*/}
      {/*      label={item.label}*/}
      {/*      sx={item.sx}*/}
      {/*      required={item.required}*/}
      {/*      value={values[item.name]}*/}
      {/*      onChange={handleOnChange}*/}
      {/*    >*/}
      {/*      <MenuItem value="" disabled>*/}
      {/*        {item.placeholder}*/}
      {/*      </MenuItem>*/}
      {/*      {states.map((option) => (*/}
      {/*        <MenuItem key={option} value={option}>*/}
      {/*          {option}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </TextField>*/}
      {/*  ) : item.type === "phone_number" ? (*/}
      {/*    <TextField*/}
      {/*      key={`login-${item.id}`}*/}
      {/*      variant="outlined"*/}
      {/*      name={item.name}*/}
      {/*      label={item.label}*/}
      {/*      type={item.type}*/}
      {/*      placeholder={item.placeholder}*/}
      {/*      sx={item.sx}*/}
      {/*      required={item.required}*/}
      {/*      value={values[item.name]}*/}
      {/*      inputProps={item.inputProps}*/}
      {/*      multiline={item.multiline}*/}
      {/*      rows={item.rows}*/}
      {/*      onChange={handleOnChange}*/}
      {/*    />*/}
      {/*  ) : (*/}
      {/*    <TextField*/}
      {/*      key={`login-${item.id}`}*/}
      {/*      variant="outlined"*/}
      {/*      name={item.name}*/}
      {/*      label={item.label}*/}
      {/*      type={item.type}*/}
      {/*      placeholder={item.placeholder}*/}
      {/*      sx={item.sx}*/}
      {/*      required={item.required}*/}
      {/*      value={values[item.name]}*/}
      {/*      inputProps={item.inputProps}*/}
      {/*      multiline={item.multiline}*/}
      {/*      rows={item.rows}*/}
      {/*      onChange={handleOnChange}*/}
      {/*    />*/}
      {/*  )*/}
      {/*)}*/}

      {toggleParams?.inputFields.map((item) => {
        if (item.type === "checkbox") {
          return (
            <FormControlLabel
              key={`login-${item.id}`}
              label={item.label}
              labelPlacement="end"
              sx={item.sx}
              control={
                <Checkbox
                  name={item.name}
                  checked={values[item.name]}
                  required={item.required}
                />
              }
            />
          );
        } else if (item.type === "select") {
          return (
            <TextField
              select
              key={`login-${item.id}`}
              variant="outlined"
              name={item.name}
              label={item.label}
              sx={item.sx}
              required={item.required}
              value={values[item.name]}
              onChange={handleOnChange}
            >
              <MenuItem value="" disabled>
                {item.placeholder}
              </MenuItem>
              {states.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          );
        } else if (item.type === "phone_number") {
          return (
            <TextField
              key={`login-${item.id}`}
              variant="outlined"
              name={item.name}
              label={item.label}
              type={item.type}
              placeholder={item.placeholder}
              sx={item.sx}
              required={item.required}
              value={values[item.name]}
              inputProps={item.inputProps}
              multiline={item.multiline}
              rows={item.rows}
              onChange={handleOnChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Select
                      variant="standard"
                      value={countryCode}
                      onChange={(event) => {
                        setCountryCode(event.target.value);
                      }}
                    >
                      {countryCodeSelect.map((country) => (
                        <MenuItem value={country.code}>{country.label}</MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          );
        } else if (["password", "reenterpassword"].includes(item.type)) {
          const handleTogglePasswordVisibility = () => {
            setShowPassword(!showPassword);
          };

          return (
            <TextField
              key={`login-${item.id}`}
              variant="outlined"
              name={item.name}
              label={item.label}
              type={showPassword ? "text" : "password"} // Toggle type based on state
              placeholder={item.placeholder}
              sx={item.sx}
              required={item.required}
              value={values[item.name]}
              onChange={handleOnChange}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          );
        } else {
          return (
            <TextField
              key={`login-${item.id}`}
              variant="outlined"
              name={item.name}
              label={item.label}
              type={item.type}
              placeholder={item.placeholder}
              sx={item.sx}
              required={item.required}
              value={values[item.name]}
              inputProps={item.inputProps}
              multiline={item.multiline}
              rows={item.rows}
              onChange={handleOnChange}
            />
          );
        }
      })}

      {!toggleParams.login_user && toggleParams.showResend ? (
        <Button onClick={() => sendReset()}>
          {loading.reset ? (
            <CircularProgress size={20} style={{ marginRight: "10px" }} />
          ) : null}
          Forgotten password?
        </Button>
      ) : null}
      <div className="loginButtons">
        {toggleParams.showReset && !toggleParams.login_user ? (
          <Button variant="contained" onClick={resetPassword}>
            {loading.submit ? (
              <CircularProgress
                size={20}
                style={{ color: "white", marginRight: "10px" }}
              />
            ) : null}
            Submit
          </Button>
        ) : (
          <Button variant="contained" type="submit">
            {loading.init ? (
              <CircularProgress
                size={20}
                style={{ color: "white", marginRight: "10px" }}
              />
            ) : null}
            Login
          </Button>
        )}
        {toggleParams.showResend && toggleParams.login_user ? (
          <Button
            onClick={() =>
              toggleParams?.showReset ? sendReset("pass") : sendOTP(true, false)
            }
          >
            {loading.resend ? (
              <CircularProgress size={20} style={{ marginRight: "10px" }} />
            ) : null}
            Resend OTP
          </Button>
        ) : null}
      </div>
    </form>
  );
};
