import React from "react";

function NotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        // src={"/images/404-error.svg"}
        src="/images/404-error.svg"
        alt="404 Error"
        style={{ height: "50%", width: "50%" }}
      />
      <h1 style={{ marginTop: "20px", fontSize: "18px" }}>
        Oops! Page Not Found
      </h1>
      <p style={{ color: "gray" }}>
        The page you are looking for does not exist.
      </p>
    </div>
  );
}

export default NotFound;
