import { useState } from "react";
import { Button, Container, MenuItem, Select } from "@mui/material";

import { Section } from "./Section";
import Divider from "../../Tools/Divider";

import "./MobileView.scss";
import { useNavigate } from "react-router";

export const MobileView = () => {
  const navigate = useNavigate();
  const [study, setStudy] = useState("academic");

  const handleChange = (e) => {
    setStudy(e.target.value);
  };

  const handleStartLearing = () => {
    console.log(study);
    window.location.href = "https://fmatb.courses.store/";

    // if (study === "academic" || study === "non-academic") {
    //   navigate(`/courses/${study}`);
    // } else if (study === "current_affairs") {
    //   navigate(`/reading/${study}`);
    // } else if (study === "editorials") {
    //   navigate(`/reading/${study}`);
    // }
  };

  return (
    <Container width="lg">
      <div className="hero">
        <div>
          <img
            className="fluid-image"
            src="/images/homepage/mobile/circle_mobile.svg"
            alt=""
          />
        </div>
        <div>
          <img src="/images/header/logo.png" alt="study glow" />
        </div>
        <div className="selectCourse">
          <p>I want to prepare for:</p>
          <Select
            value={study}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ background: "#DEEBFF", margin: "1rem 0", width: "100%" }}
          >
            <MenuItem value="academic">Academic courses</MenuItem>
            <MenuItem value="non-academic">Non academic courses</MenuItem>
            <MenuItem value="current_affairs">Current affairs </MenuItem>
            <MenuItem value="editorials">Editorials</MenuItem>
          </Select>
        </div>
        <div>
          <Button variant="contained" onClick={handleStartLearing}>
            Start Learning
          </Button>
        </div>
      </div>
      <Divider color="primary" />
      <Section type="course" color="secondary" />
      <Section type="softCourse" color="primary" />
      <Section type="blog" color="warning" />
      <Section type="testseries" color="tertiary" />
      <div className="androidApp">
        <img
          className="fluid-image"
          src="/images/homepage/mobile/downloadApp.svg"
          alt="android App"
        />
      </div>
    </Container>
  );
};
