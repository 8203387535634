import React, { useState, useRef } from "react";
import { AdminNavbar } from "../../../components/AdminNavbar/AdminNavbar";
import {
  Avatar,
  Badge,
  Button,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import "./Fac.scss";
import { AdminSidebar } from "../../../components/AdminSidebar/AdminSidebar";
import publicAxios from "../../../axios/publicAxios";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../state/atoms/authAtom";

export const AddFac = () => {
  const auth = useRecoilValue(authAtom);
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
  }));
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: {
      value: "",
    },
    bio: {
      value: "",
    },
    designation: {
      value: "",
    },
    thumbnail: {
      value: null,
      id: null,
      url: "/images/admin/Avatar.png",
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: {
        value: value,
      },
    }));
  };
  const uploadResource = (id, type, file, resourceId) => {
    console.log(file);
    const fileData = new FormData();
    //  type === 'video' && fileData.append('name',file.name)
    fileData.append("file", file);
    fileData.append("type", type);
    return publicAxios
      .post(
        `v2/${resourceId ? "resource/" + resourceId : "resources"}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
        // console.log(chapters);
      })
      .catch((e) => {
        //  console.log(e);
      });
  };

  function handleThumbnailImage(event) {
    //console.log(event.target.files);
    const resourceId = formData.thumbnail.id;
    uploadResource("", "image", event.target.files[0], resourceId).then(
      (res) => {
        console.log(res.data.id);
        setFormData((formData) => ({
          ...formData,
          thumbnail: {
            ...formData.thumbnail,
            value: event.target.files[0],
            id: res.data.id,
          },
        }));
      }
    );
  }

  const handleSubmit = () => {
    const request_body = {
      name: formData.fullName.value,
      resource_id: formData.thumbnail.id ? formData.thumbnail.id : "",
      bio: formData.bio.value,
    };
    publicAxios
      .post(`/v2/faculty`, request_body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.access,
        },
      })
      .then((res) => {
        //  setLoading(false);
        //  console.log(courseId);
        navigate("/u1NzT9Pq2s5/admin/faculty");
      })
      .catch((e) => {
        //  console.log(e);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        padding: 0,
        background: "#F1F2F5",
        fontFamily: "Lato",
      }}
    >
      {/* <AdminSidebar /> */}
      <div className="main-dash">
        {/* <AdminNavbar /> */}
        <div className="main-user-dash">
          <Stack sx={{ width: "90%" }}>
            <div className="all-user-strip">Add Faculty</div>
            <div className="FacPaper">
              <Stack
                direction={{ xs: "column", md: "row" }}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="left-side">
                  <Stack direction="column">
                    <span style={{ textAlign: "start", paddingLeft: "1%" }}>
                      <strong>Basic Info</strong>
                    </span>
                    <div className="form">
                      {/* <label htmlFor="fname">First Name</label> */}
                      <TextField
                        id="outlined-basic"
                        label="Full Name"
                        name="fullName"
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      />
                      {/* <TextField
                        id="outlined-basic"
                        label="Designation"
                        name="designation"
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      /> */}
                      <div className="upload">
                        <Tooltip title="Upload Photo">
                          <a
                            href="/"
                            onClick={(event) => {
                              event.preventDefault();
                            }}
                          >
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <SmallAvatar
                                  alt="Remy Sharp"
                                  src="/images/admin/download.png"
                                  style={{ transform: "rotate(180deg)" }}
                                />
                              }
                            >
                              <Avatar
                                alt="Travis Howard"
                                src={
                                  formData.thumbnail.value
                                    ? URL.createObjectURL(
                                        formData.thumbnail.value
                                      )
                                    : formData.thumbnail?.url
                                }
                                sx={{ width: 100, height: 100 }}
                              />
                            </Badge>
                          </a>
                        </Tooltip>
                        <Button
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                          variant="contained"
                          sx={{
                            background: "#E5E5E5",
                            color: "black",
                            margin: "2%",
                          }}
                        >
                          {" "}
                          Choose File{" "}
                        </Button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleThumbnailImage}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </Stack>
                </div>
                <div className="right-side">
                  <Stack direction="column">
                    <div className="form">
                      {/* <label htmlFor="fname">First Name</label> */}
                      {/* <TextField
                        id="outlined-basic"
                        label="Last Name"
                        onChange={handleChange}
                        name="lastName"
                        variant="outlined"
                        sx={{ marginTop: "3.2rem" }}
                      /> */}
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        name="bio"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        sx={{ marginTop: "3.2rem" }}
                      />
                      {/* <TextField
                        id="outlined-basic"
                        label="Designation"
                        variant="outlined"
                        sx={{ marginTop: "2rem" }}
                      /> */}
                      <div className="upload">
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={1}
                          justifyContent="end"
                        >
                          <Button
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{
                              background: "#025D97",
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/u1NzT9Pq2s5/admin/faculty");
                            }}
                            variant="contained"
                            sx={{
                              background: "#E5E5E5",
                              color: "black",
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </div>
                    </div>
                  </Stack>
                </div>
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};
