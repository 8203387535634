import React from "react";
import { Container } from "@mui/material";

import PageHero from "../../components/PageHero/PageHero";
import PageNavbar from "../../components/PageNavbar/PageNavbar";
import VacanciesCard from "../../components/Blog/VacanciesCard/VacanciesCard";

import pageNavbar from "../../helper/DummyData/pageNavbar";
import pageHero from "../../helper/DummyData/pageHero";

import RefundCard from "../../components/Privacy/RefundCard";

// import "./Blog.scss";

export const Refund = () => {
  return (
    <Container>
      <div>
        <RefundCard/>
      </div>
    </Container>
  );
};
