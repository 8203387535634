import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useState } from "react";
import "./Calendar.css";
import publicAxios from "../../axios/publicAxios";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state/atoms/authAtom";
import { Tooltip } from "@mui/material";

const eventStyle = {
  backgroundColor: "lightblue",
  color: "black",
  width: "100%",
  padding: "5px",
  borderRadius: "10px",
};

export const Calendar = () => {
  const auth = useRecoilValue(authAtom);
  const [liveClasses, setLiveClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLiveClasses = async () => {
    try {
      const response = await publicAxios.get("/v2/liveclass", {
        headers: {
          Authorization: "Bearer " + auth.access,
        },
      });
      console.log("response.data", response.data);
      setLiveClasses(
        response.data.map((liveClass) => ({
          title: liveClass.title,
          start: new Date(liveClass.start_time),
          liveClassDetails: liveClass, // Store all live class details in extendedProps
        }))
      );
    } catch (error) {
      console.error("Error fetching live classes:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveClasses();
  }, []);

  const getTooltipContent = (liveClass) => (
    <>
      <b>Title:</b> {liveClass.title} <br />
      <b>Start Time:</b> {new Date(liveClass.start_time).toLocaleString()}{" "}
      <br />
      {/* Add other live class details as needed */}
      {liveClass.course && (
        <>
          <b>Course:</b> {liveClass.course.title} <br />
        </>
      )}
      {liveClass.link && (
        <>
          <b>Link:</b> {liveClass.link} <br />
        </>
      )}
    </>
  );

  return (
    <div className="calendar-container">
      <div>
        <FullCalendar
          height="90vh"
          width="100%"
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          headerToolbar={{
            left: "prev,next,today",
            center: "title",
            right: "dayGridMonth, timeGridWeek, timeGridDay",
          }}
          allDaySlot={false}
          initialView="dayGridMonth"
          slotDuration={"01:00:00"}
          editable={true} // Optional: Enable event editing (if desired)
          selectable={true} // Optional: Enable event selection (if desired)
          selectMirror={true} // Optional: Mirror selection (if desired)
          dayMaxEvent={true} // Optional: Limit events per day (if desired)
          weekends={true} // Optional: Show weekends (if desired)
          nowIndicator={true} // Optional: Show current time indicator (if desired)
          eventContent={(eventInfo) => (
            <Tooltip
              title={getTooltipContent(
                eventInfo.event.extendedProps.liveClassDetails
              )}
            >
              <div style={eventStyle}>
                <b>{eventInfo.event.title}</b>
              </div>
            </Tooltip>
          )}
          events={liveClasses}
          columnHeader={(
            columnHeaderFormat // Custom column header content
          ) => (
            <th
              style={{ backgroundColor: "green", color: "white" }} // Green background, white text
              {...columnHeaderFormat.attrs}
            >
              {columnHeaderFormat.text}
            </th>
          )}
          dayHeaderContent={(
            fullDate // Custom day header content
          ) => (
            <td style={{ backgroundColor: "green", color: "white" }}>
              {fullDate.day}
            </td>
          )}
        />
      </div>
    </div>
  );
};
