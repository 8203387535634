export const SoftCourseArrow = () => {
  return (
    <>
      <div className="curveArrow bottomLeft">
        <svg
          width="232"
          height="207"
          viewBox="0 0 232 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M226.769 182.82C233.057 186.863 232.418 194.96 224.925 197.254C210.666 201.614 196.043 204.681 181.234 206.419C89.9112 176.954 24.2438 107.038 0.391424 14.1622C-0.258334 11.355 1.9005 9.37538 3.82882 10.9256C10.7842 16.6154 18.1159 21.8295 25.7738 26.5324C26.4123 26.9621 27.1391 27.2432 27.9007 27.355C28.6623 27.4668 29.4392 27.4064 30.1744 27.1782C30.9095 26.9501 31.584 26.56 32.1483 26.0367C32.7125 25.5134 33.1522 24.8703 33.4347 24.1547C36.8759 16.4121 40.8468 8.91568 45.319 1.71863C46.4823 -0.198171 49.1966 0.482661 49.7206 2.76607C69.4963 78.0347 121.665 133.58 195.633 157.922C205.212 167.349 215.713 175.237 226.769 182.82Z"
            fill="#C42730"
            fillOpacity="0.5"
          />
        </svg>
      </div>
      <div className="curveArrow topLeft">
        <svg
          width="215"
          height="240"
          viewBox="0 0 215 240"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2_185)">
            <path
              d="M28.5225 227.245C24.4772 233.53 16.3762 232.891 14.0811 225.402C9.71918 211.15 6.65013 196.536 4.91116 181.734C34.4332 90.4607 104.345 24.8285 197.281 0.988874C200.09 0.339464 202.071 2.49715 200.52 4.42442C194.827 11.3761 189.61 18.7038 184.904 26.3577C184.474 26.9958 184.193 27.7222 184.081 28.4834C183.969 29.2446 184.03 30.0211 184.258 30.7559C184.486 31.4906 184.877 32.1647 185.4 32.7287C185.924 33.2927 186.567 33.7321 187.283 34.0145C195.016 37.4556 202.502 41.4242 209.69 45.8924C211.607 47.055 210.926 49.7679 208.642 50.2916C133.343 70.0672 77.7678 122.198 53.4124 196.136C44.0119 205.699 36.1204 216.195 28.5225 227.245Z"
              fill="#0373BA"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_2_185"
              x="0.911133"
              y="0.871094"
              width="213.896"
              height="238.659"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_185" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2_185"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="curveArrow topRight">
        <svg
          width="232"
          height="207"
          viewBox="0 0 232 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.2741 24.1065C-1.01387 20.0634 -0.374602 11.9668 7.11856 9.67287C21.3774 5.31327 36.0001 2.24586 50.8095 0.507821C142.121 30.014 207.799 99.8884 231.652 192.775C232.301 195.582 230.142 197.562 228.214 196.011C221.259 190.322 213.927 185.108 206.269 180.405C205.631 179.975 204.904 179.694 204.142 179.582C203.381 179.47 202.604 179.531 201.869 179.759C201.134 179.987 200.459 180.377 199.895 180.9C199.33 181.424 198.891 182.067 198.608 182.782C195.159 190.511 191.181 197.994 186.703 205.176C185.54 207.093 182.825 206.412 182.301 204.129C162.515 128.871 110.357 73.3254 36.3786 48.983C26.8209 39.5876 16.32 31.7004 5.2741 24.1065Z"
            fill="#FCB03E"
            fillOpacity="0.5"
          />
        </svg>
      </div>
      <div className="curveArrow bottomRight">
        <svg
          width="207"
          height="232"
          viewBox="0 0 207 232"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M182.51 4.6917C186.555 -1.59291 194.656 -0.953982 196.952 6.53517C201.314 20.7864 204.383 35.4013 206.122 50.2027C176.641 141.466 106.688 207.108 13.7619 230.948C10.9533 231.597 8.97259 229.44 10.5236 227.512C16.2165 220.561 21.4334 213.233 26.1388 205.579C26.5688 204.941 26.85 204.215 26.9619 203.454C27.0737 202.692 27.0133 201.916 26.785 201.181C26.5567 200.446 26.1664 199.772 25.6429 199.208C25.1193 198.644 24.4758 198.205 23.7598 197.922C16.0125 194.476 8.51206 190.501 1.31174 186.024C-0.606091 184.861 0.0751066 182.148 2.35974 181.624C77.6687 161.88 133.244 109.739 157.599 35.8005C167.031 26.2269 174.923 15.7317 182.51 4.6917Z"
            fill="#156C3C"
            fillOpacity="0.5"
          />
        </svg>
      </div>
    </>
  );
};
