import { useState } from "react";
import { Typography, Container, Paper } from "@mui/material";
import { Link } from "react-router-dom";

// import Loader from "../../Tools/Loader";

const RefundCard = () => {
  const paperStyle = {
    backgroundColor: "transparent", // Setting background color
    boxShadow: "none",
    marginTop: "1%",
  };

  return (
    <Container maxWidth="md">
      <Paper style={paperStyle}>
        <Typography variant="h4" gutterBottom>
          Refund Policy
        </Typography>

        <Typography variant="body1" paragraph>
          There is no refund policy (refund would be proceed only in case if you
          have made payment more than once for the same item). Note: it is
          advisable to check demo videos on the website before purchasing any
          course. We are not liable if you don't like course after purchasing.
        </Typography>

        {/* Add more sections as needed */}
      </Paper>
      <Paper style={paperStyle}>
        <Typography variant="h4" gutterBottom>
          Cancellation Policy
        </Typography>

        <Typography variant="body1" paragraph>
          Studyglows believes in helping its customers as far as possible, and
          has therefore a liberal cancellation policy. Under this policy:
          Cancellations will be considered only if the request is made within 12
          hours of placing an order. However, the cancellation request will not
          be entertained if the orders have been completed.
          <p>
            No cancellations are entertained for those products that the
            Studyglows marketing team has given special offers on for various
            purposes including but not limited to special occasions like Holi,
            Diwali, Valentine's Day, specific examination etc. These are limited
            occasion offers and therefore cancellations are not possible.
          </p>
        </Typography>

        {/* Add more sections as needed */}
      </Paper>
    </Container>
  );
};

export default RefundCard;
